export type Request = {
    id: number;
    createdAt?: string;
    updatedAt?: string;
    name: string;
    lastname: string;
    email: string;
    enrollmentNumber: string;
    documents: string[];
    info?: {
      id?: number;
      personalEmail?: string;
      phone?: string;
      identification?: string;
      address?: string | null;
      whatsapp?: string;
      sex?: string;
      province?: {
        id: number;
        name: string;
      };
      borough?: {
        id: number;
        name: string;
      };
      period?: {
        id: number;
        name: string;
      };
      degree?: {
        id: number;
        name: string;
      };
      campus?: {
        id: number;
        name: string;
      };
    };
  serviceSession: {
    id: number;
    name: string;
    request: number;
    whatsappLink: string;
  };
  requests: {
    id: number;
    createdAt: string;
    updatedAt: string;
    description: string;
    typeRequestName: string;
    statusName: string;
    degreeName: string;
    campusName: string;
    documents: string[];
    completedSocialService?: boolean;
    hasFutureSubjects?: boolean;
    optionalSubjects?: string; 
    [key: string]: any;
  }[];
};
export const statusMap: { [key: string]: number } = {
  "Pendiente": 1,
  "Aprobado": 2,
  "En proceso": 3,
  "Revisado": 4,
  "Denegado": 5,
};
// Datos de la tabla de metricas
const requests = [
  {
    id: 1,
    name: "Mariana",
    lastname: "Gutierrez",
    identification: "001-1234567-8",
    sex: "Femenino",
    phone: 8091234567,
    whatsapp: 8091234567,
    enrollmentNumber: "12003030",
    serviceSessionName: "Jornadas Medioambientales",
    boroughName: "Santiago",
    provinceName: "Santiago",
    createdAt: "17 Marzo, 2024",
    updatedAt: "17 Marzo, 2024",
    email: "mariana@p.uapa.edu.do",
    degreeName: "Ingenieria en Software",
    periodName: "Trimestral",
    campusName: "Santiago",
    statusName: "Pendiente",
    description:
      "Pendiente de revisión por el coordinador del Dep. Servicio Social.",
    typeRequestName: "Servicio Social",
  },
];

const requestsDocumentation = [
  {
    id: 1,
    name: "Mariano",
    lastname: "Lopez",
    identification: "401-0344305-6",
    sex: "Masculino",
    enrollmentNumber: "10029292",
    phone: 8091234567,
    whatsapp: 8091234567,
    serviceSessionName: "Jornadas de educación y recreación para niños",
    boroughName: "Santiago",
    provinceName: "Santiago",
    createdAt: "17 Marzo, 2024",
    updatedAt: "17 Marzo, 2024",
    email: "111022333@p.uapa.edu.do",
    degreeName: "Ingenieria Gerencial",
    periodName: "Trimestral",
    campusName: "Santiago",
    statusName: "Pendiente",
    documents: ["https://cdn.pixabay.com/video/2020/06/05/41146-428253129_tiny.mp4"],
    typeRequestName: "Pasantía",
    description: "Pendiente de revisión por el coordinador de pasantías.",
  },
];

export { requestsDocumentation, requests };
