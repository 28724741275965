import React from 'react';
import { Form } from 'react-bootstrap';

interface FormFieldProps {
    label: string;
    type: string | undefined;
    value: string | number | string[] | undefined;
    readOnly?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
    required?: boolean;
    feedback?: string;
    as?: string;
    rows?: number | undefined;
    textLength?: number;
    options?: string[]; 
    disabled?: boolean;
}

const ModalFormField: React.FC<FormFieldProps> = ({ label, type, value, readOnly, onChange, required, feedback, as, rows, textLength, options, disabled }) => (
    <Form.Group className="mb-3">
        <Form.Label><strong>{label}</strong></Form.Label>
        {type === 'select' ? (
            <Form.Select value={value as string} onChange={onChange} required={required} disabled={disabled}>
                {options?.map((option, index) => (
                    <option key={index} value={option}>{option}</option>
                ))}
            </Form.Select>
        ) : (
            <Form.Control type={type} value={value} readOnly={readOnly} onChange={onChange} textLength={textLength} required={required} as={as as React.ElementType} rows={rows} />
        )}
        {feedback && <Form.Control.Feedback type="invalid">{feedback}</Form.Control.Feedback>}
    </Form.Group>
);

export default ModalFormField;