import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from 'Layout';
import NonAuthLayout from 'Layout/NonAuthLayout';
import { ToastContainer } from "react-toastify";

//routes
import { publicRoutes, authProtectedRoutes } from "./allRoutes";
import { AuthProtected } from './AuthProtected';
import { AccessRoute } from './AuthProtected';

const Index = () => {
    return (
        <React.Fragment>
            <Routes>
                {publicRoutes.map((route: any, idx: number) => (
                    <Route
                        key={idx}
                        path={route.path}
                        element={<NonAuthLayout> {route.component} </NonAuthLayout>}
                    />
                ))}

                {authProtectedRoutes.map((route: any, idx: number) => (
                    <Route
                        key={idx}
                        path={route.path}
                        element={
                            <AuthProtected>
                                <AccessRoute component={Layout} allowedRoles={route.roles} to={route.to}>
                                    <route.component />
                                </AccessRoute>
                            </AuthProtected>
                        }
                    />
                ))}
            </Routes>

            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </React.Fragment>
    );
}

export default Index;