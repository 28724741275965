import React from 'react'
import BreadCrumb from 'Common/BreadCrumb';
import { Container } from 'react-bootstrap';

// Import Components
import Widgets from './widgets';
import RequestsTable from './RequestsTable';
import { DocumentationTable } from './DocumentationTable';

const RequestMetrics = () => {
  document.title = "Métricas Generales";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Métricas" pageTitle="Administración" />
          <Widgets />
        </Container>
        <div className='custom-box'>
          <RequestsTable />
        </div>
        <div className='custom-box'>
          <DocumentationTable />
        </div>
      </div>
    </React.Fragment>
  )
}

export default RequestMetrics;
