import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Form, FormLabel } from "react-bootstrap";
import { Request } from 'Common/data/requests';
import ModalButtons from "./ModalButtons";
import ModalFormField from "./ModalFormFields";
import { Media } from "./Media";
import { Link } from "react-router-dom";
import { getInverseGenderMap } from "Common/data/socialServices";
import { useSocialServiceRequest } from "Common/Hooks/useSocialServiceRequest";
import useAllRequestsData from 'Common/Hooks/useAllRequestsData';

function ModalComponent({
    show,
    onHide,
    request,
    participant,
    onApprove,
    onReject,
    view,
    DocumentationTable
}: {
    show: boolean,
    onHide: () => void,
    request: Request['requests'][0] | null,
    participant: Request | null,
    onApprove?: (updatedRequest: Request['requests'][0]) => void,
    onReject?: (updatedRequest: Request['requests'][0]) => void,
    view: string,
    DocumentationTable?: boolean
}) {
    const [status, setStatus] = useState(request?.status?.name || request?.statusName || "");
    const [description, setDescription] = useState(request?.description || "");
    const [typeRequestName, setTypeRequestName] = useState(request?.typeRequest?.name || request?.typeRequestName || "");
    const formattedCreatedAt = request?.createdAt ? new Date(request?.createdAt).toLocaleDateString() : 'N/A';
    const { updateRequest } = useSocialServiceRequest();
    const { refetchData } = useAllRequestsData();

    useEffect(() => {
        const newStatus = request?.status?.name || request?.statusName || "";
        const newDescription = request?.description || "";
        const newTypeRequestName = request?.typeRequest?.name || request?.typeRequestName || "";

        if (status !== newStatus) setStatus(newStatus);
        if (description !== newDescription) setDescription(newDescription);
        if (typeRequestName !== newTypeRequestName) setTypeRequestName(newTypeRequestName);
    }, [request]);

    if (!show || !request || !participant) {
        return null;
    }

    const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        setStatus(e.target.value);
    };

    const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        setDescription(e.target.value);
    };

    const handleApproveClick = async () => {
        if (onApprove && request) {
            const updatedRequest = { ...request, status, description } as Request['requests'][0];
            try {
                await updateRequest(request.id, { status, description });
                onApprove(updatedRequest);
                refetchData();
                onHide();
            } catch (error) {
                console.error("Error al actualizar la solicitud:", error);
            }
        }
    };

    const handleRejectClick = async () => {
        if (onReject && request) {
            onHide();
        }
    };

    return (
        <Modal show={show} onHide={onHide} className="modal-dialog-scrollable">
            <Modal.Header closeButton>
                <Modal.Title className="mb-2">Detalles de la Solicitud</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col sm={12} lg={6}>
                            <ModalFormField label="Nombre" type="text" value={participant.name} readOnly />
                            <ModalFormField label="Cédula" type="text" value={participant.info?.identification || ''} readOnly />
                            <ModalFormField label="Teléfono" type="text" value={participant.info?.phone || ''} readOnly />
                        </Col>
                        <Col sm={12} lg={6}>
                            <ModalFormField label="Apellido" type="text" value={participant.lastname} readOnly />
                            <ModalFormField label="Sexo" type="text" value={getInverseGenderMap(participant.info?.sex ?? '') || ''} readOnly />
                            <ModalFormField label="WhatsApp" type="text" value={participant.info?.whatsapp || ''} readOnly />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} lg={6}>
                            <ModalFormField label="Fecha de Solicitud" type="text" value={formattedCreatedAt} readOnly />
                            <ModalFormField label="Municipio" type="text" value={participant.info?.borough?.name || ''} readOnly />
                            <ModalFormField label="Recinto" type="text" value={participant.info?.campus?.name || ''} readOnly />
                        </Col>
                        <Col sm={12} lg={6}>
                            <ModalFormField label="Matrícula" type="text" value={participant.enrollmentNumber} readOnly />
                            <ModalFormField label="Provincia" type="text" value={participant.info?.province?.name || ''} readOnly />
                            <ModalFormField label="Periodo" type="text" value={participant.info?.period?.name || ''} readOnly />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ModalFormField label="Carrera" type="text" value={participant.info?.degree?.name || ''} readOnly />
                            <ModalFormField label="Jornada" type="text" value={participant.serviceSession?.name || ''} readOnly />
                            <ModalFormField label="Email Institucional" type="text" value={participant.email} readOnly />
                        </Col>
                    </Row>
                    {/* campos específicos para la solicitud de pasantía */}
                    {typeRequestName.trim().toLowerCase() === "solicitud de pasantía" && (
                        <>
                            <Row>
                                <Col>
                                    <ModalFormField label="¿Realizaste el Servicio Social Universitario?" type="text" value={request?.completedSocialService ? "Sí" : "No"} readOnly />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ModalFormField label="¿Tienes que cursar otras asignaturas el próximo trimestre?" type="text" value={request?.hasFutureSubjects ? "Sí" : "No"} readOnly />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ModalFormField label="Materias Optativas y Periodo" type="text" value={request?.optionalSubjects || ''} readOnly />
                                </Col>
                            </Row>
                        </>
                    )}
                    <Row>
                        <Col className="d-flex flex-column">
                            {DocumentationTable === true && (
                                <>
                                    <FormLabel>Documentos</FormLabel>
                                    {request.documents?.map((documentUrl, index) => (
                                        <Link to={documentUrl} target="_blank" rel="noopener noreferrer" key={index} className="d-flex justify-content-center my-2">
                                            <Media key={index} url={documentUrl} width='80%' height='auto' />
                                        </Link>
                                    ))}
                                </>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} lg={6}>
                            <ModalFormField
                                label="Estado"
                                type="select"
                                value={status}
                                onChange={handleStatusChange}
                                disabled={view === "/my-request-status"}
                                options={view !== "/my-request-status" ? ['Aprobado', 'En proceso', 'Denegado', 'Pendiente', 'Revisado'] : [status]}
                            />
                        </Col>
                        <Col sm={12} lg={6}>
                            <ModalFormField label="Tipo de Solicitud" type="text" value={typeRequestName} readOnly />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ModalFormField label="Descripción" type="text" value={description} readOnly={view === "/my-request-status"} onChange={handleDescriptionChange} />
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <ModalButtons view={view} handleApproveClick={handleApproveClick} handleRejectClick={handleRejectClick} onHide={onHide} />
            </Modal.Footer>
        </Modal>
    );
}

export default ModalComponent;