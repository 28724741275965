import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form, Table, Card } from 'react-bootstrap';
import { fetchElements, createElement, deleteElement, updateElement } from 'services/apiEndpoints';
import BreadCrumb from 'Common/BreadCrumb';
import { toast } from 'react-toastify';
import "assets/scss/pages/MyRequestStatus.scss";

interface Jornada {
    id: number;
    name: string;
    request: number;
    whatsappLink: string;
}

const SocialServiceConfig: React.FC = () => {
    document.title = "Configuración - Servicio Social";

    const [jornadas, setJornadas] = useState<Jornada[]>([]);
    const [newJornada, setNewJornada] = useState<Jornada>({ id: 0, name: '', request: 0, whatsappLink: '' });
    const [editingJornada, setEditingJornada] = useState<Jornada | null>(null);

    const endpoint = '/serviceSessions';

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data: Jornada[] = await fetchElements(endpoint) as unknown as Jornada[];
                setJornadas(data);
            } catch (error) {
                toast.error("Error al obtener las jornadas, intente más tarde o comuníquese con el administrador");
            }
        };

        fetchData();
    }, []);

    const handleAddOrEditJornada = async () => {
        if (!newJornada.name || !newJornada.request || !newJornada.whatsappLink) {
            toast.error("Todos los campos son obligatorios");
            return;
        }

        if (editingJornada) {
            try {
                const updatedJornada = await updateElement(endpoint, editingJornada.id, newJornada);
                setJornadas(jornadas.map(jornada => (jornada.id === editingJornada.id ? updatedJornada as unknown as Jornada : jornada)));
                toast.success("Jornada actualizada correctamente");
            } catch (error) {
                toast.error("Error al actualizar la jornada, intente más tarde o comuníquese con el administrador");
            }
        } else {
            try {
                const createdJornada = await createElement(endpoint, newJornada);
                setJornadas([...jornadas, createdJornada as unknown as Jornada]);
                toast.success("Jornada agregada correctamente");
            } catch (error) {
                toast.error("Error al agregar la jornada, intente más tarde o comuníquese con el administrador");
            }
        }
        setNewJornada({ id: 0, name: '', request: 0, whatsappLink: '' });
        setEditingJornada(null);
    };

    const handleDeleteJornada = async (id: number) => {
        try {
            await deleteElement(endpoint, id);
            setJornadas(jornadas.filter(jornada => jornada.id !== id));
            toast.success("Jornada eliminada correctamente");
        } catch (error) {
            toast.error("Error al eliminar la jornada, intente más tarde o comuníquese con el administrador");
        }
    };

    const handleEditClick = (jornada: Jornada) => {
        setEditingJornada(jornada);
        setNewJornada(jornada);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setNewJornada({ ...newJornada, [name]: value });
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Configuración" pageTitle="Servicio Social" />
                    
                    {/* Form */}
                    <Card>
                        <Card.Body>
                            <Form>
                                <Row className="d-flex justify-content-center">
                                    <Col md={5} className="mb-2">
                                        <Form.Group controlId="formJornadaName">
                                            <Form.Label>Nombre de la Jornada</Form.Label>
                                            <Form.Control type="text" placeholder="Ingrese el nombre de la jornada" name="name" value={newJornada.name} onChange={handleChange} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={5} className="mb-2">
                                        <Form.Group controlId="formJornadaWhatsApp">
                                            <Form.Label>Grupo de WhatsApp</Form.Label>
                                            <Form.Control type="text" placeholder="Ingrese el enlace del grupo de WhatsApp" name="whatsappLink" value={newJornada.whatsappLink} onChange={handleChange} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={2} className="mb-2">
                                        <Form.Group controlId="formJornadaRequest">
                                            <Form.Label>Solicitudes</Form.Label>
                                            <Form.Control type="number" placeholder="Ingrese el número de solicitudes" name="request" value={newJornada.request} onChange={handleChange} />
                                        </Form.Group>
                                    </Col>
                                    <Button className="botonenviar w-25 my-3" onClick={handleAddOrEditJornada}>
                                        {editingJornada ? 'Actualizar' : 'Agregar'}
                                    </Button>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                    
                    {/* Table */}
                    <Container fluid className="table-responsive">
                        <Card>
                            <Card.Body>
                                <Table hover className="table align-middle mb-0" >
                                    <thead className='table-light'>
                                        <tr className='text-truncate'>
                                            <th>#</th>
                                            <th>Nombre</th>
                                            <th>Solicitudes</th>
                                            <th>Grupo de WhatsApp</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {jornadas.map(jornada => (
                                            <tr key={jornada.id}>
                                                <td data-column="ID">{jornada.id}</td>
                                                <td data-column="Nombre">{jornada.name}</td>
                                                <td data-column="Solicitudes">{jornada.request}</td>
                                                <td data-column="Grupo de WhatsApp"><a href={jornada.whatsappLink} target="_blank" rel="noopener noreferrer">{jornada.whatsappLink}</a></td>
                                                <td data-column="Acciones" className="d-flex justify-content-center align-items-center">
                                                    <Button className="btneliminar bi bi-trash3 mx-2" onClick={() => handleDeleteJornada(jornada.id)}></Button>
                                                    <Button className="btneditar bi bi-pencil mx-2" onClick={() => handleEditClick(jornada)}></Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Container>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default SocialServiceConfig;