import React from 'react';
import BreadCrumb from 'Common/BreadCrumb';
import { Row, Col, Card, Container, Button } from 'react-bootstrap';
import "assets/scss/pages/_InternshipRegulation.scss"

const InternshipRegulation = () => {
    document.title = "Reglamento Institucional Pasantía | Institutional Regulation for Social Service";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Reglamento Institucional de Pasantía" pageTitle="Panel" />
                    <Row className=" justify-content-center">
                        <Col xxl={12}>
                            <Card className="overflow-hidden">
                                <div className="bg-primary pb-0">
                                    <Card.Body className="text-center py-4">
                                        <h4 className="fw-medium text-white mb-3">UNIVERSIDAD ABIERTA PARA ADULTOS</h4>
                                        <p className="text-white text-opacity-75 mb-0">REGLAMENTO DE PRÁCTICA PROFESIONAL PASANTÍA</p>
                                    </Card.Body>
                                    <Card.Body className="py-2 bg-white bg-opacity-10">
                                        <ul className="list-unstyled mb-0 text-white-75 hstack gap-2 justify-content-between">
                                            <li>
                                                Modificado y aprobado por el Consejo Académico mediante resolución no. 45-2023
                                            </li>
                                            <li>
                                                14 de noviembre del 2023.
                                            </li>
                                        </ul>
                                    </Card.Body>
                                </div>
                                <Card.Body>
                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i> Considerando</h6>
                                    <p className="text-muted mb-2">Que la Ley 139-01 de Educación Superior, Ciencia y Tecnología de fecha 24 de julio del año 2001,
                                        en su artículo 55 propugna porque “el Sistema Nacional de Educación Superior, Ciencia y Tecnología genere una cultura
                                        que propicie y desarrolle la calidad como un proceso continuo e integral”. En este sentido, tendrá necesariamente como
                                        nota sobresaliente la vinculación entre la teoría y la práctica,
                                        entre el conocimiento y la realidad.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i> Considerando</h6>
                                    <p className="text-muted mb-2">Que el Reglamento de las Instituciones
                                        y Programas de Educación Superior a Distancia, aprobado mediante la resolución No. 9-2006 del 11 de diciembre del
                                        2016, en su artículo 56, Acápite c, propugna para que toda Institución de Educación Superior a Distancia en su funcionamiento debe tener una oferta académica
                                        con una estructura curricular que exhiba la renovación sustancial de los contenidos de la formación profesional, en beneficio del desarrollo
                                        de competencias (capacidades, conocimientos, habilidades y valores), que incrementen la competitividad de sus profesionales egresados,
                                        garantizando su integración eficaz al mercado de trabajo actual</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i> Considerando</h6>
                                    <p className="text-muted mb-2">Que los Estatutos de la Universidad Abierta para Adultos, aprobado mediante resolución
                                        de enero 2019, establecen en el Acápite f, del artículo 5 que para brindar educación superior al servicio de la sociedad, su acción
                                        está orientada a contribuir al establecimiento de lazos efectivos entre la Universidad y el mundo laboral, incorporando la
                                        experiencia adquirida como parte integrante de la formación
                                        universitaria. </p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i> Considerando</h6>
                                    <p className="text-muted mb-2">Que el artículos 29 del Reglamento Académico de Pregrado y Grado de la UAPA, aprobadas las
                                        modificaciones mediante la Resolución No. 35- 2021 del 21 de noviembre 2021, establece que, además de los ciclos de
                                        formación definidos en la estructura curricular de las carreras de grado, se contempla la práctica profesional como un
                                        componente que pone al participante en contacto con los problemasdel campo social y ocupacional, a través de la integración
                                        de conocimientos, habilidades, destrezas y valores desarrollados a lo largo de la carrera, con el propósito de demostrar las
                                        competencias de egreso adquiridas, en ámbitos laborales o comunitarios relacionados con su área de formación.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i> Considerando</h6>
                                    <p className="text-muted mb-2">Que los planes de estudios de las carreras de pregrado y grado vigentes, tienen la Pasantía como una
                                        práctica profesional obligatoria con créditos y horas prácticas en el campo laboral con el seguimiento de la institución.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i> Considerando</h6>
                                    <p className="text-muted mb-2">Que el Modelo Educativo por Competencias Centrado en el Aprendizaje (MECCA) de la UAPA
                                        establece en el numeral 3.3.4.2, que las competencias profesionales en cada carrera deben estar asociadas al saber hacer,
                                        referidas a las habilidades, destrezas y conocimientos prácticos propios de la profesión, que deben ser desarrolladas durante
                                        el periodo de formación del participante en la Universidad.
                                    </p>

                                    <h6 className="card-title mb-3"> VISTAS</h6>

                                    <p className="text-muted mb-2">  La Ley 139-01 de Educación Superior, Ciencia y Tecnología.</p>

                                    <p className="text-muted mb-2">   El Reglamento de Instituciones y Programas de Educación Superior a Distancia del
                                            MESCYT.</p>

                                    <p className="text-muted mb-2">  Los Estatutos y el Reglamento Académico de Pregrado y Grado de la UAPA.</p>

                                    <p className="text-muted mb-2">  El Modelo Educativo por Competencias Centrado en el Aprendizaje de la UAPA
                                            (MECCA,2009).</p>
                                    
                                    <p className="text-muted mb-2">El Consejo Académico, en uso de las atribuciones que le confieren los Estatutos y el
                                        Reglamento Académico de Pregrado y Grado, RESUELVE: aprobar la modificación al
                                        Reglamento de Práctica Profesional (Pasantías).</p>

                                    <h6 className="card-title mb-3"> CAPÍTULO I</h6>
                                    <h6 className="card-title mb-3"> DE LA DEFINICIÓN, TIPOLOGÍA, OBJETIVOS Y ÁMBITOS DE APLICACIÓN</h6>

                                    <h6 className="card-title mb-3">De la definición</h6>


                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 1.</h6>
                                    <p className="text-muted mb-2">La práctica profesional (pasantía), se define
                                        como el conjunto de actividades formativas de carácter práctico que realizan los participantes en empresas o instituciones
                                        públicas y privadas, nacionales e internacionales, relacionadas con la propuesta curricular de los estudios cursados en la
                                        Universidad, que le permitirán la aplicación en forma integrada y selectiva de los conocimientos, habilidades, destrezas,
                                        actitudes y valores en el campo laboral. </p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Párrafo.</h6>
                                    <p className="text-muted mb-2">En el abordaje metodológico de la práctica profesional (pasantía), se hace énfasis en la práctica laboral de
                                        acuerdo al perfil de egresado, la investigación y el Emprendimiento Empresarial. </p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 2.</h6>
                                    <p className="text-muted mb-2">La UAPA asume dos formas de práctica
                                        profesional, la práctica Interinstitucional y la virtual.</p>

                                    <p className="text-muted mb-2"> a)  La práctica profesional interinstitucional: Es la integración del participante en un
                                            contexto de aprendizaje situado en escenarios reales, con la finalidad de demostrar las
                                            competencias adquiridas para el ejercicio profesional de sus respectivas carreras.</p>

                                    <p className="text-muted mb-2"> b)  La práctica profesional virtual: Permite a los participantes
                                            desarrollar su labor fuera de las instalaciones físicas de una
                                            empresa o institución, mediante actividades simuladas, vinculadas al perfil de egreso de la carrera que cursan, bajo la
                                            supervisión de un facilitador especialista de la Universidad.</p>
                                   
                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 3.</h6>
                                    <p className="text-muted mb-2">El Departamento de Práctica Profesional y
                                        Servicio Social, gestionará los procesos inherentes a situaciones especiales de movilidad de los participantes, tomando la
                                        decisión de autorizar la pasantía virtual, previa evaluación de
                                        cada caso.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Párrafo.</h6>
                                    <p className="text-muted mb-2">Los requerimientos para aprobar solicitudes de
                                        práctica profesional virtual, estarán establecidos en el Manual de Procedimientos de la Práctica Profesional o Pasantías. </p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 4.</h6>
                                    <p className="text-muted mb-2"> El objetivo general de la práctica profesional (pasantía), es complementar la formación adquirida por
                                        el participante en la Universidad con la práctica en ámbitos laborales relacionados con su área de formación, que le permita incorporar saberes,
                                        habilidades y actitudes vinculados a situaciones reales del mundo del trabajo. </p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 5.</h6>
                                    <p className="text-muted mb-2"> Los objetivos específicos de la Práctica Profesional (Pasantía) son:</p>

                                    <p className="text-muted mb-2"> a) Promover el acercamiento constante entre los sectores productivos y la Universidad, a través de la
                                            realización de prácticasprofesionales que sean de beneficio mutuo.</p>

                                    <p className="text-muted mb-2"> b) Vincular al participante con el medio sociocultural y organizacional real con la finalidad de consolidar el desarrollo
                                            de las competencias propias del perfil de egreso de su carrera.</p>

                                    <p className="text-muted mb-2"> c) Fortalecer las competencias profesionales del pasante vinculadas al perfil de egreso de su carrera.</p>

                                    <p className="text-muted mb-2"> d) Afianzar la capacidad emprendedora y de responsabilidad
                                            social del pasante, a través de experiencias formativas integradoras en contextos institucionales y sectores productivos.</p>

                                   
                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 6.</h6>
                                    <p className="text-muted mb-2">La práctica profesional (pasantía), de acuerdo al lugar donde se desarrolle, se puede presentar en dos
                                        contextos de aplicación:</p>

                                    <p className="text-muted mb-2"> a) Pasantía Nacional: Cuando la pasantía se desarrolla en una empresa o institución ubicada en el
                                            territorio de la República Dominicana.</p>

                                    <p className="text-muted mb-2"> b) Pasantía Internacional: Cuando la pasantía se desarrolla
                                            en una empresa o institución ubicada fuera de la República Dominicana.</p>
        

                                    <h6 className="card-title mb-3"> CAPÍTULO II</h6>
                                    <h6 className="card-title mb-3"> DE LOS CONVENIOS DE PRÁCTICA PROFESIONAL (PASANTÍA) Y COMPROMISOS DE LAS PARTES</h6>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 7.</h6>
                                    <p className="text-muted mb-2">Para fines de realización de la práctica profesional (pasantía),
                                        la UAPA establecerá convenio interinstitucional y acuerdo escrito temporal con empresas e instituciones, nacionales e internacionales,
                                        que estén legalmente constituidas y dispongan
                                        de estructura organizacional. </p>

                                    <p className="text-muted mb-2"> a) Convenio interinstitucional: son aquellos que se realizan
                                            con empresas o instituciones, bajo la firma de un convenio
                                            donde se comprometen a recibir pasantes de manera constante e ininterrumpida, según su disponibilidad.</p>

                                    <p className="text-muted mb-2"> b) Acuerdo escrito temporal: son aquellos que se realizan con
                                            empresas o instituciones, que se comprometen a recibir pasantes, sin que exista un convenio interinstitucional por escrito.</p>
                                    
                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 8.</h6>
                                    <p className="text-muted mb-2"> La empresa o institución receptora de pasantes deberá asumir los siguientes compromisos:</p>

                                    <p className="text-muted mb-2"> a) Recibir a los participantes autorizados por la Universidad
                                            para realizar la pasantía durante el período acordado.</p>

                                    <p className="text-muted mb-2"> b) Asignar un supervisor para la inducción del proceso de la
                                            Pasantía, acompañamiento profesional, según los criterios de desempeño y el programa acordado en conjunto con la
                                            Universidad, a ser desarrollado por el pasante.</p>

                                    <p className="text-muted mb-2"> c) Asignar al pasante actividades relacionadas con el perfil de
                                            egreso de la carrera que cursa en la Universidad.</p>

                                    <p className="text-muted mb-2"> d) Evaluar el desempeño en el desarrollo de las diferentes
                                            actividades en la empresa o institución.</p>

                                    <p className="text-muted mb-2"> e) Garantizar al pasante condiciones adecuadas de higiene y
                                            seguridad en el lugar de trabajo.</p>

                                    <p className="text-muted mb-2"> f) Proporcionar al pasante informaciones relativas a las funciones y tareas a
                                            realizar y los instrumentos para cumplir las actividades asignadas.</p>

                                    <p className="text-muted mb-2"> g) Informar oportunamente a la Universidad sobre el desempeño del participante y
                                            otros requerimientos que sean necesarios.</p>

                                    <p className="text-muted mb-2"> h) Expedir al pasante los documentos descritos en los literales
                                            b, c y d del artículo 7, firmados y sellados por la autoridad competente de la empresa o institución.</p>


                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 9.</h6>
                                    <p className="text-muted mb-2">La práctica profesional (pasantía), es avalada a partir de la expedición de los siguientes documentos.
                                        Cada una de las partes se involucran en el proceso:</p>

                                    <p className="text-muted mb-2"> a) Carta de presentación del pasante emitida por el Departamento de Práctica Profesional y Servicio Social.</p>

                                    <p className="text-muted mb-2"> b) Certificación empresarial/institucional expedida por la empresa receptora del pasante.</p>

                                    <p className="text-muted mb-2"> c) Formulario de evaluación de desempeño del pasante.</p>

                                    <p className="text-muted mb-2"> d) Control de horas.</p>

                                    <p className="text-muted mb-2"> e) Programa de trabajo propuesto para la Práctica Profesional o Pasantía.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 10.</h6>
                                    <p className="text-muted mb-2">Son compromisos del pasante:</p>

                                    <p className="text-muted mb-2"> a) Cumplir en su totalidad con el programa de trabajo propuesto para la Práctica Profesional (Pasantía).</p>

                                    <p className="text-muted mb-2"> b) Acatar lo estipulado en el Reglamento de Práctica Profesional (Pasantía).</p>

                                    <p className="text-muted mb-2"> c) Respetar las políticas, normativas y manuales de procedimientos internos, y cualquier otra disposición, de la
                                            empresa o institución donde realice su Pasantía.</p>

                                    <p className="text-muted mb-2"> d) Respetar las normas institucionales y mantener confidencialidad en la institución que fue asignado el pasante.</p>

                                    <p className="text-muted mb-2"> e) Ejecutar las actividades propias previstas en el programa
                                            de trabajo de la pasantía y cumplir las funciones acordadas
                                            con las empresas o institución, aplicando conforme al campo laboral, los conocimientos adquiridos en la formación académica.</p>

                                    <p className="text-muted mb-2"> f) Informar al facilitador de cualquier novedad o irregularidad que afecte el cumplimiento del plan de actividades de
                                            la pasantía.</p>

                                    <p className="text-muted mb-2"> g) Informar al Departamento de Práctica Profesional y Servicio Social de cualquier irregularidad que afecte el cumplimiento
                                            del programa de trabajo de la pasantía relacionada al facilitador o guía de la asignatura.</p>

                                    <p className="text-muted mb-2"> h) Elaborar el informe final de la pasantía con sus respectivas
                                            evidencias en cumplimiento del programa de trabajo.</p>

                                    <p className="text-muted mb-2"> i) Entregar al facilitador o guía los documentos que avalan
                                            haber culminado la pasantía.</p>
    

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 11.</h6>
                                    <p className="text-muted mb-2">Cada pasante debe asumir un comportamiento ético y responsable en la realización de su pasantía.</p>


                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 12.</h6>
                                    <p className="text-muted mb-2"> El pasante deberá cumplir la cantidad de horas de pasantía establecidas en su pensum.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Párrafo:.</h6>
                                    <p className="text-muted mb-2">  El pasante que decida realizar su pasantía en
                                        una empresa o institución la cual tenga establecido en sus
                                        normativas, un número mayor de horas de las exigidas en
                                        su pensum, deberá ajustarse a dichas normativas, previo a
                                        un acuerdo firmado con el Departamento de Práctica Profesional y Servicio Social</p>


                                    <h6 className="card-title mb-3"> CAPÍTULO III</h6>
                                    <h6 className="card-title mb-3"> DE LA ESTRUCTURA Y FUNCIONES DEL DEPARTAMENTO DE PRÁCTICA PROFESIONAL Y SERVICIO SOCIAL</h6>


                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 13.</h6>
                                    <p className="text-muted mb-2">El Departamento de Práctica Profesional y Servicio Social, es una dependencia de la Vicerrectoría Académica,
                                        instancia responsable de la organización, funcionamiento, supervisión y control de las Pasantías y el Servicio Social Universitario.
                                        Está conformada por una Dirección General y el personal auxiliar necesario para realizar sus funciones. </p>


                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Párrafo:</h6>
                                    <p className="text-muted mb-2">En los recintos, el Departamento de Práctica Profesional y Servicio Social, estará dirigido por un encargado
                                        y el personal auxiliar necesario para realizar sus funciones, bajo la Dirección Académica del Recinto.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 14.</h6>
                                    <p className="text-muted mb-2">El Director del Departamento de Práctica Profesional y Servicio Social, se reunirá con los Encargados
                                        de los Departamento en los Recintos, periódicamente para consolidar, estandarizar las políticas y procedimientos de la
                                        Práctica Profesional y el Servicio Social Universitario de la Universidad.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 15.</h6>
                                    <p className="text-muted mb-2">El Director del Departamento de Práctica
                                        Profesional y Servicio Social, será designado por el Rector por
                                        un período de tres (3) años renovables, de una terna presentada por la Vicerrectoría Académica.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 16.</h6>
                                    <p className="text-muted mb-2">Para ser Director del Departamento de
                                        Práctica Profesional y Servicio Social se requiere:</p>

                                    <p  className="text-muted mb-2"> a) Ser dominicano o extranjero residente legal en el país.</p>

                                    <p className="text-muted mb-2"> b) Poseer cuando menos el grado de maestría o especialidad
                                            afín con el área de desempeño.</p>

                                    <p className="text-muted mb-2"> c) Estar en pleno uso de sus derechos civiles y políticos.</p>

                                    <p className="text-muted mb-2"> d)  Tener una experiencia mínima de dos (2) años en procesos
                                            afines al área de su formación.</p>

                                    <p className="text-muted mb-2"> e) Dominio del idioma inglés.</p>
                                   

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 17.</h6>
                                    <p className="text-muted mb-2">Las funciones del Director del Departamento de Práctica Profesional y Servicio Social, en la Sede o
                                        Encargado en los Recintos son:</p>

                                    <p className="text-muted mb-2"> a) Velar por el cumplimiento de las Normativas Institucionales y disposiciones emanadas de las autoridades de la
                                            Universidad que se vinculen con la Práctica Profesional y Servicio Social.</p>

                                    <p className="text-muted mb-2"> b) Elaborar y velar por el cumplimiento del Plan Operativo y
                                            el presupuesto anual del Departamento de Práctica Profesional y Servicio Social.</p>

                                    <p className="text-muted mb-2"> c) Elaborar la memoria anual del Departamento y presentar
                                            en la fecha establecida a la Vicerrectoría Académica.</p>

                                    <p className="text-muted mb-2"> d) Velar por que se cumplan efectivamente los procesos y las
                                            labores del personal a su cargo, evaluar su desempeño y promover su adecuado desarrollo.</p>

                                    <p className="text-muted mb-2"> e) Proponer mejoras innovadoras a la Vicerrectoría Académica para el desarrollo continuo del Departamento.</p>

                                    <p className="text-muted mb-2"> f) Gestionar los recursos y medios necesarios para el buen
                                            funcionamiento de las actividades del Departamento.</p>

                                    <p className="text-muted mb-2"> g) Establecer vínculos y alianzas estratégicas con empresas e
                                            instituciones públicas, privadas, sin fines de lucro, sociales, entre otras a nivel nacional e internacional, para firma de
                                            convenios formales sobre la ejecución de prácticas profesionales de los participantes, así como las de índole social.</p>

                                    <p className="text-muted mb-2"> h) Asignar en coordinación con los Directores de Escuela, los
                                            facilitadores para la orientación y seguimiento de la Práctica Profesional o Pasantía.</p>

                                    <p className="text-muted mb-2"> i) Diseñar programas o proyectos de ámbito social que sirvan como intervención en los sectores más vulnerable y que
                                            permita la participación social de los interesados de la Institución.</p>

                                    <p className="text-muted mb-2"> j) Ofertar las diferentes actividades de Servicio Social Universitario a los participantes que han concluido el periodo
                                            académico requerido para realizar el Servicio Social Universitario.</p>

                                    <p className="text-muted mb-2"> k) Velar por que se diseñen y ejecuten actividades de impacto
                                            social para la comunidad.</p>

                                    <p className="text-muted mb-2"> l) Autorizar la prestación del Servicio Social Universitario,
                                            conjuntamente con la Institución receptora.</p>

                                    <p className="text-muted mb-2"> m) Velar por que se lleve el debido registro y control, así
                                            como el seguimiento a los proyectos del Servicio Social que realizan los participantes.</p>

                                    <p className="text-muted mb-2"> n) Presidir el Comité de Exoneración de Pasantía por experiencia profesional.</p>

                                    <p className="text-muted mb-2"> o) Participar con voz y voto en las reuniones del Consejo Académico y del Consejo de los Recintos.</p>

                                    <p className="text-muted mb-2"> p) Colaborar a través de las práctica profesional (pasantía), en la realización de las actividades de investigaciones,
                                            Extensión y Vinculación Social promovidas por la Universidad o las Escuelas.</p>

                                    <p className="text-muted mb-2"> q) Coordinar la evaluación de los docentes con la Dirección
                                            General de Aseguramiento de la Calidad.</p>

                                    <p className="text-muted mb-2">Realizar cualquier otra función o atribución que le sea delegada o conferida de acuerdo con las reglamentaciones y
                                        Estatutos vigentes.</p>

                                    <h6 className="card-title mb-3"> CAPÍTULO IV</h6>
                                    <h6 className="card-title mb-3"> DEL COMITÉ DE PRÁCTICA PROFESIONAL (PASANTÍA)</h6>


                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 18.</h6>
                                    <p className="text-muted mb-2">El Comité de Pasantías es un organismo
                                        colegiado responsable de conocer, aprobar o rechazar las solicitudes de pasantías y exoneración por experiencia profesional. El mismo estará integrado por: </p>

                                    <p className="text-muted mb-2"> a) El director o encargado de Práctica Profesional y Servicio Social.</p>

                                    <p className="text-muted mb-2"> b) El director de la escuela que administra la carrera que cursa el participante.</p>

                                    <p className="text-muted mb-2"> c) Un facilitador especialista de la carrera que cursa el participante.</p>
                                    
                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Párrafo:</h6>
                                    <p className="text-muted mb-2"> Las políticas y procedimientos para aprobar o rechazar las solicitudes de pasantías o la exoneración por
                                        experiencia profesional, estarán establecidas en el Manual de Procedimientos de Práctica Profesional (Pasantías). </p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 19.</h6>
                                    <p className="text-muted mb-2">Las funciones del Comité de Pasantías son las siguientes:</p>

                                    <p className="text-muted mb-2"> a) Validar la documentación presentada por el participante para
                                            determinar si cumple o no con los requisitos establecidos.</p>

                                    <p className="text-muted mb-2"> b) Aprobar o rechazar la plaza propuesta por el participante
                                            para realizar la pasantía, de acuerdo al campo ocupacional.</p>

                                    <p className="text-muted mb-2"> c) Asignar al participante una plaza, en caso de haber sido
                                            rechazada la propuesta.</p>


                                    <p className="text-muted mb-2">Aprobar o rechazar la solicitud de exoneración de la pasantía
                                        por experiencia profesional.</p>

                                    <h6 className="card-title mb-3"> CAPÍTULO V</h6>
                                    <h6 className="card-title mb-3"> DE LA INSCRIPCIÓN DE LA PRÁCTICA PROFESIONAL (PASANTÍA)</h6>


                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 20.</h6>
                                    <p className="text-muted mb-2">El participante que desee realizar la práctica profesional (pasantía), debe cumplir con los siguientes
                                        requisitos:</p>

                                    <p className="text-muted mb-2"> a) Estar activo en el periodo académico en que solicita la pasantía.</p>

                                    <p className="text-muted mb-2"> b) Tener aprobadas todas las asignaturas del pensum de la carrera que cursa,
                                            hasta el período académico donde está programada la pasantía.</p>

                                    <p className="text-muted mb-2"> c) Haber realizado el Servicio Social Universitario.</p>

                                    <p className="text-muted mb-2"> d) Hacer la solicitud en línea 30 días antes de la fecha de su
                                            planificación de asignaturas.</p>

                                    <p className="text-muted mb-2"> e) Presentar una propuesta de plaza para realizar la pasantía,
                                            la cual puede ser aprobada o rechazada, luego de ser evaluada por el Comité de Pasantías.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 21.</h6>
                                    <p className="text-muted mb-2">El participante debe cumplir con los prerrequisitos establecidos para realizar la pasantía en el nivel del
                                        pensum de la carrera que cursa.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 22.</h6>
                                    <p className="text-muted mb-2">El participante debe cumplir con los prerrequisitos establecidos para realizar la pasantía en el nivel del
                                        pensum de la carrera que cursa.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 23.</h6>
                                    <p className="text-muted mb-2">El Departamento de Práctica Profesional y Servicio Social, después de recibir las solicitudes de pasantías,
                                        coordina con la Dirección de Registro la creación de grupos por carreras.</p>


                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 24.</h6>
                                    <p className="text-muted mb-2">El Departamento de Práctica Profesional y Servicio Social, autorizará la inclusión de la pasantía, luego
                                        que el Comité verifique que el solicitante cumple con todos los requisitos institucionales y cuando tenga confirmada la
                                        plaza, en una empresa o institución.</p>


                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 25.</h6>
                                    <p className="text-muted mb-2">Con la autorización del Departamento de Práctica Profesional y Servicio Social, el participante debe
                                        formalizar su inscripción y pagar el costo estipulado por la Universidad.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 26.</h6>
                                    <p className="text-muted mb-2">Luego de aprobada la plaza, el Departamento de Práctica Profesional y Servicio Social, remitirá a
                                        la empresa o institución la carta de presentación del pasante con el formato de certificación empresarial/institucional, el
                                        formulario de evaluación del desempeño y el control de horas y el Programa de Trabajo, para los fines de ser admitido. </p>


                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 27.</h6>
                                    <p className="text-muted mb-2">Un grupo de pasantía podrá tener como máximo 20 participantes. </p>


                                    <h6 className="card-title mb-3"> CAPÍTULO VI</h6>
                                    <h6 className="card-title mb-3"> DE LA EXONERACIÓN DE LA PRÁCTICA PROFESIONAL (PASANTÍA) POR EXPERIENCIA PROFESIONAL</h6>


                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 28.</h6>
                                    <p className="text-muted mb-2">Todo participante que está laborando en una empresa o institución y desee solicitar la exoneración
                                        de la pasantía por experiencia profesional debe hacer la solicitud al Departamento de Práctica Profesional y Servicio Social,
                                        quien dará respuesta durante el tiempo establecido en el procedimiento, cumpliendo con los siguientes requisitos:</p>

                                    <p className="text-muted mb-2"> a) Hacer la solicitud al Departamento de Práctica Profesional y Servicio Social antes de la planificación
                                            del periodo académico en que cursará la pasantía.</p>

                                    <p className="text-muted mb-2"> b) Tener un tiempo mínimo de un año prestando servicio
                                            continuo en la empresa o institución y un mínimo de seis meses desempeñando el cargo relacionado con su carrera.</p>

                                    <p className="text-muted mb-2"> c) Las funciones y tareas que realiza deben estar en correspondencia con el perfil de egreso del plan de estudio en el
                                            cual está inscrito el participante.</p>

                                    <p className="text-muted mb-2"> d) Entregar una descripción o perfil del puesto, certificada
                                            y sellada por la Dirección, Gerencia o Departamento de Gestión Humana de la empresa o institución que está laborando.</p>

                                    <p className="text-muted mb-2"> e) Entregar una certificación laboral que indique el cargo o puesto de trabajo y el
                                            tiempo que tiene en esa posición, firmada y sellada por la Dirección, Gerencia o Departamento
                                            de Gestión Humana de la empresa o institución que está laborando.</p>

                                    <p className="text-muted mb-2"> f) Completar el formulario de evaluación de desempeño del puesto en la empresa o institución, suministrado por el
                                            Práctica Profesional y Servicio Social, firmada y sellada por la Dirección, Gerencia o Departamento de Gestión Humana de la empresa o institución en la
                                            que está laborando.</p>

                                    <p className="text-muted mb-2"> g) Copia de cédula de identidad electoral o pasaporte vigente.</p>

                                    <p className="text-muted mb-2"> h) Si el solicitante es propietario de la empresa o institución y realiza funciones relacionadas con su carrera,
                                            deberá presentar un acto notarial con firma certificada, indicando la posición, tiempo, funciones que realiza en la empresa o institución, y el número
                                            de registro mercantil. Además, Anexar copia de la autorización del registro mercantil.</p>

                                    <p className="text-muted mb-2"> i) Cualquier otro documento o acción de verificación que
                                            requiera el Comité responsable, para la exoneración de la pasantía por experiencia profesional.</p>
                                    

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>PÁRRAFO I:</h6>
                                    <p className="text-muted mb-2"> Posterior a la recepción de la solicitud de exoneración por experiencia profesional y las evidencias
                                        antes citadas, el Departamento de Práctica Profesional y Servicio Social, convocará al Comité de Pasantías, el cual evaluará la autenticidad
                                        de los requisitos presentados por el solicitante y aprobará o rechazará dicha solicitud. </p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>PÁRRAFO II:</h6>
                                    <p className="text-muted mb-2">En caso de aprobación de la solicitud de exoneración de la Pasantía por experiencia profesional, el
                                        Comité asignará una calificación, basada en la evaluación de la documentación presentada por el participante de la empresa o institución
                                        donde está laborando. Posterior al proceso, se remitirá al Departamento de Registro el acta de calificación, junto a la documentación que
                                        avala la misma. </p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>PÁRRAFO III:</h6>
                                    <p className="text-muted mb-2">El Departamento de Práctica Profesional y Servicio Social, informará al participante de la aprobación
                                        de su solicitud de exoneración por experiencia profesional, quien deberá pagar el costo establecido por la Institución. </p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>PÁRRAFO IV:</h6>
                                    <p className="text-muted mb-2"> De no ser aprobada la solicitud de exoneración por experiencia profesional, el participante deberá
                                        iniciar el proceso ordinario, según los lineamientos del presente Reglamento. </p>


                                    <h6 className="card-title mb-3"> CAPÍTULO VII</h6>
                                    <h6 className="card-title mb-3"> DE LOS FACILITADORES, LAS COMPETENCIAS Y CRITERIOS A EVALUAR </h6>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>De los facilitadores:</h6>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 29.</h6>
                                    <p className="text-muted mb-2">El facilitador de pasantía es un docente del personal académico de la carrera a la que pertenece el pasante,
                                        que será seleccionado por el Departamento de Práctica Profesional y Servicio Social, en coordinación con los directores de escuelas. </p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 30.</h6>
                                    <p className="text-muted mb-2">Son responsabilidades del facilitador-guía de la pasantía: </p>

                                    <p className="text-muted mb-2"> a) Orientar a los pasantes sobre el proceso de pasantías en los
                                            encuentros definidos en los espacios de reflexión.</p>

                                    <p className="text-muted mb-2"> b) Entregar al pasante la carta de presentación y los formularios correspondientes para depositarlos en la empresa o
                                            institución donde fue autorizada la pasantía.</p>

                                    <p className="text-muted mb-2"> c) Participar en la actividad de capacitación programada por
                                            el Departamento de Práctica Profesional y Servicio Social.</p>

                                    <p className="text-muted mb-2"> d) Cumplir con el seguimiento del programa de trabajo establecido para el pasante a su cargo y el cronograma de encuentros
                                            u otras actividades programadas por el Departamento de Práctica Profesional y Servicio Social.</p>

                                    <p className="text-muted mb-2"> e) Comunicar al Departamento de Práctica Profesional y Servicio Social, cualquier novedad o irregularidad que afecte al
                                            pasante en el cumplimiento del plan de trabajo de pasantía.</p>

                                    <p className="text-muted mb-2"> f) Dar seguimiento al cumplimiento del programa de trabajo del pasante, en coordinación con el Supervisor o el Departamento
                                            de Gestión Humana de la empresa o institución en la que está colocado el pasante. que está laborando.</p>

                                    <p className="text-muted mb-2"> g) Recibir la certificación empresarial/institucional del pasante, el formulario de evaluación de desempeño y control
                                            de horas, firmado y sellado por la empresa o institución, con miras a validación.</p>

                                    <p className="text-muted mb-2"> h) Evaluar las actividades realizadas por el pasante durante el
                                            desarrollo de la Pasantía, según establecido en el programa de trabajo.</p>

                                    <p className="text-muted mb-2"> i) Crear y asignar actividades prácticas vinculadas al perfil de
                                            egreso del participante.</p>

                                    <p className="text-muted mb-2"> j) Dar seguimiento a las actividades prácticas y a la elaboración del Informe Final.</p>

                                    <p className="text-muted mb-2"> k) Unificar en el reporte de calificación final en el sistema
                                            académico de Registro, los resultados de la evaluación de desempeño realizada por la empresa o institución y la asignada durante el proceso.</p>

                                    <p className="text-muted mb-2"> l) Entregar al Departamento de Práctica Profesional y Servicio Social, los documentos de finalización de la pasantía de
                                            la empresa o institución, recibido del participante.</p>

                                    <p className="text-muted mb-2"> m) Participar como facilitador especialista del área de formación en el Comité de Pasantías cuando sea requerido.</p>

                                    <p className="text-muted mb-2"> n) Elaborar informes reflexivos acerca de las experiencias en sus facilitaciones del Curso de Pasantía que sugieran
                                            oportunidades de mejora en el programa y la metodología implementada en el mismo.</p>


                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 31.</h6>
                                    <p className="text-muted mb-2">El Departamento de Práctica Profesional y Servicio Social dará seguimiento a los procedimientos establecidos,
                                        a través de informes de los facilitadores que están coordinando la pasantía y otros controles que se establezcan.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 32.</h6>
                                    <p className="text-muted mb-2">Las competencias generales o transversales y específicas a evaluar en cada carrera, estarán establecidas en
                                        el formulario de evaluación del desempeño del participante durante la pasantía.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 33.</h6>
                                    <p className="text-muted mb-2">El Departamento de Práctica Profesional y Servicio Social, pondrá a disposición de los pasantes las
                                        orientaciones para la elaboración y presentación del Informe Final, en correspondencia con el programa de trabajo establecido y los
                                        criterios de desempeño previstos según su perfil de egreso.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>De la evaluación.</h6>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 34.</h6>
                                    <p className="text-muted mb-2">La evaluación de la pasantía es un proceso sistemático de seguimiento, valoración y medición para
                                        verificar los niveles de desempeño del pasante en actividades del campo laboral, vinculadas a las competencias generales y
                                        específicas de la carrera que cursa..</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 35.</h6>
                                    <p className="text-muted mb-2">Al finalizar el proceso de pasantía, la empresa o institución receptora, realizará una evaluación del
                                        desempeño del pasante en el desarrollo del plan de trabajo, en cuanto a sus competencias cognitivas, actitudinales y específicas de la carrera,
                                        con base a un instrumento de evaluación definido y entregado por Departamento de Práctica Profesional y Servicio Social. </p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 36.</h6>
                                    <p className="text-muted mb-2">La calificación final será distribuida de la siguiente forma:</p>

                                    <p className="text-muted mb-2"> a) El supervisor de la empresa o institución evaluará las competencias especificadas en el formulario de evaluación de
                                            desempeño de la pasantía con base en sesenta (60) puntos.</p>

                                    <p className="text-muted mb-2"> b) El facilitador-guía de la pasantía, evaluará el cumplimien- to del programa de trabajo registrado en el Informe Final,
                                            incluidas las evidencias de tal cumplimiento, y la partici- pación en los espacios de reflexión y socialización de los aprendizajes desarrollados
                                            durante la práctica, en base a cuarenta (40) puntos.</p>
                            

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 37.</h6>
                                    <p className="text-muted mb-2">La calificación mínima para aprobar la pasantía será de 70 puntos a nivel de pregrado y grado.</p>

                                    <h6 className="card-title mb-3"> CAPÍTULO VIII</h6>
                                    <h6 className="card-title mb-3"> DE LAS CONSECUENCIAS DEL NO CUMPLIMIENTO </h6>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 38.</h6>
                                    <p className="text-muted mb-2">Todo incumplimiento por parte de un pasante de cualquiera de las responsabilidades establecidas en
                                        este Reglamento, conllevará la aplicación de medidas disciplinarias y formativas, de acuerdo a las sanciones que se indican a continuación:</p>

                                    <p className="text-muted mb-2"> a) Suspensión temporal del programa hasta que el pasante se
                                            comprometa a no repetir las faltas que se le imputan.</p>

                                    <p className="text-muted mb-2"> b) ElReprobación del programa de la pasantía, para que el pasante en falta tenga que iniciarlo de nuevo.</p>

                                    <p className="text-muted mb-2"> c) En caso de cambio de empresa o institución, no se reconocerá el tiempo, ni lo proceso realizados en la pasantía
                                            anterior.</p>

                                    <p className="text-muted mb-2"> d) Aplicación de las sanciones disciplinarias contempladas en el Reglamento de Participantes, Reglamento
                                            Interno de disciplina, si fuere necesario.</p>


                                    <p className="text-muted mb-2">EL PRESENTE REGLAMENTO FUE APROBADO EL
                                        DÍA 4 DE MAYO DEL AÑO 2003; MODIFICADO EN FECHA 9 DE OCTUBRE DE 2012, MEDIANTE LA RESOLUCIÓN DEL CONSEJO ACADÉMICO No. 18-2012; MODIFICADO EL 12 DE NOVIEMBRE DE 2013,
                                        MEDIANTE LA RESOLUCIÓN No. 33-2013; MODIFICADO EL 8 DE FEBRERO DE 2022, MEDIANTE LA RESOLUCIÓN 07-2022, EN LA CIUDAD DE SANTIAGO DE LOS CABALLEROS, REPÚBLICA DOMINICANA.</p>

                                    <h6 className="card-title mb-3">DADO y APROBADO LA CIUDAD DE SANTIAGO DE LOS CABALLEROS EN SESIÓN
                                        ORDINARIA DEL CONSEJO ACADÉMICO, A LOS CATORCE (14) DÍAS DEL MES DE
                                        NOVIEMBRE DE 2023.</h6>

                                    <div className="d-flex justify-content-end gap-2 btncontainer">
                                        <Button className='actulizarboton'>Aceptar</Button>
                                        <Button className="cancelarboton"><i className="ri-close-line align-bottom me-1"></i> Declinar</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

        </React.Fragment>
    )
}

export default InternshipRegulation;