import BreadCrumb from 'Common/BreadCrumb';
import React from 'react'
import { Container } from 'react-bootstrap';
import TableComponent from './TableComponent';

const IntershipRequests = () => {

  document.title = "Solicitudes de Pasantía"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Solicitudes" pageTitle="Pasantía" />
        </Container>
        <div className='custom-box'>
          <TableComponent />
        </div>
      </div>
    </React.Fragment>
  );


}

export default IntershipRequests;