export type ConfigurationEndpoints = {
    [key: string]: string;
};

export const configuration = {
    'Provincias': '/provinces',
    'Carreras': '/degree',
    'Períodos': '/periods',
    'Recintos': '/campus',
    'Municipios': '/boroughs',
};