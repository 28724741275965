import { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Request } from 'Common/data/requests';
import axiosInstance from 'services/axios';

const useAllRequestsData = () => {
    const [requests, setRequests] = useState<Request[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const fetchRequests = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get<{ data: Request[] }>("/participants/allParticipantsFull");

            if (response.data && Array.isArray(response.data.data)) {
                const fetchedRequests = response.data.data;

                if (fetchedRequests.length > 0) {
                    setRequests(fetchedRequests);
                } else {
                    console.log("No hay solicitudes disponibles.");
                    setRequests([]);
                }
            } else {
                toast.error("Estructura de datos inesperada al obtener las solicitudes");
            }
        } catch (error) {
            console.log("Error al obtener las solicitudes, intente más tarde o comuníquese con el administrador");
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchRequests();
    }, [fetchRequests]);

    return { requests, setRequests, loading, refetchData: fetchRequests };
};

export default useAllRequestsData;