import { Element } from "services/apiEndpoints";

export interface Jornada extends Element {
    request: number;
    whatsappLink: string;
}

export interface Participant {
    id: number;
    name: string;
    lastname: string;
    enrollmentNumber: string;
    email: string;
    serviceSession?: { id: number };
    info?: {
        id?: number;
        identification?: string;
        sex?: string;
        phone?: string;
        whatsapp?: string;
        personalEmail?: string;
        province?: { id: number, name: string };
        borough?: { id: number, name: string };
        campus?: { id: number, name: string };
        degree?: { id: number, name: string };
        period?: { id: number, name: string };
    };
    requests: {
        id: number;
        createdAt: string;
        updatedAt: string;
        description: string;
        typeRequestName: string;
        statusName: string;
        degreeName: string;
        campusName: string;
        documents: string[];
        [key: string]: any;
    };
}

export const genderMap: { [key: string]: string } = {
    "Femenino": "female",
    "Masculino": "male",
    "Otro": "other"
};

// PAra retornar el valor inverso del mapa de género para cuando viene de la DB
export const getInverseGenderMap = (value: string): string => {
    const keys = Object.keys(genderMap);
    for (let key of keys) {
        if (genderMap[key] === value) {
            return key;
        }
    }
    return value;
};

export const SocialServiceEndpoints = {
    'Provincias': '/provinces',
    'Carreras': '/degree',
    'Períodos': '/periods',
    'Recintos': '/campus',
    'Municipios': '/boroughs',
    'Jornadas': '/serviceSessions'
};

export const ssuformTabOne = [
    {
        label: "Nombre",
        placeholder: "Ingrese su nombre",
        id: "nameInput",
        value: "",
        type: "text",
        col: 6,
    },
    {
        label: "Apellidos",
        placeholder: "Ingrese sus apellidos",
        id: "lastNameInput",
        value: "",
        type: "text",
        col: 6,
    },
    {
        label: "Cédula",
        placeholder: "Ingrese su cedula",
        id: "identificationInput",
        value: "",
        type: "text",
        col: 4,
    },
    {
        label: "Sexo",
        placeholder: "Ingrese su sexo",
        id: "genderInput",
        option: ["Femenino", "Masculino", "Otro"],
        value: "",
    },
    {
        label: "Teléfono",
        placeholder: "Ingrese su telefono",
        id: "phoneInput",
        type: "number",
        value: "",
    },
    {
        label: "Whatsapp",
        placeholder: "Ingrese su whatsapp",
        id: "whatsappInput",
        type: "number",
        value: "",
    },
    {
        label: "Correo",
        placeholder: "Ingrese su correo electronico",
        id: "emailInput",
        type: "email",
        value: "",
    },
    {
        label: "Provincia",
        id: "Provincias",
        option: [],
    },
    {
        label: "Municipio",
        id: "Municipios",
        option: [],
    },
];

export const ssuformTabTwo = [
    {
        label: "ID - Matrícula",
        id: "enrollmentInput",
        placeholder: "Ingrese su matricula, eje: 100020202",
        type: "number",
        value: "",
    },
    {
        label: "Recinto u oficina de apoyo a la que perteneces ",
        id: "Recintos",
        option: [],
        value:  ""
    },
    {
        label: "Período Académico",
        id: "Períodos",
        option: [],
        value: ""
    },
    {
        label: "Carrera",
        id: "Carreras",
        option: [],
        value: ""
    },
    {
        label: "Correo Institucional",
        id: "institutionalEmailInput",
        placeholder: "ejem: 202002153@p.uapa.edu.do",
        type: "email",
        value: ""
    },
    {
        label: "Seleccione la jornada a la cual le gustaría participar:",
        id: "Jornadas",
        placeholder: "ejem: 202002153@p.uapa.edu.do",
        option: [],
        value: ""
    },
];