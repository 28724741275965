import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";

interface MediaProps {
    url: string;
    width?: string;
    height?: string;
    className?: string;
}
export const Media: React.FC<MediaProps> = ({ url, width = '100%', height = '100%', className = "doc-style" }) => {
    const isImage = url.match(/\.(jpeg|jpg|gif|png)$/i) != null;
    const isVideo = url.match(/\.(mp4|webm|ogg)$/i) != null || url.includes('youtube.com/');

    if (isVideo) {
        return <video src={url} width={width} height={height} controls className={className} />
    } else if (isImage) {
        return <Image src={url} alt="Document" style={{ width, height }} className={className}/>;
    } else {
        return <div><span>La visualización no esta disponible</span><Link to={url}>{url}</Link></div>;
    }
};
