import axiosInstance from 'services/axios';

export interface Element {
    data(data: any): unknown;
    name: string;
    id: number;
}

export const fetchElement = async (url: string): Promise<Element> => {
    try {
        const response = await axiosInstance.get(url);
        return response.data;
    } catch (error) {
        console.error("Error fetching element", error);
        throw error;
    }
}

export const fetchElements = async (endpoint: string): Promise<Element[]> => {
    try {
        const response = await axiosInstance.get(endpoint);
        return response.data;
    } catch (error) {
        console.error("Error fetching elements", error);
        throw error;
    }
};

export const createElement = async (endpoint: string, elementData: any): Promise<Element> => {
    try {
        const response = await axiosInstance.post(endpoint, elementData);
        return response.data;
    } catch (error) {
        console.error("Error creating element", error);
        throw error;
    }
};

export const updateElement = async (endpoint: string, elementId: number, elementData: any): Promise<Element> => {
    try {
        const response = await axiosInstance.put(`${endpoint}/${elementId}`, elementData);
        return response.data;
    } catch (error) {
        console.error("Error updating element", error);
        throw error;
    }
};

export const deleteElement = async (endpoint: string, elementId: number): Promise<void> => {
    try {
        await axiosInstance.delete(`${endpoint}/${elementId}`);
    } catch (error) {
        console.error("Error deleting element", error);
        throw error;
    }
};