import React from 'react';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

// Images
import welcomeImage from "assets/images/Login/Computer login-pana(1).svg";
import signup from "assets/images/auth/signup.svg";
import withRouter from 'Common/withRouter';

const ParticlesAuth = ({children}:any) => {
    const location = useLocation();

  let image, alt;
  if (location.pathname === '/login') {
    image = welcomeImage
    alt = "Imagen de una joven sentada en una computadora portátil"
  } else if (location.pathname === '/signup') {
    image = signup
    alt = "Imagen de un joven con una vista para registrarse"
  }
    return (
        <React.Fragment> 
            <div className="auth-page-wrapper py-5 position-relative d-flex align-items-center justify-content-center min-vh-100">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={12}>
                            <Card className="mb-0">
                                <Row className="g-0 align-items-center">
                                    <Col lg={6} className="mx-auto">
                                        <Image src={image} alt={alt} className="img-fluid" />
                                    </Col>
                                    {/* Pass Children */}
                                    {children}
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(ParticlesAuth);