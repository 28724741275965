import React, { useState } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import PaginationComponent from "Components/Pagination";
import usePagination from "Common/Hooks/UsePagination";
import useFilter from "Common/Hooks/UseFilter";
import { columnNamesAdmin } from "Common/data/columnNames";
import ModalComponent from "Components/Modal";
import { useLocation } from 'react-router-dom';
import LordIcon from "Components/LordIcon";
import useAllRequestsData from "Common/Hooks/useAllRequestsData";
import "assets/scss/pages/_requests.scss";
import { Request } from "Common/data/requests";

function TableComponent() {
  document.title = "Servicio Social - Participantes";

  const icon = require('../../../assets/animations/nodata.json');
  const { requests, loading, refetchData } = useAllRequestsData();
  const filteredRequestsByType: Request[] = requests.filter(req =>
    req.requests.some(request => request.typeRequest.name === "Solicitud de Servicio Social")
  );
  const { setSearch, filteredRequests } = useFilter(filteredRequestsByType);
  const itemsPerPage = 5;
  const { currentPage, setCurrentPage, itemsToShow } = usePagination<Request>(filteredRequests, itemsPerPage);
  const [showModal, setShowModal] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState<Request['requests'][0] | null>(null);
  const location = useLocation();
  const currentView = location.pathname;

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(1);
    setSearch(e.target.value);
  };

  const openModal = (request: Request['requests'][0]) => {
    setSelectedRequest(request);
    setShowModal(true);
  };

  const handleApprove = async () => {
    await refetchData();
    setShowModal(false);
    setCurrentPage(1);
  };

  const handleReject = async () => {
    await refetchData();
    setShowModal(false);
    setCurrentPage(1);
  };

  // Renderizar las columnas para acceder tanto a participant como a requests
  const renderCellContent = (item: Request, key: keyof typeof columnNamesAdmin): React.ReactNode => {
    const cellMapping: { [key in keyof typeof columnNamesAdmin]: string | undefined } = {
      id: item.requests?.[0]?.id?.toString(),
      name: item.name,
      lastname: item.lastname,
      enrollmentNumber: item.enrollmentNumber,
      createdAt: new Date(item.requests?.[0]?.createdAt).toLocaleDateString(),
      email: item.email,
      statusName: item.requests?.[0]?.status.name,
      description: item.requests?.[0]?.description,
    };

    return cellMapping[key] || 'N/A';
  };

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Row className="box-header align-items-center g-2">
                <Col>
                  <h6 className="card-title mb-0">Servicio Social - Participantes</h6>
                </Col>
                <Col className="search-box-container">
                  <div className="search-box">
                    <Form.Control
                      type="text"
                      className="search"
                      placeholder="Buscar por cualquier criterio..."
                      onChange={handleSearch}
                    />
                    <i className="ri-search-line search-icon"></i>
                  </div>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              {loading ? (
                <div className="d-flex justify-content-center align-content-center">
                  <p>Cargando solicitudes...</p>
                </div>
              ) : (
                <div className="table-container">
                  <table className="table-responsive table">
                    <thead className="table-light">
                      <tr>
                        {Object.values(columnNamesAdmin).map((columnName, index) => (
                          <th key={index}>{columnName}</th>
                        ))}
                        <th>Acción</th>
                      </tr>
                    </thead>
                    <tbody>
                      {itemsToShow.length > 0 ? (
                        itemsToShow.map((request: Request, idx) => (
                          <tr key={idx}>
                            {Object.keys(columnNamesAdmin).map((key) => (
                              <td key={key} data-column={`${columnNamesAdmin[key]}:`}>
                                {renderCellContent(request, key as keyof typeof columnNamesAdmin)}
                              </td>
                            ))}
                            <td>
                              <div className="d-flex justify-content-center align-items-center">
                                <Button
                                  onClick={() => openModal(request.requests[0])}
                                  className="btnver"
                                  disabled={!request.requests || request.requests.length === 0}
                                >
                                  <i className="ri ri-eye-line"></i>
                                </Button>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={Object.keys(columnNamesAdmin).length + 1}>
                            <div className="d-flex justify-content-center align-content-center">
                              <LordIcon icon={icon} />
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}
              <ModalComponent
                show={showModal}
                onHide={() => setShowModal(false)}
                view={currentView}
                request={selectedRequest}
                participant={selectedRequest ? requests.find(r => r.requests.some(req => req.id === selectedRequest.id)) || null : null}
                onApprove={handleApprove}
                onReject={handleReject}
              />
              <div>
                <nav aria-label="requests navigation">
                  <PaginationComponent
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalItems={filteredRequests.length}
                    itemsPerPage={itemsPerPage}
                  />
                </nav>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default TableComponent;