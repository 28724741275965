import { useState, useEffect } from 'react';

// Hook para guardar el estado en el local storage
export function usePersistentState<T>(defaultValue: T, key: string): [T, React.Dispatch<React.SetStateAction<T>>] {
    const [state, setState] = useState<T>(() => {
        // Obtener el estado persistente del local storage
        const persistentState = localStorage.getItem(key);
        return persistentState !== null
            ? JSON.parse(persistentState).value
            : defaultValue;
    });

    // Guardar el estado en el local storage
    useEffect(() => {
        localStorage.setItem(key, JSON.stringify({ value: state })); 
    }, [key, state]);

    return [state, setState];
}