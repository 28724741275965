import React, { useState } from 'react';
import axios from 'axios';
import { Card, Col, Button, Form, Alert, Spinner } from 'react-bootstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { Link } from 'react-router-dom';
import withRouter from 'Common/withRouter';
import { useNavigate } from 'react-router-dom';
import { usePersistentState } from 'Common/Hooks/UsePersistentState';

const Signin = () => {
    const defaultAuthState = { isLoggedIn: false, accessToken: null};
    const [authState, setAuthState] = usePersistentState(defaultAuthState, 'auth');
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [passwordShow, setPasswordShow] = useState<boolean>(false);
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    
    // Manejamos loscambios de cada unos de los campos
    const handleChange = (e: any) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    // Para iniciar sesión
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);
    
        try {
            const response = await axios.post('https://sagespra.uapa.edu.do/api/auth/login', formData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    
            const accessToken = response.data.access_token;
            localStorage.setItem('accessToken', accessToken);
            console.log('Token de acceso almacenado correctamente:', accessToken);
    
            // Define el nuevo estado de autenticación
            const newUserAuthState = {
                isLoggedIn: true,
                accessToken: accessToken,
            };
    
            // Guarda el nuevo estado de autenticación
            setAuthState(newUserAuthState);
    
            navigate('/home');
        } catch (error: any) {
            setError(error.response?.data?.message || 'Error al iniciar sesión. Inténtalo de nuevo.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <ParticlesAuth>
            <React.Fragment>
                <Col xxl="6" className="mx-auto">
                    <Card className="mb-0 border-0 shadow-none mb-0">
                        <Card.Body className="p-sm-5 m-lg-4">
                            <div className="text-center mt-5">
                                <h5 className="fs-3xl">BIENVENIDOS A SAGESPRA - INICIO DE SESIÓN</h5>
                               <p className="text-muted">Sistema Automatizado Para la Gestión del Servicio Social y la Práctica Profesional </p>
                            </div>
                            <div className="p-2 mt-5">
                                {error && error ? (<Alert variant="danger"> {error} </Alert>) : null}

                                <Form onSubmit={handleSubmit}>

                                    <Form.Group className="mb-3" controlId="formEmail">
                                        <Form.Label>Correo Institucional <span className="text-orange-uapa">*</span></Form.Label>
                                        <div className="position-relative">
                                            <Form.Control
                                                type="email"
                                                name="email"
                                                className="form-control password-input"
                                                placeholder="Ingrese su correo"
                                                required
                                                onChange={handleChange}
                                                value={formData.email}
                                            />
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formPassword">
                                        <div className="float-end">
                                            <Link to={process.env.PUBLIC_URL + "/forgot-password"} className="text-decoration-underline link-orange-uapa link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">Olvidaste la contraseña?</Link>
                                        </div>
                                        <Form.Label>Contraseña <span className="text-orange-uapa">*</span></Form.Label>
                                        <div className="position-relative auth-pass-inputgroup mb-3">
                                            <Form.Control type={passwordShow ? "text" : "password"} className="form-control pe-5 password-input " placeholder="Ingrese su contraseña" required
                                                name="password"
                                                value={formData.password}
                                                onChange={handleChange}
                                            />
                                            <Button variant="link" className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}>{passwordShow ? (
                                                <i className="fa fa-eye-slash"></i>
                                            ) : (
                                                <i className="fa fa-eye"></i>
                                            )}</Button>
                                        </div>
                                    </Form.Group>

                                    <Form.Group controlId="formRememberMe">
                                        <Form.Check type="checkbox" label="Recordarme" id="auth-remember-check" />
                                    </Form.Group>

                                    <div className="mt-4">
                                        <Button className="btn btn-login w-100" type="submit" disabled={loading}> {loading && <Spinner size='sm' />} {" "}Iniciar sesión</Button>
                                    </div>

                                </Form>
                            </div>
                            <div className="mt-4 text-center">
                                <div>
                                    <div className="mt-4 text-center">
                                        <p className="mb-0">¿No tienes una cuenta? <Link to={process.env.PUBLIC_URL + "/signup"} className="text-decoration-underline link-orange-uapa link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">Regístrate</Link></p>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </React.Fragment>
        </ParticlesAuth>
    );
};

export default withRouter(Signin);
