import React, { useState } from 'react';
import { Card, Col, Button, Form, Image, Container, Row } from 'react-bootstrap';
import forgotPassword from "assets/images/auth/forgotPassword.svg";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';

const ForgotPassword = () => {

    document.title = "UAPA - Olvidó su contraseña";

    const [email, setEmail] = useState('');
    const [isInvalid, setIsInvalid] = useState(false);

    const validateEmail = (email: string) => {
        const regex = /(@p.uapa.edu.do|@uapa.edu.do)$/;
        return regex.test(email);
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
        setIsInvalid(!validateEmail(newEmail) && newEmail.length > 0);
    }

    const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if (!validateEmail(email)) {
            toast.error("Por favor ingrese un correo válido");
            return;
        } else {
            toast.success("Correo enviado");
        }
    }
    return (
        <React.Fragment>
            <div className="auth-page-wrapper py-5 position-relative d-flex align-items-center justify-content-center min-vh-100">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={12}>
                            <Card className="mb-0">
                                <Row className="g-0 align-items-center">
                                    <Col lg={6} className="mx-auto" >
                                        <Image src={forgotPassword} alt="Ilustracion de una mujer que olvido su contraseña" className="img-fluid mw-50" />
                                    </Col>
                                    <Col lg={6} className="mx-auto">
                                        <Card className="border-0 shadow-none mb-0">
                                            <Card.Body className="p-sm-5">
                                                <div className="text-center">
                                                    <h5 className="fs-3xl">¿Olvidó su contraseña?</h5>
                                                    <p className="text-muted">Ingrese su correo institucional para enviarle las instrucciones</p>
                                                </div>
                                                <div className="p-2 mt-5">
                                                    <Form action="#" onSubmit={(e) => {
                                                        e.preventDefault();
                                                    }}>
                                                        <Form.Group className="mb-3" controlId="formUsername">
                                                            <Form.Label>Correo Institucional <span className="text-orange-uapa">*</span></Form.Label>
                                                            <div className="position-relative">
                                                                <Form.Control
                                                                    type="email"
                                                                    name="email"
                                                                    placeholder="Ingrese el correo"
                                                                    value={email}
                                                                    onChange={handleEmailChange}
                                                                    isInvalid={isInvalid}
                                                                    isValid={email.length > 0 && !isInvalid}
                                                                    required
                                                                />
                                                                <Form.Control.Feedback type="invalid">Por favor ingrese un correo válido</Form.Control.Feedback>
                                                            </div>
                                                        </Form.Group>

                                                        <div className="mt-4">
                                                            <Button variant='blue-uapa' className="btn w-100" onClick={handleSubmit} type="submit">Enviar email</Button>
                                                        </div>

                                                    </Form>
                                                </div>
                                            </Card.Body>
                                            <Card.Footer className="border-0 text-center">
                                                <p className="mb-0">¿Recuerda su contraseña? <Link to="/login" className="text-decoration-underline  link-orange-uapa link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">Iniciar sesión</Link></p>
                                            </Card.Footer>
                                        </Card>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ForgotPassword;