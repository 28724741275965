import React from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import CountUp from 'react-countup';

import { widgetsStyle } from 'Common/data/widgets';

const Widgets = () => {

    return (
        <React.Fragment>
            <Row>
                {
                    (widgetsStyle || [])?.map((item: any, index: number) => {
                        return (
                            <Col lg={6} xxl={3} key={index}>
                                <Card className="file-manager-widgets" style={{ backgroundSize: "95px", backgroundPosition: "right bottom" }}>
                                    <Card.Body>
                                        <div className="d-flex align-items-center mb-4">
                                            <div className="flex-grow-1">
                                                <div className="avatar-sm">
                                                    <div className={`avatar-title bg-${item.imgBg} text-light rounded fs-xl`}>
                                                        <i className={item.img}></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-shrink-0">
                                                <p><span className={`text-${item.iconColor} me-1`}><i className={`${item.icon} fs-sm align-middle`}></i> +{item.num} %</span> vs. semana pasada</p>
                                            </div>
                                        </div>
                                        <h4 className="fw-semibold mb-3">
                                            <CountUp start={0} end={item.count} />
                                        </h4>
                                        <p className="fw-medium text-muted mb-0">{item.title}</p>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                    })
                }
            </Row>
        </React.Fragment>
    );
};

export default Widgets;
