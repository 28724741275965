import { Navigate } from "react-router-dom";

// Authentication
import Login from "pages/Authentication/Login";
import UserProfile from "pages/User/UserProfile";
import UserProfileSettings from "pages/User/UserProfileSettings";

// Home
import Home from "pages/Home/Home";

// Requests
import CreateIntershipRequest from "pages/Requests/IntershipRequest/CreateIntershipRequest";
import CreateSocialServiceRequest from "pages/Requests/SocialServiceRequest/CreateSocialServiceRequest";
import MyRequestStatus from "pages/Requests/MyRequestStatus/MyRequestStatus";

// Administration
import RequestMetrics from "pages/Administration/Metrics";
import SocialServiceRequest from "pages/Administration/SocialServiceAdmin/Requests";
import SocialServiceConfig from "pages/Administration/SocialServiceAdmin/Configuration";
import IntershipRequest from "pages/Administration/IntershipAdmin/Requests";
import IntershipConfig from "pages/Administration/IntershipAdmin/Configuration";
import DocumentationRequest from "pages/Requests/Documentations/DocumentationRequest";
import SsuRegulation from "pages/Requests/Documentations/SsuRegulation";
import InternshipRegulation from "pages/Requests/Documentations/InternshipRegulation";
import ForgotPassword from "pages/Authentication/ForgotPassword";
import PageNotFound from "pages/AuthenticationInner/Errors/404Error";
import SignUp from "pages/Authentication/SignUp";
import GeneralConfig from "pages/Other/Configuration";
import PageNotAuthorized from "pages/AuthenticationInner/Errors/401Error";


const authProtectedRoutes = [
     // User
     { path: "/user-profile", component: UserProfile, roles: ['Administrador', 'Pasantía', 'Servicio Social', 'Estudiante'] },
     { path: "/user-profile-settings", component: UserProfileSettings, roles: ['Estudiante', "Administrador"] },
 
     // Home
     { path: "/home", component: Home, roles: ['Administrador', 'Pasantía', 'Servicio Social', 'Estudiante'] },
     
     // Requests
     { path: "/create-social-service-request", component: CreateSocialServiceRequest, roles: ['Administrador', 'Servicio Social', 'Estudiante'] },
     { path: "/create-intership-request", component: CreateIntershipRequest, roles: ['Administrador', 'Pasantía', 'Estudiante'] },
     { path: "/my-request-status", component: MyRequestStatus, roles: ['Administrador', 'Estudiante'] },
 
     // Administration
     { path: "/requests-metrics", component: RequestMetrics, roles: ['Administrador'] },
 
     { path: "/social-service-configs", component: SocialServiceConfig, roles: ['Administrador', 'Servicio Social'] },
     { path: "/social-service-requests", component: SocialServiceRequest, roles: ['Administrador', 'Servicio Social', 'Estudiante'] },
 
     { path: "/intership-requests", component: IntershipRequest, roles: ['Administrador', 'Pasantía'] },
     { path: "/intership-configs", component: IntershipConfig, roles: ['Administrador', 'Pasantía'] },
 
     //Other
     { path: "/general-config", component: GeneralConfig, roles: ['Administrador', 'Pasantía', 'Servicio Social'] },
 
    //Regulations and Documentation
    { path: "/ssu-regulation", component: SsuRegulation, roles: ['Administrador', 'Estudiante', 'Servicio Social'] },
    { path: "/intern-regulation", component: InternshipRegulation, roles: ['Administrador', 'Pasantía', 'Estudiante'] },
    { path: "/documentation-request", component: DocumentationRequest , roles: ['Administrador', 'Pasantía', 'Estudiante', 'Servicio Social'] },

];

const publicRoutes = [
    // Authentication
    { path: "/", exact: true, component: <Navigate to="/login" /> },
    { path: "/login", name: "Login", component: <Login /> },
    { path: "/signup", name: "Signup", component: <SignUp /> },
    { path: "/forgot-password", name: "Forgot Password", component: <ForgotPassword /> },
    { path: "/not-found", name: "PageNotFound", component: <PageNotFound /> },
    { path: "/unauthorized", name: "Unauthorized", component: <PageNotAuthorized />},
    { path: "*",  component: <Navigate to="/not-found" /> },

];

export { authProtectedRoutes, publicRoutes };