import React, { useState, useEffect } from 'react';
import { Container, Button, Form, Row, Col, ListGroup } from 'react-bootstrap';
import BreadCrumb from 'Common/BreadCrumb';
import { fetchElements, createElement, updateElement, deleteElement, Element } from 'services/apiEndpoints';
import { ConfigurationEndpoints, configuration } from 'Common/data/endpointsData';
import { toast } from 'react-toastify';
import "assets/scss/components/_generalconfig.scss"

const GeneralConfig: React.FC = () => {
    document.title = "Configuración General";

    const [inputs, setInputs] = useState<{ label: string, elements: Element[] }[]>([]);
    const [newElementData, setNewElementData] = useState<{ [key: string]: Partial<Element> }>({});
    const [editElement, setEditElement] = useState<Element | null>(null);
    const [editingColumn, setEditingColumn] = useState<string | null>(null);

    // Función para obtener el endpoint correcto basado en el label
    const getEndpoint = (label: string): string => (configuration as ConfigurationEndpoints)[label] || '';

    // Obtener los datos
    useEffect(() => {
        const fetchData = async () => {
            try {
                const responses = await Promise.all(
                    Object.keys(configuration).map(key => fetchElements(configuration[key as keyof typeof configuration]))
                );
    
                const inputsData = Object.keys(configuration).map((label, index) => ({
                    label,
                    elements: responses[index]
                }));
    
                setInputs(inputsData);
            } catch (error) {
                toast.error("Error al obtener los datos iniciales, intente más tarde o comuníquese con el administrador");
            }
        };
    
        fetchData();
    }, []);

    // Función para detectar cambios en el input
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, label: string) => {
        setNewElementData({
            ...newElementData,
            [label]: { ...newElementData[label], name: e.target.value }
        });
    };

    // Función para agregar o editar un elemento
    const handleAddOrEdit = async (label: string) => {
        const endpoint = getEndpoint(label);
        const elementData = newElementData[label];

        if (!elementData || !elementData.name) {
            toast.error("El campo esta vacio, favor ingrese la información.");
            return;
        }

        try {
            if (editElement) {
                await updateElement(endpoint, editElement.id, elementData);
                const updatedElements = inputs.map(input =>
                    input.label === label ? {
                        ...input, elements: input.elements.map(element =>
                            element.id === editElement.id ? { ...element, ...elementData } : element
                        )
                    } : input
                );
                setInputs(updatedElements);
                toast.success("Se ha actualizado correctamente");
            } else {
                const newElement = await createElement(endpoint, elementData);
                setInputs(inputs.map(input =>
                    input.label === label ? { ...input, elements: [...input.elements, newElement] } : input
                ));
                toast.success("Se ha agregado correctamente");
            }
        } catch (error) {
            toast.error(`Error al ${editElement ? 'actualizar' : 'crear'} el elemento, intente más tarde o comuníquese con el administrador`);
        }

        setNewElementData({ ...newElementData, [label]: { name: '' } });
        setEditElement(null);
        setEditingColumn(null);
    };

    // Función para editar un elemento
    const handleEdit = (label: string, elementId: number) => {
        const editedElement = inputs.find(input => input.label === label)?.elements.find(element => element.id === elementId);
        if (editedElement) {
            setEditElement(editedElement);
            setNewElementData({ ...newElementData, [label]: { name: editedElement.name } });
            setEditingColumn(label);
        } else {
            toast.error("Ocurrió un error al intentar editar el elemento, intente más tarde o comuníquese con el administrador");
        }
    };

    // Función para eliminar un elemento
    const handleDelete = async (label: string, elementId: number) => {
        try {
            const endpoint = getEndpoint(label);
            await deleteElement(endpoint, elementId);
            const updatedElements = inputs.map(input =>
                input.label === label ? { ...input, elements: input.elements.filter(element => element.id !== elementId) } : input
            );
            setInputs(updatedElements);
            toast.success("Se ha eliminado correctamente");
        } catch (error) {
            toast.error("Ocurrió un error al intentar eliminar el elemento, intente más tarde o comuníquese con el administrador");
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Configuración" pageTitle="Actualizaciones" />
                    <Row>
                        {inputs.map(input => (
                            <Col key={input.label} md="12" lg="6" xl="4" className="my-4">
                                <div className="bg-white p-3 rounded shadow">
                                    <h5 className="mb-3">{input.label}</h5>
                                    <ListGroup className="list-group-container">
                                        {input.elements.map(element => (
                                            <ListGroup.Item key={element.id} className="d-flex justify-content-between align-items-center">
                                                <span className="element">
                                                    {element.name}
                                                </span>
                                                <div>
                                                    <div className="btn-group" role="group">
                                                        <Button onClick={() => handleEdit(input.label, element.id)} className="btneditar bi bi-pencil"></Button>
                                                        <Button onClick={() => handleDelete(input.label, element.id)} className="btneliminar bi bi-trash3"></Button>
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                    <Form className="mt-3">
                                        <Form.Group className="mb-3">
                                            <Form.Control
                                                type="text"
                                                placeholder={`Nuevo ${input.label.slice(0, -1).toLowerCase()}`}
                                                value={newElementData[input.label]?.name || ''}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e, input.label)}
                                            />
                                        </Form.Group>
                                        <div className="form-btn-container">
                                            <Button onClick={() => handleAddOrEdit(input.label)} className="btnguardar">
                                                {editElement && editingColumn === input.label ? 'Editar' : 'Agregar'}
                                            </Button>
                                        </div>
                                    </Form>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default GeneralConfig;