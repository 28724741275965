import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { useLocation } from 'react-router-dom';

const useProfile = () => {
  const token = localStorage.getItem('accessToken');
  const [loading, setLoading] = useState(token ? true : false);
  const [userProfile, setUserProfile] = useState<any>(null);
  const [error, setError] = useState('');
  const [roleName, setRoleName] = useState<string | null>(null);
  const [allowedRoles, setAllowedRoles] = useState<string[]>([]);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const location = useLocation();
  const [roleLoading, setRoleLoading] = useState(true);


  // Primer useEffect que se ejecuta cuando cambia el token
  useEffect(() => {
    const fetchData = async () => {
      if (token) {
        const { email } = jwtDecode<{ email: string }>(token);

        try {
          const getRole = await fetch(`https://sagespra.uapa.edu.do/api/users/${email}/role`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          const roleData = await getRole.json();
          setRoleName(roleData.name);

          if(roleData.name === "Estudiante"){
             // Obtenemos los datos del perfil de usuario
            const profileResponse = await fetch('https://sagespra.uapa.edu.do/api/participants/profile', {
            headers: {
              'Authorization': `Bearer ${token}`
            }
            }).then(response => response.json());
            setUserProfile(profileResponse);
          }
          else{
             // Obtenemos los datos del perfil de usuario
             const profileResponse = await fetch('https://sagespra.uapa.edu.do/api/administratives/profile', {
              headers: {
                'Authorization': `Bearer ${token}`
              }
              }).then(response => response.json());
              setUserProfile(profileResponse);
          }

          
          // Obtenemos el rol del usuario
          const roleResponse = await fetch(`https://sagespra.uapa.edu.do/api/users/${email}/role`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }).then(response => response.json());

          // Actualizamos el perfil del usuario con el rol
          setUserProfile((prevState: any) => ({
            ...prevState,
            role: roleResponse
          }));

          const role = roleResponse.name;
          setRoleName(role);

          // Obtenemos los roles permitidos
          const rolesResponse = await fetch('https://sagespra.uapa.edu.do/api/roles').then(response => response.json());
          const roleNames = rolesResponse.map((role: { name: string }) => role.name);
          setAllowedRoles(roleNames);
          console.log('Roles permitidos', roleNames);
        } catch (error) {
          console.log('Error al obtener los datos del usuario', error);
          setError('Error al obtener los datos del usuario');
        } finally {
          setRoleLoading(false);
        }
      }
    };

    fetchData();
  }, [token]);

  // Segundo useEffect que se ejecuta cuando cambia el token, el rol, el perfil de usuario y los roles permitidos
  useEffect(() => {
    if (token && roleName && userProfile && allowedRoles.length > 0) {
      if (allowedRoles.includes(roleName)) {
        setIsAuthorized(true);
        setLoading(false);
        console.log('El usuario tiene permisos?')
        console.log('el rol actual', roleName)
        console.log('allowedRoles.include', allowedRoles.includes(roleName))
        setRoleLoading(false);
        console.log('Termino la carga de roles')
      } else {
        setIsAuthorized(false);
        setRoleLoading(false);
        console.log('Termino la carga de roles')
        console.log('El usuario no tiene permisos')
      }
    }
  }, [token, roleName, userProfile, allowedRoles, location]);

  return { userProfile, loading, token, roleName, allowedRoles, isAuthorized, roleLoading, setUserProfile };
}
export { useProfile };