import React, { useEffect, useState } from "react";

const Navdata = () => {
    //state data
    const [isCreateRequest, setIsCreateRequest] = useState(false);
    const [isIntershipAdmin, setIsIntershipAdmin] = useState(false);
    const [isSocialServiceAdmin, setIsSocialServiceAdmin] = useState(false);

    const [iscurrentState, setIscurrentState] = useState('Home');

    function updateIconSidebar(e: any) {
        if (e && e.target && e.target.getAttribute("sub-items")) {
            const ul: any = document.getElementById("two-column-menu");
            const iconItems: any = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id: any = item.getAttribute("sub-items");
                var menusId = document.getElementById(id);
                if (menusId) {
                    (menusId.parentElement as HTMLElement).classList.remove("show");
                }
            });
            e.target.classList.add("active");
        }
    }
    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');

        if (iscurrentState !== 'CreateRequest') {
            setIsCreateRequest(false);
        }

        if (iscurrentState !== 'IntershipAdmin') {
            setIsIntershipAdmin(false);
        }

        if (iscurrentState !== 'SocialServiceAdmin') {
            setIsSocialServiceAdmin(false);
        }
    }, [
        iscurrentState,
        isCreateRequest,
        isIntershipAdmin,
        isSocialServiceAdmin
    ]);

    const menuItems: any = [
        {
            id: "home",
            icon: "bi bi-house",
            label: "Inicio",
            link: "/home",
            roles: ['Administrador', 'Estudiante', 'Pasantía', 'Servicio Social']
        },
        {
            label: "Solicitudes",
            isHeader: true,
            roles: ['Administrador', 'Estudiante']
        },
        {
            id: "my-request-status",
            label: "Estado solicitudes",
            icon: "bi-hourglass-split",
            link: "/my-request-status",
            roles: ['Estudiante', 'Administrador']
        },
        {
            id: "createRequest",
            label: "Crear nueva",
            icon: "bi-file-earmark-plus",
            link: "/#",
            roles: ['Estudiante', 'Administrador'],
            click: function (e: any) {
                e.preventDefault();
                setIsCreateRequest(!isCreateRequest);
                setIscurrentState('CreateRequest');
                updateIconSidebar(e);
            },
            stateVariables: isCreateRequest,
            subItems: [
                {
                    id: "createSocialServiceRequest",
                    label: "Servicio Social",
                    link: "/create-social-service-request",
                    parentId: "createRequest",
                    roles: ['Estudiante', 'Administrador']
                },
                {
                    id: "createIntershipRequest",
                    label: "Pasantia",
                    link: "/create-intership-request",
                    parentId: "createRequest",
                    roles: ['Estudiante', 'Administrador']
                }
            ],
        },
        {
            id: "documentation",
            label: "Documentación",
            icon: "bx bx-detail",
            link: "/documentation-request",
            roles: ['Administrador', 'Estudiante']
        },
        {
            label: "Administración",
            isHeader: true,
            roles: ['Administrador, Pasantía, Servicio Social']
        },
        {
            id: "metrics",
            icon: "bi-bar-chart",
            label: "Metricas",
            link: "/requests-metrics",
            roles: ['Administrador']
        },
        {
            id: "intership-admin",
            label: "Pasantias",
            icon: "ri-briefcase-4-line",
            link: "/#",
            roles: ['Administrador', 'Pasantía'],
            click: function (e: any) {
                e.preventDefault();
                setIsIntershipAdmin(!isIntershipAdmin);
                setIscurrentState('IntershipAdmin');
                updateIconSidebar(e);
            },
            stateVariables: isIntershipAdmin,
            subItems: [
                { id: 1, label: "Ver solicitudes", link: "/intership-requests", parentId: "intership-admin", roles: ['Administrador', 'Pasantía'] },
                {
                    id: 2, label: "Configuraciones", link: "/intership-configs", parentId: "intership-admin",
                    roles: ['Administrador', 'Pasantía']
                },
            ],
        },
        {
            id: "social-service-admin",
            label: "Servicio Social",
            icon: "ri-user-heart-line",
            link: "/#",
            roles: ['Administrador', 'Servicio Social'],
            click: function (e: any) {
                e.preventDefault();
                setIsSocialServiceAdmin(!isSocialServiceAdmin);
                setIscurrentState('SocialServiceAdmin');
                updateIconSidebar(e);
            },
            stateVariables: isSocialServiceAdmin,
            subItems: [
                { id: 1, label: "Ver solicitudes", link: "/social-service-requests", parentId: "social-service-admin", icon: "bi bi-eye", roles: ['Administrador', 'Servicio Social'] },
                { id: 2, label: "Configuraciones", link: "/social-service-configs", parentId: "social-service-admin", icon: "bi-gear", roles: ['Administrador', 'Servicio Social'] },
            ],
        },
        {
            label: "Otras secciones",
            isHeader: true,
            roles: ['Administrador', 'Pasantía', 'Servicio Social']
        },
        {
            id: "Configuraciones",
            label: "Configuraciones",
            icon: "bi-gear",
            link: "/general-config",
            roles: ['Administrador', 'Pasantía', 'Servicio Social']
        },

    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};

export default Navdata;