import React from 'react'
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import "assets/scss/pages/_404.scss"

const PageNotFound = () => {

    document.title = "Pagina no encontrada  (Coloca el acento en la a)"

    return (
        <React.Fragment>
            <div className="custom-page-content home">
                <Container fluid>
                    <div id="container">
                        <div className="content">
                            <h2>404</h2>
                            <h4>Opps! Pagina No Encontrada</h4>
                            <p>Parece que te has perdido en la red.
                                ¡No te preocupes, hasta los mejores navegadores se desorientan!</p>
                            <Link to="/login"> Regresar al Inicio</Link>
                        </div>
                    </div>
                </Container>
            </div>
        </React.Fragment> 
    ) 

}

export default PageNotFound;