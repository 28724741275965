import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Form, InputGroup, ListGroup } from 'react-bootstrap';
import { FaWindowClose } from 'react-icons/fa';
import "assets/scss/components/_chatComponent.scss";
import LordIcon from './LordIcon';

const Chatbot = () => {
    const icon = require('assets/animations/Uapi.json');
    const [show, setShow] = useState(false);
    const [question, setQuestion] = useState('');
    const [conversation, setConversation] = useState<{ sender: string, message: string }[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [showHelloMessage, setShowHelloMessage] = useState(true);

    // useEffect para mostrar el mensaje tan pronto cargue la pagina
    useEffect(() => {
        if (showHelloMessage) {
            const timer = setTimeout(() => {
                setShowHelloMessage(false);
            }, 4000);
            return () => clearTimeout(timer);
        }
    }, [showHelloMessage]);

    const toggleChat = () => {
        setShowHelloMessage(false);
        setShow(prevShow => !prevShow);
        if (!show) {
            setQuestion('');
            setConversation([{ sender: 'bot', message: 'Hola! ¿Cómo te puedo ayudar hoy?' }]);
            setError(null);
        }
    };

    // Manejador para detectar la tecla Enter
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleAsk();
        }
    };

    //Manejo de respuesta
    const handleAsk = async () => {
        if (!question.trim()) return;

        const userMessage = { sender: 'user', message: question };
        setConversation([...conversation, userMessage]);
        setLoading(true);
        setError(null);

        try {
            const res = await axios.post('https://sagespra.uapa.edu.do/api/chatbot/ask', { question });
            const botResponse = { sender: 'bot', message: res.data.response };
            setConversation([...conversation, userMessage, botResponse]);
        } catch (error) {
            setError('Error al procesar la pregunta, intentelo de nuevo más tarde.');
        } finally {
            setLoading(false);
            setQuestion('');
        }
    };

    return (
        <div className="chat-container">
            {showHelloMessage && <div className="hello-message">Hola</div>}
            {show ? null : (
                <div className="chat-toggle-btn btn" onClick={toggleChat}>
                    <LordIcon icon={icon} />
                </div>
            )}

            {show && (
                <div className={`chat-box`}>
                    <div className="chat-header">
                        <span className='chat-header-title'>Uapi</span>
                        <div className="chat-header-icons">
                            <FaWindowClose onClick={toggleChat} />
                        </div>
                    </div>
                    {(
                        <>
                            <ListGroup variant="flush" className="chat-body">
                                {conversation.map((msg, idx) => (
                                    <ListGroup.Item key={idx} className={msg.sender === 'user' ? 'user-message' : 'bot-message'}>
                                        {msg.message}
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                            {error && <p className="text-danger">{error}</p>}
                            <InputGroup className="mt-3">
                                <Form.Control
                                    placeholder="Preguntar..."
                                    aria-label="Preguntar"
                                    value={question}
                                    onKeyDown={handleKeyDown} 
                                    onChange={(e) => setQuestion(e.target.value)}
                                    disabled={loading}
                                    className="form-control"
                                />
                                <Button variant="btn-outline-blue-uapa" className="btn btn-blue-uapa" onClick={handleAsk} disabled={loading}>
                                    {loading ? 'Enviando...' : 'Enviar'}
                                </Button>
                            </InputGroup>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default Chatbot;