import { useEffect, useRef } from 'react';
import { Player } from '@lordicon/react';

interface LordIconProps {
  icon: string;
}

export default function LordIcon({ icon }: LordIconProps) {    
  const playerRef = useRef<Player>(null);
  
  useEffect(() => {
    playerRef.current?.playFromBeginning();
  }, [])

  return (
    <Player 
      ref={playerRef} 
      icon={icon}
      size={96}
      onComplete={() => playerRef.current?.playFromBeginning()}
    />
  );
}