import { useState } from "react";
import { Request } from 'Common/data/requests';

const useFilter = (requests: Request[]) => {
  const [search, setSearch] = useState<string>("");

  const filteredRequests = requests.filter(request => {
    const values: string[] = [
      ...Object.values(request).filter(value => value !== null && value !== undefined),
      ...Object.values(request.info || {}).filter(value => value !== null && value !== undefined),
      ...Object.values(request.requests[0] || {}).filter(value => value !== null && value !== undefined)
    ].map(value => value.toString().toLowerCase());

    return values.some(value => value.includes(search.toLowerCase()));
  });

  return { search, setSearch, filteredRequests };
};

export default useFilter;
