// Mapear nombres de columnas para la tabla de los administradores
const columnNamesAdmin: { [key: string]: string } = {
  id: "#",
  name: "Nombre",
  lastname: "Apellidos",
  enrollmentNumber: "Matrícula",
  createdAt: "Fecha de solicitud",
  email: "Email Institucional",
  statusName: "Estado",
  description: "Descripción",
};

// Mapear nombres de columnas para la tabla de participantes
const columnNamesStudents: { [key: string]: string } = {
  id: "#",
  createdAt: "Fecha de creación",
  updatedAt: "Fecha de actualización",
  description: "Descripción",
  "typeRequest.name": "Tipo de Solicitud",
  "status.name": "Estado",
  "degree.name": "Carrera",
  "campus.name": "Campus",
};

// Mapear nombres de columnas para toda la información de requests.ts
const columnNamesRequest: { [key: string]: string } = {
  id: "#",
  name: "Nombre",
  lastname: "Apellidos",
  identification: "Cédula",
  gender: "Femenino",
  phone: "Teléfono",
  whatsapp: "Whatsapp",
  enrollmentNumber: "Matrícula",
  "serviceSession.name": "Evento",
  borough: "Ciudad",
  province: "Provincia",
  createdAt: "Fecha de solicitud",
  institutionalEmail: "Email Institucional",
  "degree.name": "Carrera",
  period: "Periodo",
  campusName: "Recinto",
  statusName: "Estado",
  description: "Descripción",
  "typeRequest.name": "Tipo de solicitud",
};

// Mapear nombres de columnas para la tabla de documentación en métricas
const columnNamesDocumentation: { [key: string]: string } = {
  id: "#",
  name: "Nombre",
  lastname: "Apellidos",
  matricula: "Matrícula",
  createDate: "Enviado",
  institutionalEmail: "Email Institucional",
  status: "Estado",
  requestsType: "Tipo de solicitud",
  documents: "Documentos",
};

export { columnNamesDocumentation, columnNamesRequest, columnNamesStudents, columnNamesAdmin };
