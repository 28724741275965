import BreadCrumb from 'Common/BreadCrumb'
import React from 'react'
import TableComponent from './TableComponent'

const SocialServiceRequests = () => {

    document.title = "Servicio Social - Participantes";
  
    return (
      <React.Fragment>
        <div className="page-content">
          <div>
            <BreadCrumb title="Solicitudes" pageTitle="Servicio Social" />
          </div>
          <div className='custom-box'>
            <TableComponent />
          </div>
        </div>
      </React.Fragment>
    );
 
  
}

export default SocialServiceRequests;