import React, { useState } from 'react';
import { Button, Card, Col, Form, Nav, Row, Tab, Table } from 'react-bootstrap';
import { useSocialServiceRequest } from '../../../Common/Hooks/useSocialServiceRequest';
import "assets/scss/pages/_ProfileSettings.scss";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import axiosInstance from 'services/axios';

const Personal = () => {
    const { participant, data, formData, handleChange, handleSubmitInfo, roleName } = useSocialServiceRequest();
    const [ShowPwdBTN1, SetShowPwdBTN1] = useState(false);
    const [ShowPwdBTN2, SetShowPwdBTN2] = useState(false);
    const [ShowPwdBTN3, SetShowPwdBTN3] = useState(false);
    const [Enable, SetEnable] = useState(true);
    const [passwordErrors, setPasswordErrors] = useState<string[]>([]);
    const [passwordForm, setPasswordForm] = useState({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    });

    // para validar contrasena (pendiente)
    const validatePassword = (password: string): string[] => {
        const errors = [];
        if (!/(?=.*\d)/.test(password)) {
            errors.push('La contraseña debe contener al menos un dígito (0-9).');
        }
        if (!/(?=.*[a-z])/.test(password)) {
            errors.push('La contraseña debe contener al menos una letra minúscula (a-z).');
        }
        if (!/(?=.*[A-Z])/.test(password)) {
            errors.push('La contraseña debe contener al menos una letra mayúscula (A-Z).');
        }
        if (!/.{8,}/.test(password)) {
            errors.push('La contraseña debe tener al menos 8 caracteres de longitud.');
        }
        return errors;
    };

    // Deteccion de cambios en los inputs password
    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        setPasswordForm((prevForm) => ({
            ...prevForm,
            [id]: value,
        }));
        setPasswordErrors(validatePassword(value));
    };

    //Para la validacion de la data del form del primer tab
    const validatePersonalData = (): boolean => {
        if (roleName === "Estudiante") {
            const requiredFields = ['nameInput', 'lastNameInput', 'identificationInput', 'phoneInput', 'emailInput', 'genderInput', 'Provincias', 'Municipios'];
            for (const field of requiredFields) {
                if (!formData[field]) {
                    toast.error('Todos los campos son obligatorios.');
                    return false;
                }
            }
            return true;
        } else {
            toast.error("Esto es solo para los participantes.");
            return false;
        }
    };

    //Para manejar el submit del primer Form
    const handleGeneralInfo = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!validatePersonalData()) return;
        try {
            await handleSubmitInfo(e);
            toast.success('Datos actualizados correctamente');
        } catch (error) {
            toast.error('Error al actualizar los datos');
        }
    };

    // PAra manejar el submitform del ultimo tab 
    const handleEducationalInfo = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            await handleSubmitInfo(e);
            toast.success('Datos actualizados correctamente');
        } catch (error) {
            toast.error('Error al actualizar los datos');
        }
    };

    //Para manejar el form de la contrasena //Pending mover a componenten aparte
    const handlePasswordSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const newPasswordErrors = validatePassword(passwordForm.newPassword);
        if (newPasswordErrors.length > 0) {
            setPasswordErrors(newPasswordErrors);
            return;
        }

        if (passwordForm.newPassword !== passwordForm.confirmNewPassword) {
            toast.error('Las nuevas contraseñas no coinciden');
            return;
        }

        try {
            await axiosInstance.put('/auth/changepassword', {
                currentPassword: passwordForm.currentPassword,
                newPassword: passwordForm.newPassword,
            });
            toast.success('Contraseña cambiada exitosamente');
            setPasswordForm({
                currentPassword: '',
                newPassword: '',
                confirmNewPassword: '',
            });
            setPasswordErrors([]);
        } catch (error: any) {
            if (error.response && error.response.status === 401) {
                toast.error('No autorizado. Por favor, inicie sesión de nuevo.');
            } else if (error.response && error.response.status === 400) {
                toast.error('Contraseña actual incorrecta.');
            } else {
                toast.error('Error al cambiar la contraseña');
            }
        }
    };

    return (
        <React.Fragment>
            <Col xl={12}>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row className="d-flex align-items-center flex-wrap gap-2 mb-4">
                        <div className="col-md order-1">
                            <Nav variant='pills' className="arrow-navtabs gap-2 flex-grow-1">
                                <Nav.Item>
                                    <Nav.Link eventKey="first" href="#personalDetails">Datos personales</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second" href="#changePassword">Cambiar Contraseña</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="third" href="#education">Educación</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                        <div className="col-md-auto order-lg-2">
                            <Button type="button" className='botoneditar' onClick={() => SetEnable(false)}>Editar Perfil</Button>
                        </div>
                    </Row>
                    <Tab.Content>
                        <Tab.Pane eventKey="first" id="personalDetails">
                            <Card>
                                <Card.Header>
                                    <h6 className="card-title mb-0">Datos Personales</h6>
                                </Card.Header>
                                <Card.Body>
                                    <Form onSubmit={handleGeneralInfo}>
                                        <Row>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Form.Label htmlFor="nameInput">Nombre</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        id="nameInput"
                                                        placeholder="Introduce tu nombre"
                                                        value={formData.nameInput}
                                                        onChange={handleChange}
                                                        disabled={Enable}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Form.Label htmlFor="lastNameInput">Apellidos</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        id="lastNameInput"
                                                        placeholder="Introduce tu apellido"
                                                        value={formData.lastNameInput}
                                                        onChange={handleChange}
                                                        disabled={Enable}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Form.Label htmlFor="identificationInput">Cédula</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        id="identificationInput"
                                                        placeholder="Introduce numero de cedula"
                                                        value={formData.identificationInput}
                                                        onChange={handleChange}
                                                        disabled={Enable}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Form.Label htmlFor="phoneInput">Teléfono</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        id="phoneInput"
                                                        placeholder="Introduce numero de telefono"
                                                        value={formData.phoneInput}
                                                        onChange={handleChange}
                                                        disabled={Enable}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Form.Label htmlFor="whatsappInput">WhatsApp</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        id="whatsappInput"
                                                        placeholder="Introduce numero de WhatsApp"
                                                        value={formData.whatsappInput}
                                                        onChange={handleChange}
                                                        disabled={Enable}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Form.Label htmlFor="emailInput">Correo Electrónico</Form.Label>
                                                    <Form.Control
                                                        type="email"
                                                        id="emailInput"
                                                        placeholder="Introduce correo electrónico"
                                                        value={formData.emailInput}
                                                        onChange={handleChange}
                                                        disabled={Enable}
                                                    />
                                                </div>
                                            </Col>
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="genderInput">Sexo</Form.Label>
                                                <Form.Select
                                                    id="genderInput"
                                                    value={formData.genderInput}
                                                    onChange={handleChange}
                                                    disabled={Enable}
                                                >
                                                    <option value="">Seleccionar</option>
                                                    <option value="Masculino">Masculino</option>
                                                    <option value="Femenino">Femenino</option>
                                                    <option value="Otro">Otro</option>
                                                </Form.Select>
                                            </Form.Group>
                                            <Col lg={12}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label htmlFor="Provincias">Provincia</Form.Label>
                                                    <Form.Select
                                                        id="Provincias"
                                                        value={formData.Provincias}
                                                        onChange={handleChange}
                                                        disabled={Enable}
                                                    >
                                                        <option value="">Seleccionar</option>
                                                        {data['Provincias']?.map(province => (
                                                            <option key={province.id} value={province.id}>{province.name}</option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label htmlFor="Municipios">Municipio</Form.Label>
                                                    <Form.Select
                                                        id="Municipios"
                                                        value={formData.Municipios}
                                                        onChange={handleChange}
                                                        disabled={Enable}
                                                    >
                                                        <option value="">Seleccionar</option>
                                                        {data['Municipios']?.map(borough => (
                                                            <option key={borough.id} value={borough.id}>{borough.name}</option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}>
                                                <div className="hstack gap-2 justify-content-end">
                                                    <Button type="submit" className='actulizarboton' hidden={Enable}>Actualizar</Button>
                                                    <Button type="button" className='cancelarboton' hidden={Enable} onClick={() => SetEnable(true)}>Cancelar</Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second" id="changePassword">
                            <Card>
                                <Card.Header>
                                    <h6 className="card-title mb-0">Cambiar Contraseña</h6>
                                </Card.Header>
                                <Card.Body>
                                    <Form onSubmit={handlePasswordSubmit}>
                                        <Row className=" g-2 justify-content-lg-between align-items-center">
                                            <Col lg={4}>
                                                <div>
                                                    <Form.Label htmlFor="currentPassword" className="form-label">Contraseña Actual*</Form.Label>
                                                    <div className="position-relative">
                                                        <Form.Control
                                                            type={ShowPwdBTN1 ? "text" : "password"}
                                                            id="currentPassword"
                                                            placeholder="Ingresar la contraseña actual"
                                                            autoComplete='off'
                                                            value={passwordForm.currentPassword}
                                                            onChange={handlePasswordChange}
                                                            className="valid"
                                                        />
                                                        <Button variant='link' className="position-absolute top-0 end-0 text-decoration-none text-muted password-addon" id="passwordAddon" onClick={() => SetShowPwdBTN1(!ShowPwdBTN1)}>
                                                            <i className={ShowPwdBTN1 ? "ri-eye-off-fill align-middle" : "ri-eye-fill align-middle"}></i>
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <div className="auth-pass-inputgroup">
                                                    <Form.Label htmlFor="newPassword" className="form-label">Nueva Contraseña*</Form.Label>
                                                    <div className="position-relative">
                                                        <Form.Control
                                                            type={ShowPwdBTN2 ? "text" : "password"}
                                                            className="password-input"
                                                            id="newPassword"
                                                            onPaste={(e) => e.preventDefault()}
                                                            placeholder="Ingresar nueva contraseña"
                                                            aria-describedby="passwordInput"
                                                            required
                                                            autoComplete='off'
                                                            value={passwordForm.newPassword}
                                                            onChange={handlePasswordChange}
                                                        />
                                                        <Button variant='link' className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" id="password-addon" onClick={() => SetShowPwdBTN2(!ShowPwdBTN2)}>
                                                            <i className={ShowPwdBTN2 ? "ri-eye-off-fill align-middle" : "ri-eye-fill align-middle"}></i>
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <div className="auth-pass-inputgroup">
                                                    <Form.Label htmlFor="confirmNewPassword">Confirmar Contraseña*</Form.Label>
                                                    <div className="position-relative">
                                                        <Form.Control
                                                            type={ShowPwdBTN3 ? "text" : "password"}
                                                            className="password-input"
                                                            onPaste={(e) => e.preventDefault()}
                                                            id="confirmNewPassword"
                                                            placeholder="Confirmar nueva contraseña"
                                                            required
                                                            autoComplete='off'
                                                            value={passwordForm.confirmNewPassword}
                                                            onChange={handlePasswordChange}
                                                        />
                                                        <Button variant='link' className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" id="confirm-password-input" onClick={() => SetShowPwdBTN3(!ShowPwdBTN3)}>
                                                            <i className={ShowPwdBTN3 ? "ri-eye-off-fill align-middle" : "ri-eye-fill align-middle"}></i>
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Col>

                                            <div className="d-flex align-items-center justify-content-between">
                                                <Link to="#" className="link-primary text-decoration-underline">¿Olvidaste la contraseña?</Link>
                                                <div className="">
                                                    <Button type="submit" className="botoncontra">Cambiar contraseña</Button>
                                                </div>
                                            </div>
                                            <Col lg={12}>
                                                {passwordErrors.length > 0 && (
                                                    <Form.Control.Feedback type='invalid' className='d-block'>
                                                        {passwordErrors.map((error, index) => (
                                                            <div key={index}>{error}</div>
                                                        ))}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12}>
                                                <div className="table-responsive">
                                                    <Table className="table table-borderless align-middle mb-0">
                                                        <thead className="table-light">
                                                            <tr>
                                                                <th scope="col">Dispositivo</th>
                                                                <th scope="col">Dirección IP</th>
                                                                <th scope="col">Fecha</th>
                                                                <th scope="col">Dirección</th>
                                                                <th scope="col"><i className="ri-logout-box-r-line"></i></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><i className="bi bi-phone align-baseline me-1"></i> iPhone 12 Pro</td>
                                                                <td>192.44.234.160</td>
                                                                <td>18 Dec, 2023</td>
                                                                <td>Los Angeles, United States</td>
                                                                <td><Link to="#" className="icon-link icon-link-hover">Cerrar sesion <i className="bi bi-box-arrow-right"></i></Link></td>

                                                            </tr>

                                                            <tr>
                                                                <td><i className="bi bi-tablet align-baseline me-1"></i> Apple iPad Pro</td>
                                                                <td>192.44.234.162</td>
                                                                <td>03 Jan, 2023</td>
                                                                <td>Phoenix, United States</td>
                                                                <td><Link to="#" className="icon-link icon-link-hover">Cerrar sesion <i className="bi bi-box-arrow-right"></i></Link></td>
                                                            </tr>

                                                            <tr>
                                                                <td><i className="bi bi-phone align-baseline me-1"></i> Galaxy S21 Ultra 5G</td>
                                                                <td>192.45.234.54</td>
                                                                <td>25 Feb, 2023</td>
                                                                <td>Washington, United States</td>
                                                                <td><Link to="#" className="icon-link icon-link-hover">Cerrar sesion <i className="bi bi-box-arrow-right"></i></Link></td>
                                                            </tr>

                                                            <tr>
                                                                <td><i className="bi bi-laptop align-baseline me-1"></i> Dell Inspiron 14</td>
                                                                <td>192.40.234.32</td>
                                                                <td>16 Oct, 2022</td>
                                                                <td>Phoenix, United States</td>
                                                                <td><Link to="#" className="icon-link icon-link-hover">Cerrar sesion <i className="bi bi-box-arrow-right"></i></Link></td>
                                                            </tr>

                                                            <tr>
                                                                <td><i className="bi bi-phone align-baseline me-1"></i> iPhone 12 Pro</td>
                                                                <td>192.44.326.42</td>
                                                                <td>22 May, 2022</td>
                                                                <td>Conneticut, United States</td>
                                                                <td><Link to="#" className="icon-link icon-link-hover">Cerrar sesion <i className="bi bi-box-arrow-right"></i></Link></td>

                                                            </tr>

                                                            <tr>
                                                                <td><i className="bi bi-tablet align-baseline me-1"></i> Apple iPad Pro</td>
                                                                <td>190.44.182.33</td>
                                                                <td>19 Nov, 2023</td>
                                                                <td>Los Angeles, United States</td>
                                                                <td><Link to="#" className="icon-link icon-link-hover">Cerrar sesion <i className="bi bi-box-arrow-right"></i></Link></td>

                                                            </tr>

                                                            <tr>
                                                                <td><i className="bi bi-phone align-baseline me-1"></i> Galaxy S21 Ultra 5G</td>
                                                                <td>194.44.235.87</td>
                                                                <td>30 Aug, 2022</td>
                                                                <td>Conneticut, United States</td>
                                                                <td><Link to="#" className="icon-link icon-link-hover">Cerrar sesion <i className="bi bi-box-arrow-right"></i></Link></td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Tab.Pane>
                    </Tab.Content>
                    <Tab.Content>
                        <Tab.Pane eventKey="third" id="education">
                            <Card>
                                <Card.Header>
                                    <h6 className="card-title mb-0">Educación</h6>
                                </Card.Header>
                                <Card.Body>
                                    <Form onSubmit={handleEducationalInfo}>
                                        <div id="newlink">
                                            <div id="1">
                                                <Row>
                                                    <Col lg={6}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label htmlFor="Recintos">Sede</Form.Label>
                                                                <Col lg={7}>
                                                                    <Form.Select
                                                                        id="Recintos"
                                                                        value={formData.Recintos}
                                                                        onChange={handleChange}
                                                                        disabled={Enable}
                                                                    >
                                                                        <option value="">Sede</option>
                                                                        {data['Recintos']?.map(campus => (
                                                                            <option key={campus.id} value={campus.id}>{campus.name}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </Col>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Form.Label htmlFor="degreeName">Carrera</Form.Label>
                                                            <div className="mb-3">
                                                                <Col lg={7}>
                                                                    <Form.Select
                                                                        data-choices
                                                                        name="Carreras"
                                                                        id="Carreras"
                                                                        value={formData.Carreras}
                                                                        onChange={handleChange}
                                                                        disabled={Enable}
                                                                    >
                                                                        <option disabled value="">Carrera</option>
                                                                        {data['Carreras']?.map(degree => (
                                                                            <option key={degree.id} value={degree.id}>{degree.name}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </Col>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Form.Label htmlFor="Períodos">Período Académico</Form.Label>
                                                            <div className="mb-3">
                                                                <Col lg={7}>
                                                                    <Form.Select
                                                                        data-choices
                                                                        name="Períodos"
                                                                        id="Períodos"
                                                                        value={formData.Períodos}
                                                                        onChange={handleChange}
                                                                        disabled={Enable}
                                                                    >
                                                                        <option disabled value="">Periodos</option>
                                                                        {data['Períodos']?.map(period => (
                                                                            <option key={period.id} value={period.id}>{period.name}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </Col>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Form.Label htmlFor="enrollmentInput">Matrícula</Form.Label>
                                                            <Col lg={7}>
                                                                <Form.Control
                                                                    type="text"
                                                                    id="enrollmentInput"
                                                                    placeholder="Matrícula"
                                                                    value={participant?.enrollmentNumber || ''}
                                                                    onChange={handleChange}
                                                                    disabled={Enable}
                                                                />
                                                            </Col>
                                                        </div>
                                                    </Col>
                                                    <div className="hstack gap-2 justify-content-end">
                                                    </div>
                                                </Row>
                                            </div>
                                        </div>
                                        <div id="newForm"></div>
                                        <Col lg={12}>
                                            <div className="hstack gap-2">
                                                <Button type="submit" className="actulizarboton" hidden={Enable}>Actualizar</Button>
                                            </div>
                                        </Col>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </Col>
        </React.Fragment>
    );
};

export default Personal;