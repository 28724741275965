import BreadCrumb from 'Common/BreadCrumb';
import React from 'react'
import { Container, Image, Row } from 'react-bootstrap';
import construction from "assets/images/auth/construction.svg"

const IntershipConfig = () => {

    document.title = "Configuración - Pasantia"

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Configuración" pageTitle="Pasantia" />
                    <Row>
                        <Image src={construction} alt="En construcción" className='w-50 mx-auto' />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default IntershipConfig;