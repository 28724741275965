import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "assets/scss/pages/MyRequestStatus.scss";

export default function ModalDocumentations({ show, onHide, action, onConfirm }: { show: boolean, onHide: () => void, action: 'deshacer' | 'enviar', onConfirm: () => void }) {

    const getTitle = () => {
        return action === 'deshacer' ? 'Deshacer cambios' : 'Confirmar envío';
    };

    const getBody = () => {
        return action === 'deshacer' ? '¿Estás seguro de deshacer los cambios?' : '¿Estás seguro de que deseas enviar la información?';
    };

    const handleConfirm = () => {
        if (action === 'deshacer') {
            onConfirm();
        } else if (action === 'enviar') {
            onConfirm();
        }
        onHide();
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{getTitle()}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{getBody()}</Modal.Body>
            <Modal.Footer>
                <Button className='botonrechazar w-25' onClick={onHide}>
                    Cancelar
                </Button>
                <Button className='botonenviar w-25' onClick={handleConfirm}>
                    Confirmar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}