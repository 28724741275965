import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import avatar1 from "assets/images/users/32/icono.png";
import { useProfile } from './Hooks/UserHooks';
import { Link, useNavigate } from 'react-router-dom';
import "assets/scss/components/_generalconfig.scss"

const ProfileDropdown = () => {

    const { userProfile, roleName } = useProfile();
    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [enrollmentNumber, setEnrollmentNumber] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (userProfile) {
            setName(userProfile.data.name || '');
            setLastname(userProfile.data.lastname || '');
            setEnrollmentNumber(userProfile.data.enrollmentNumber || '');
        } else {
            console.error('No se recibieron datos del perfil.');
        }
    }, [userProfile]);

    const handleLogout = () => {
        localStorage.removeItem('accessToken');
        navigate('/login');
    }
    const [isOpen, setIsOpen] = useState(false); //Estado del dropdown

    const handleToggle = (isOpen: boolean) => { //Hace toggle entre los estados del dropdown
        setIsOpen(isOpen);
    };

    const handleSelect = () => {
        setIsOpen(false); // Cierra el dropdown al seleccionar un elemento
    };
    return (
        <React.Fragment>
            <Dropdown className="ms-sm-3 header-item topbar-user" show={isOpen} onToggle={handleToggle}>
                <Dropdown.Toggle type="button" className="btn bg-transparent border-0 arrow-none" id="page-header-user-dropdown">
                    <span className="d-flex align-items-center">
                        <img className="rounded-circle header-profile-user" src={avatar1} alt="Header Avatar" />
                        <span className="text-start ms-xl-2">
                            <span className="d-none d-xl-block ms-1 fs-sm user-name-sub-text profile-element">{name} {lastname}</span>
                            <span className="d-none d-xl-block ms-1 fs-sm text-warning user-name-sub-text">{roleName === 'Estudiante' && <span>{enrollmentNumber}-</span>}<span>{roleName}</span></span>
                        </span>
                    </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-end">
                    <h6 className="dropdown-header">Bienvenido {name}!</h6>
                    {roleName !== 'Estudiante' && (
                        <Link to={"/user-profile"} className="text-muted link-underline-opacity-0 ms-3" onClick={handleSelect}>
                            <i className="mdi mdi-account-circle text-muted fs-lg align-middle me-1"></i>
                            <span className="align-middle">Mi perfil</span>
                        </Link>
                    )}
                    <div className="dropdown-divider"></div>
                    {(roleName === 'Estudiante' || roleName === 'Administrador') && (
                        <Link to={"/user-profile-settings"} className="text-muted link-underline-opacity-0 ms-3" onClick={handleSelect}>
                            <i className="mdi mdi-cog-outline text-muted fs-lg align-middle me-1"></i>
                            <span className="align-middle">Configuración</span>
                        </Link>
                    )}
                    <Dropdown.Item onClick={handleLogout}>
                        <i className="mdi mdi-logout text-muted fs-lg align-middle me-1"></i>
                        <span className="align-middle" data-key="t-logout">Cerrar Sesión</span>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </React.Fragment>
    );
}

export default ProfileDropdown;