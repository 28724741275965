import documentacion from "assets/images/uapa-cards/documentacion.svg";
import practicaprofesional from "assets/images/uapa-cards/practicaprofesional.svg";
import servicio from "assets/images/uapa-cards/servicio.svg";
import reglamentossu from "assets/images/uapa-cards/reglamentossu.svg";
import reglamento from "assets/images/uapa-cards/reglamento.svg";

export const homeData = [
  {
    image: servicio,
    title: "Servicio Social Universitario",
    subtitle: "Enero-Marzo, 2024",
    link: "/create-social-service-request",
    roles: ["Administrador", "Servicio Social", "Estudiante"],
  },
  {
    image: practicaprofesional,
    title: "Práctica Profesional (Pasantía)",
    subtitle: "Enero-Marzo, 2024",
    link: "/create-intership-request",
    roles: ["Administrador", "Pasantía", "Estudiante"],
  },
  {
    image: documentacion,
    title: "Documentación y Evidencia",
    subtitle: "Gestión de Archivos",
    link: "/documentation-request",
    roles: ["Administrador", "Estudiante"],
  },
  {
    image: reglamento,
    title: "Reglamento Institucional de Pasantía",
    subtitle: "Bases del proceso",
    link: "/intern-regulation",
    roles: ["Administrador", "Pasantía", "Estudiante"],
  },
  {
    image: reglamentossu,
    title: "Reglamento Institucional del SSU",
    subtitle: "Bases del proceso",
    link: "/ssu-regulation",
    roles: ["Administrador", "Servicio Social", "Estudiante"],
  },
];
