export const signupinfo = [
  {
    label: "Nombre",
    placeholder: "Ingrese su nombre",
    id: "nameInput",
    type: "text",
    name: "name",
    controlId: "formName",
  },
  {
    label: "Apellido",
    placeholder: "Ingrese su apellido",
    id: "lastNameInput",
    type: "text",
    name: "lastname",
    controlId: "formLastName",
  },
  {
    label: "Matricula",
    placeholder: "Ingrese su matricula 100035191 o 202002153",
    id: "idInput",
    type: "number",
    name: "enrollmentNumber",
    controlId: "formId",
  },
  {
    label: "Correo institucional",
    placeholder: "Ingrese su correo institucional",
    id: "email",
    type: "email",
    name: "email",
    controlId: "formInstitutionalEmail",
  },
  {
    label: "Contraseña",
    placeholder: "Ingrese su contraseña",
    id: "passwordInput",
    type: "password",
    name: "password",
    controlId: "formPassword",
    icon: "fa fa-eye",
  },
  {
    label: "Confirmar contraseña",
    placeholder: "Confirme su contraseña",
    id: "confirmPasswordInput",
    type: "password",
    name: "confirmPassword",
    controlId: "formConfirmPassword",
    icon: "fa fa-eye",
  },
];
