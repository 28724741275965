import React, { useState } from 'react';
import BreadCrumb from 'Common/BreadCrumb';
import usePagination from "Common/Hooks/UsePagination";
import { Button, Card, Container, Table } from 'react-bootstrap';
import { columnNamesStudents } from "Common/data/columnNames";
import ModalComponent from "Components/Modal";
import { useLocation } from 'react-router-dom';
import LordIcon from "Components/LordIcon";
import "assets/scss/pages/MyRequestStatus.scss";
import useRequestsData from 'Common/Hooks/useRequestsData';
import { Request } from 'Common/data/requests';

const MyRequestStatus = () => {
    document.title = "Estado de solicitud";
    const icon = require('../../../assets/animations/nodata.json');
    const { participant } = useRequestsData();
    const itemsPerPage = 5;
    const { itemsToShow } = usePagination(participant?.requests || [], itemsPerPage);
    const [showModal, setShowModal] = useState(false);
    const [selectedRequest, setSelectedRequest] = useState<Request['requests'][0] | null>(null);
    const location = useLocation();
    const currentView = location.pathname;

    const openModal = (request: Request['requests'][0]) => {
        setSelectedRequest(request);
        setShowModal(true);
    };

    const renderCellContent = (value: any, key: string) => {
        if (typeof value === 'object' && value !== null) {
            return value.name;
        }
        return value;
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Mis solicitudes" pageTitle="Solicitudes" />
                </Container>
                <Container fluid className="table-responsive">
                    <Card>
                        <Card.Body>
                            <Table hover className="table align-middle mb-0" width={250}>
                                <thead className='table-light'>
                                    <tr className='text-truncate'>
                                        {Object.values(columnNamesStudents).map((columnName, index) => (
                                            <th key={index}>{columnName}</th>
                                        ))}
                                        <th>Acción</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {itemsToShow.length > 0 ? (
                                        itemsToShow.map((request) => (
                                            <tr key={request.id}>
                                                {Object.keys(columnNamesStudents).map((key) => (
                                                    <td key={key} data-column={`${columnNamesStudents[key]}:`}>
                                                        {renderCellContent((request as any)[key.split('.')[0]], key)}
                                                    </td>
                                                ))}
                                                <td>
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <Button type="button" className="btn-personalizate" onClick={() => openModal(request)}>
                                                            <i className="ri ri-eye-line"></i>
                                                        </Button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={Object.keys(columnNamesStudents).length + 1}>
                                                <div className="d-flex justify-content-center align-content-center">
                                                    <LordIcon icon={icon} />
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>

                    <ModalComponent
                        show={showModal}
                        onHide={() => setShowModal(false)}
                        view={currentView}
                        request={selectedRequest}
                        participant={participant}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
}

export default MyRequestStatus;
