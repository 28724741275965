import React from 'react'
import { Container, Row } from 'react-bootstrap'
import BreadCrumb from 'Common/BreadCrumb'
import Personal from './personal'

const UserProfileSettings = () => {

  document.title = "Configuración del perfil"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title='Configuración del perfil ' pageTitle='Perfil' />
          <Row>
            <Personal />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UserProfileSettings