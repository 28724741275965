import React from "react";
import { downloadExcel } from "react-export-table-to-excel";
import { columnNamesRequest } from "Common/data/columnNames";
import { Request } from 'Common/data/requests';

const BotonExplorarMetricas: React.FC<{ requests: Request['requests'][] }> = ({ requests }) => {
  
  const handleDownloadExcel = () => {
    const body = requests.map((req) => {
      return Object.values(req).map(value => typeof value === 'object' ? JSON.stringify(value) : value);
    });

    downloadExcel({
      fileName: "Solicitudes",
      sheet: "Solicitudes",
      tablePayload: {
        header: Object.values(columnNamesRequest),
        body,
      },
    });
  };

  return (
    <button onClick={handleDownloadExcel} className="btn btn-orange-uapa">
      Exportar
    </button>
  );
};

export default BotonExplorarMetricas;