import React from 'react'
import { Container, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import "assets/scss/pages/_404.scss"
import unauthorized from "assets/images/auth/unauthorized.svg";

const PageNotAuthorized = () => {

    document.title = "No Autorizado - SAGESPRA";

    return (
        <React.Fragment>
            <div className="custom-page-content home">
                <Container fluid>
                    <div id="container">
                        <div className="content">
                            <Container>
                                <Image src={unauthorized} alt="No autorizado" className="w-75 mb-0" />
                            </Container>

                            <h3 className='text-white lg fw-bold '>Error 401</h3>
                            <p className='mt-0'>Al parecer, no estás autorizado para ver esta página. Pero no te preocupes, puedes iniciar sesión de nuevo.</p>
                            <Link to="/login">Regresar al inicio de sesión</Link>
                        </div>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )

}

export default PageNotAuthorized;