import React from 'react';
import BreadCrumb from 'Common/BreadCrumb';
import { Row, Col, Card, Container, Button } from 'react-bootstrap';
import "assets/scss/pages/_InternshipRegulation.scss"

const SsuRegulation = () => {
    document.title = "Reglamento Institucional SSU | Institutional Regulation for Social Service";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Reglamento Institucional SSU" pageTitle="Panel" />
                    <Row className=" justify-content-center">
                        <Col xxl={12}>
                            <Card className="overflow-hidden">
                                <div className="bg-primary pb-0">
                                    <Card.Body className="text-center py-4">
                                        <h4 className="fw-medium text-white mb-3">UNIVERSIDAD ABIERTA PARA ADULTOS</h4>
                                        <p className="text-white text-opacity-75 mb-0">REGLAMENTO DE SERVICIO SOCIAL UNIVERSITARIO</p>
                                    </Card.Body>
                                    <Card.Body className="py-2 bg-white bg-opacity-10">
                                        <ul className="list-unstyled mb-0 text-white-75 hstack gap-2 justify-content-between">
                                            <li>
                                                Modificado y aprobado por el Consejo Académico mediante resolución no. 45-2023
                                            </li>
                                            <li>
                                                14 de noviembre del 2023.
                                            </li>
                                        </ul>
                                    </Card.Body>
                                </div>
                                <Card.Body>
                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i> Considerando</h6>
                                    <p className="text-muted mb-2">Que el Reglamento Académico en su artículo 29, establece que en
                                        la estructura curricular de las carreras de pregrado y grado de la universidad, se
                                        contemplan la Práctica Profesional (pasantía), el Servicio Social y el Curso Final de
                                        Grado, como unidades curriculares que ponen al participante en contacto con los
                                        problemas del campo social y ocupacional, a través de la integración de conocimientos,
                                        habilidades, destrezas y valores desarrollados a lo largo de la carrera, con el propósito
                                        de demostrar las competencias de egreso adquiridas, en ámbitos laborales o
                                        comunitarios relacionados con su área de formación.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i> Considerando</h6>
                                    <p className="text-muted mb-2">Que el Reglamento Académico de pregrado y grado de la
                                        universidad en su artículo 31, establece que el Servicio Social Universitario es una
                                        actividad de responsabilidad social que deben realizar los participantes en beneficio de
                                        la sociedad, con el propósito de consolidar su formación integral. El mismo contribuye al
                                        desarrollo de una conciencia de solidaridad y compromiso con las comunidades.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i> Considerando</h6>
                                    <p className="text-muted mb-2">Que los planes de estudios de las carreras de pregrado y grado
                                        vigentes tienen el Servicio Social Universitario como una actividad obligatoria que debe
                                        ser realizada en una institución sin fines de lucros o en proyectos coordinados por la
                                        UAPA.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i> Considerando</h6>
                                    <p className="text-muted mb-2">Que una de las finalidades de la UAPA (acápite j del art. 5
                                        de los Estatutos), es fomentar la responsabilidad social a través de
                                        programas que contribuyan a solucionar problemas básicos de la sociedad.</p>


                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i> Considerando</h6>
                                    <p className="text-muted mb-2">Que el Modelo Educativo por Competencias Centrado en el
                                        Aprendizaje (MECCA) de la UAPA establece en el numeral 3.3.4.2, que las competencias
                                        profesionales en cada carrera deben estar asociadas al saber hacer, referidas a las
                                        habilidades, destrezas y conocimientos prácticos propios de la profesión, que deben ser
                                        desarrolladas durante el periodo de formación de los participantes en la Universidad.</p>

                                    <p className="text-muted mb-2">VISTAS:</p>
                                    <ul className="text-muted vstack gap-2">
                                        <li>
                                            La Ley 139-01 de Educación Superior, Ciencia y Tecnología.
                                        </li>
                                        <li>
                                            El Reglamento de Instituciones y Programas de Educación Superior a Distancia del
                                            MESCYT.
                                        </li>
                                        <li>
                                            Los Estatutos y el Reglamento Académico de Pregrado y Grado de la UAPA.
                                        </li>
                                        <li>
                                            El Modelo Educativo por Competencias Centrado en el Aprendizaje de la UAPA
                                            (MECCA,2009).
                                        </li>
                                    </ul>
                                    <p className="text-muted mb-2">El Consejo Académico, en uso de las atribuciones que le confieren los Estatutos y el
                                        Reglamento Académico de Pregrado y Grado, RESUELVE: aprobar la modificación al
                                        Reglamento de Servicio Social Universitario.</p>

                                    <h6 className="card-title mb-3"> CAPÍTULO I</h6>
                                    <h6 className="card-title mb-3"> DE LA DEFINICIÓN, PROPÓSITOS, ÁMBITOS DE APLICACIÓN Y LOS
                                        PROYECTOS</h6>
                                    <h6 className="card-title mb-3">De la definición</h6>


                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 1.</h6>
                                    <p className="text-muted mb-2">El presente Reglamento tiene por objeto establecer los lineamientos
                                        generales que se aplicarán para la prestación del Servicio Social Universitario de los
                                        participantes que cursen estudios de técnico superior, licenciatura e ingeniería en la
                                        Universidad Abierta para Adultos, así como asignar las funciones de las dependencias y
                                        de las personas que intervienen en su organización y ejecución.</p>


                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 2.</h6>
                                    <p className="text-muted mb-2">El Servicio Social Universitario, es una actividad realizada por los
                                        participantes de la UAPA, orientada a desarrollar el sentido de servicio, ayuda y
                                        solidaridad con los demás, situándolos como ente de solución a problemáticas
                                        comunitarias, medioambientales, de manera natural, altruista y voluntaria, en beneficio
                                        de los más necesitados.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 3.</h6>
                                    <p className="text-muted mb-2">El servicio social Universitario es obligatorio para adquirir la titulación de
                                        pregrado y grado (técnico superior, licenciatura e ingeniería), ya que forma parte integral
                                        del currículo de cada una de las carreras, que se imparten en la Universidad.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 4.</h6>
                                    <p className="text-muted mb-2">Para los efectos del presente reglamento, se entiende por:</p>

                                    <ul className="text-muted vstack gap-2">
                                        <li>
                                            Asignación: la acción de adscribir a un proyecto al participante que lo solicite,
                                            para cumplir con el Servicio Social Universitario.
                                        </li>
                                        <li>
                                            Participante: el participante de una carrera técnica, licenciatura o ingeniería, que
                                            se encuentra realizando su Servicio Social Universitario.
                                        </li>
                                        <li>
                                            Unidad Receptora: dependencia, institución u organismo en el cual se puede
                                            realizar el Servicio Social Universitario.
                                        </li>

                                    </ul>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 5.</h6>
                                    <p className="text-muted mb-2">El Servicio Social Universitario, es una actividad temporal en beneficio de la
                                        sociedad y, en consecuencia, su prestación será gratuita y en ningún caso creará
                                        derechos u obligaciones de tipo laboral.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 6.</h6>
                                    <p className="text-muted mb-2">Para la prestación del Servicio Social Universitario, los participantes se
                                        pueden acoger a los programas o proyectos que contempla la universidad o proponer el servicio social a partir de una situación detectada en su entorno, comunidad o una
                                        institución sin fines de lucro que sea previamente aprobada por la Universidad.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 7.</h6>
                                    <p className="text-muted mb-2">El Servicio Social Universitario debe realizarse en coordinación son las
                                        siguientes unidades:</p>

                                    <ul className="text-muted vstack gap-2">
                                        <li>
                                            Unidades Receptoras Internas: dependencias de servicios o unidades académicas de
                                            la Universidad, tales como el Unidad de Extensión y Voluntariado, INCAPRE,
                                            Departamento de Investigación y Divulgación Científica, Admisiones o en cualquier otra
                                            instancia de la universidad que sea autorizada para esos fines.
                                        </li>
                                        <li>
                                            Unidades Receptoras Externas: Instituciones Educativas, organismos públicos de
                                            interés social, fundaciones y asociaciones privadas que trabajen con sectores
                                            marginados, asociaciones campesinas, juntas de vecinos, o cualquier otra organización
                                            de carácter social.
                                        </li>
                                        <li>
                                            Unidad Receptora: dependencia, institución u organismo en el cual se puede
                                            realizar el Servicio Social Universitario.
                                        </li>

                                    </ul>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 8.</h6>
                                    <p className="text-muted mb-2">La UAPA establecerá acuerdos o convenios, bajo el principio de
                                        corresponsabilidad, con las unidades receptoras externas que se beneficiarán del
                                        Servicio Social Universitario prestado por los participantes.</p>

                                    <h6 className="card-title mb-3">De los propósitos del Servicio Social Universitario</h6>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 9.</h6>
                                    <p className="text-muted mb-2">El Servicio Social Universitario tiene como propósitos, los siguientes:</p>
                                    <p className="text-muted mb-2">a. Consolidar la formación integral de los participantes.</p>
                                    <p className="text-muted mb-2">b. Contribuir al desarrollo en los participantes de una conciencia de solidaridad y
                                        compromiso con las comunidades a las que pertenecen y/o en estado de
                                        vulnerabilidad.</p>
                                    <p className="text-muted mb-2">c. Vincular a la Universidad con los diferentes sectores de la sociedad,
                                        incrementando su valoración social.</p>
                                    <p className="text-muted mb-2">d. Desarrollar modelos de trabajo unidisciplinarios, interdisciplinarios y
                                        multidisciplinarios que permitan a los participantes enfrentarse a situaciones
                                        reales en las áreas sociales y profesionales.</p>
                                    <p className="text-muted mb-2">e. Contribuir al desarrollo social, económico y cultural de los sectores marginados y
                                        excluidos, mediante la extensión de los beneficios de la ciencia y la tecnología.</p>
                                    <p className="text-muted mb-2">f. Fortalecer la misión, visión, y valores de la Universidad.</p>
                                    <p className="text-muted mb-2">g. Contribuir con la aplicación eficaz del Modelo Educativo por Competencias
                                        Centrado en el Aprendizaje, MECCA, que se desarrolla en la Universidad.</p>
                                    <p className="text-muted mb-2">h. Contribuir al desarrollo de los proyectos extensionistas orientado a resolver
                                        los problemas emergentes en la sociedad.</p>
                                    <p className="text-muted mb-2">i. Dar respuesta a través del servicio social con proyectos alineados a los objetivos
                                        de Desarrollo Sostenible, donde se involucren diferentes sectores creando un
                                        proceso continuo y participativo.</p>


                                    <h6 className="card-title mb-3">Ámbito de aplicación</h6>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 10.</h6>
                                    <p className="text-muted mb-2">El Servicio Social Universitario, de acuerdo al lugar donde se desarrolle, se
                                        puede presentar en dos contextos de aplicación:</p>


                                    <p className="text-muted mb-2">a. Servicio Social Universitario Nacional: Cuando el Servicio Social Universitario se
                                        desarrolla en una institución, comunidad o familia ubicada en el territorio de la República
                                        Dominicana.</p>
                                    <p className="text-muted mb-2">b. Servicio Social Universitario Internacional: Cuando el Servicio Social Universitario se
                                        desarrolla en una institución, comunidad o familia ubicada fuera de la República
                                        Dominicana.</p>



                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 11.</h6>
                                    <p className="text-muted mb-2">El Departamento de Práctica Profesional y Servicio Social desarrollará
                                        proyectos de impacto social con el propósito de abordar las necesidades identificadas en
                                        familias, comunidades, organizaciones no gubernamentales y en línea con los objetivos
                                        de desarrollo sostenible.</p>


                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Párrafo:</h6>
                                    <p className="text-muted mb-2">El propósito fundamental de los proyectos o programas, es reforzar los valores
                                        institucionales de Solidaridad, Equidad, Responsabilidad, Sensibilidad Medioambiental y
                                        Cooperación, con la meta de inspirar un impacto positivo en todos los involucrados.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 12.</h6>
                                    <p className="text-muted mb-2">Si un participante desea llevar a cabo el servicio social universitario en la
                                        modalidad individual, debe presentar su propuesta al Departamento de Práctica
                                        Profesional y Servicio Social para su evaluación.</p>

                                    <h6 className="card-title mb-3"> CAPÍTULO II</h6>
                                    <h6 className="card-title mb-3"> DE LAS MODALIDADES DEL SERVICIO SOCIAL UNIVERSITARIO </h6>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 13.</h6>
                                    <p className="text-muted mb-2">De acuerdo a la formación académica de los participantes, el Servicio Social
                                        Universitario se efectuará a través de las modalidades siguientes:</p>

                                    <p className="text-muted mb-2">a. Unidisciplinaria: que consiste en la realización del Servicio Social Universitario en
                                        proyectos o programas en el que intervienen participantes de una misma carrera.</p>

                                    <p className="text-muted mb-2">b. Interdisciplinaria: que consiste en proyectos o programas de Servicio Social
                                        Universitario ejecutados por participantes de carreras afines pertenecientes a la misma
                                        escuela.</p>

                                    <p className="text-muted mb-2">c. Multidisciplinaria: que consiste en proyectos o programas de Servicio Social
                                        Universitario ejecutados por participantes de distintas carreras y escuelas.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 14.</h6>
                                    <p className="text-muted mb-2">De acuerdo a la cantidad de participantes el Servicio Social Universitario
                                        será: Individual o Colectivo:</p>
                                    <p className="text-muted mb-2">a. El servicio individual, es cuando el proyecto o programa de Servicio Social
                                        Universitario lo realiza un solo participante.</p>

                                    <p className="text-muted mb-2">b. El servicio colectivo, es cuando el proyecto o programa es realizado por un
                                        grupo de participantes, que de manera mancomunada operan un programa o
                                        proyecto tendiente a mejorar el nivel de vida de un grupo social marginado.</p>

                                    <h6 className="card-title mb-3">CAPÍTULO III</h6>
                                    <h6 className="card-title mb-3"> SOBRE LA DURACIÓN Y CARACTERÍSTICAS DEL SERVICIO SOCIAL UNIVERSITARIO</h6>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 15.</h6>
                                    <p className="text-muted mb-2">Para realizar el Servicio Social Universitario se requiere haber aprobado
                                        todas las asignaturas hasta el periodo académico donde está programado el Servicio
                                        Social Universitario del pensum de su carrera.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 16.</h6>
                                    <p className="text-muted mb-2">El Servicio Social Universitario tendrá una duración de 15 días hábiles como
                                        mínimo y seis (6) meses como máximo y el número de horas requeridas dependerá de
                                        las características del programa o proyecto a que se encuentre asignado el participante,
                                        pero nunca podrá ser menos de 60 horas.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 17.</h6>
                                    <p className="text-muted mb-2">El participante al concluir con el Servicio Social Universitario debe entregar
                                        los documentos originales en un plazo de 15 días hábiles pasada la fecha de término.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 18.</h6>
                                    <p className="text-muted mb-2">El servicio social universitario no culminado en el tiempo máximo
                                        establecido, ni entregada la documentación no será reconocido ni validado por el
                                        Departamento de Práctica Profesional y Servicio Social.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 19.</h6>
                                    <p className="text-muted mb-2">En caso de discontinuidad en los términos del artículo anterior, en el Servicio
                                        Social, el prestador participante deberá iniciar de nuevo, sin tomar en cuenta las
                                        actividades realizadas, quedando anuladas las horas acumuladas y el participante
                                        deberá reiniciar el Servicio Social Universitario.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 20.</h6>
                                    <p className="text-muted mb-2">Los participantes del servicio social universitario no pueden iniciar las
                                        actividades en la institución propuesta sin la previa autorización de la Coordinación del
                                        Servicio Social correspondiente.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 21.</h6>
                                    <p className="text-muted mb-2">Las horas de Servicio Social Universitario se reconocerán a partir del primer
                                        día de asistencia a la actividad asignada.</p>


                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 22.</h6>
                                    <p className="text-muted mb-2">Se reconocerán las horas del Servicio Social Universitario realizado por un
                                        participante, aún cuando este cambie de carrera o interrumpa sus estudios y haga su
                                        reingreso.</p>


                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 23.</h6>
                                    <p className="text-muted mb-2">Los participantes que se inscriban en un segundo plan de estudios, después
                                        de ser egresados de la Universidad, deberán prestar el Servicio Social Universitario que
                                        corresponde a su nueva carrera.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 24.</h6>
                                    <p className="text-muted mb-2">El Servicio Social Universitario podrá realizarse en cualquier área
                                        profesional, cuando el programa o proyecto a ejecutarse sea de carácter unidisciplinario,
                                        interdisciplinario o multidisciplinario.</p>


                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 25.</h6>
                                    <p className="text-muted mb-2">En caso de que se suspenda o elimine un programa o proyecto de Servicio
                                        Social Universitario, por causas no imputables a los participantes, es obligación de la
                                        Universidad asignarlo a un nuevo proyecto o programa afín, reconociendo las horas
                                        acumuladas.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 26.</h6>
                                    <p className="text-muted mb-2">Los programas y proyectos de Servicio Social Universitario podrán ser de
                                        carácter interno en una dependencia de la Universidad o externo en el sector público,
                                        privado sin fines de lucro o social.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 27.</h6>
                                    <p className="text-muted mb-2">La asignación de los participantes al sector público, social o privado sin fines
                                        de lucro, se hará de acuerdo a las necesidades que las instituciones (unidades
                                        receptoras externas) propongan a la Universidad y en los que se establezcan la
                                        transcendencia de las actividades a realizar en el mismo.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 28.</h6>
                                    <p className="text-muted mb-2">El servicio social universitario no se considerará válido en los casos que los
                                        participantes reciban salario o remuneración económica.</p>

                                    <h6 className="card-title mb-3"> CAPÍTULO IV</h6>
                                    <h6 className="card-title mb-3"> DE LA ESTRUCTURA Y FUNCIONES DEL SERVICIO SOCIAL UNIVERSITARIO </h6>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 29.</h6>
                                    <p className="text-muted mb-2">La Unidad de Servicio Social, es una instancia que depende del
                                        Departamento de Práctica Profesional y Servicio Social, responsable de la organización,
                                        funcionamiento, supervisión y control del Servicio Social Universitario.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 30.</h6>
                                    <p className="text-muted mb-2">La gestión del Servicio Social Universitario, estará a cargo de un
                                        Coordinador en cada Recinto.</p>
                                    <p className="text-muted mb-2">Son funciones del Coordinador de la Unidad de Servicio Social las
                                        siguientes:</p>

                                    <p className="text-muted mb-2"> a) Elaborar y velar por el cumplimiento del Plan Operativo y el presupuesto anual
                                        de la Unidad de Servicio Social. </p>
                                    <p className="text-muted mb-2"> b) Velar por el cumplimiento de las Normativas Institucionales y disposiciones
                                        emanadas de las autoridades de la Universidad que se vinculen con el Servicio
                                        Social.</p>
                                    <p className="text-muted mb-2"> c) Proponer mejoras innovadoras a la Dirección de Práctica Profesional y Servicio
                                        Social, para el desarrollo continuo del Departamento. </p>
                                    <p className="text-muted mb-2"> d) Desarrollar en coordinación con Unidad de Extensión programas y
                                        proyectos de ámbito social alineados a los objetivos de desarrollo
                                        Sostenible, que permitan desarrollar intervenciones en los sectores más
                                        vulnerables a través de la vinculación social de los participantes y de la
                                        Institución en las comunidades.</p>
                                    <p className="text-muted mb-2"> e) Coordinar con la Unidad de Extensión y Voluntariado actividades que
                                        respondan a problemas emergentes de la sociedad.</p>
                                    <p className="text-muted mb-2"> f) Presentar las diferentes actividades de Servicio Social Universitario a los
                                        participantes que han concluido el periodo académico requerido para realizar
                                        dicho servicio.</p>
                                    <p className="text-muted mb-2"> g) Llevar el registro y control de los participantes que concluyeron el Servicio Social
                                        Universitario.</p>
                                    <p className="text-muted mb-2"> h) Promover a través de las escuelas la convocatoria de orientación para motivar a
                                        los participantes a solicitar el Servicio Social Universitario en el tiempo
                                        establecido.</p>
                                    <p className="text-muted mb-2"> i) Supervisar las jornadas coordinadas a través de los programas y proyectos de
                                        Servicio Social Universitario.</p>
                                    <p className="text-muted mb-2"> j) Organizar conjuntamente con los coordinadores de los Recintos, actividades de
                                        inducción y capacitación de los participantes para realizar el Servicio Social
                                        Universitario.</p>
                                    <p className="text-muted mb-2"> k) Autorizar la prestación del Servicio Social Universitario a los participantes que
                                        cumplen con el requisito.</p>
                                    <p className="text-muted mb-2"> l) Elaborar las estadísticas relativas al Servicio Social Universitario.</p>
                                    <p className="text-muted mb-2"> m) Sistematizar los resultados de impactos del Servicio Social Universitario que
                                        realiza la Universidad.</p>
                                    <p className="text-muted mb-2"> n) Promover el establecimiento de convenios con instituciones públicas y sociales
                                        para la prestación del Servicio Social Universitario.</p>
                                    <p className="text-muted mb-2"> o) Elaborar la memoria anual de la unidad y entregar en el tiempo establecido.</p>
                                    <p className="text-muted mb-2"> p) Representar a la institución ante organismos o en eventos relacionados con el
                                        Servicio Social Universitario, con la autorización de la autoridad competente.</p>
                                    <p className="text-muted mb-2"> q) Todas aquellas que se consideren necesarias para el mejor funcionamiento de la
                                        unidad.</p>
                                    <p className="text-muted mb-2"> r) Cumplir y hacer cumplir las disposiciones que establece este reglamento.</p>

                                    <h6 className="card-title mb-3"> CAPÍTULO VI</h6>
                                    <h6 className="card-title mb-3"> DE LOS REQUISITOS, DERECHOS Y DEBERES DE LOS PARTICIPANTES DEL SERVICIO SOCIAL UNIVERSITARIO. </h6>
                                    <h6 className="card-title mb-3"> De los requisitos. </h6>
                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 31.</h6>
                                    <p className="text-muted mb-2">El participante para realizar el Servicio Social Universitario, debe
                                        cumplir con los siguientes requisitos:</p>

                                    <p className="text-muted mb-2"> a) Tener aprobadas todas las asignaturas del pensum de la carrera que cursa, hasta el
                                        período académico donde está programado el servicio social universitario. </p>
                                    <p className="text-muted mb-2"> b) Hacer la solicitud en línea en la convocatoria realizada por el Departamento.</p>
                                    <p className="text-muted mb-2"> c) Presentar una propuesta de institución sin fines de lucro donde desee realizar el
                                        servicio social universitario, en la modalidad individual, la cual puede ser aprobada o
                                        rechazada, luego de ser evaluada o solicitar ser integrado en uno de los programas o proyectos colectivos coordinados por la unidad de servicio social o la Unidad de
                                        Extensión y Voluntariado. </p>

                                    <h6 className="card-title mb-3"> De los derechos. </h6>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 32.</h6>
                                    <p className="text-muted mb-2">El participante en su rol de prestador de servicio social universitario tiene
                                        los siguientes derechos:</p>
                                    <p className="text-muted mb-2"> a) Recibir información y asesoría previa en lo relativo al Servicio Social Universitario. </p>
                                    <p className="text-muted mb-2"> b) Recibir de la unidad receptora las instrucciones necesarias para asegurar la
                                        eficiencia del servicio, cuando este es en la modalidad individual.</p>
                                    <p className="text-muted mb-2"> c) PManifestar por escrito sus opiniones, recomendaciones, quejas e inconformidades
                                        en relación al Servicio Social Universitario.</p>
                                    <p className="text-muted mb-2"> d) Obtener de la institución receptora la carta y el control de horas que certifiquen la
                                        terminación del Servicio Social Universitario cuando sea un servicio individual.</p>
                                    <p className="text-muted mb-2"> e) Los demás que se establezcan en la normatividad universitaria aplicable.</p>

                                    <h6 className="card-title mb-3"> De los deberes.</h6>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 33.</h6>
                                    <p className="text-muted mb-2">Son deberes de los participantes del Servicio Social Universitario, las
                                        siguientes:</p>

                                    <p className="text-muted mb-2"> a) Participar en las actividades académicas de inducción y acciones que convoque
                                        el coordinador del Servicio Social Universitario la Unidad de Extensión y
                                        Voluntariado.</p>
                                    <p className="text-muted mb-2"> b) Respetar las políticas, normativas y manuales de procedimientos internos, y
                                        cualquier otra disposición, de la unidad receptora donde realice el Servicio Social
                                        Universitario.</p>
                                    <p className="text-muted mb-2"> c) Cumplir con dedicación y esmero las actividades asignadas.</p>
                                    <p className="text-muted mb-2"> d) Mantener una conducta apropiada y cuidar la imagen de la Universidad.</p>
                                    <p className="text-muted mb-2"> e) Hacer buen uso de los recursos materiales y de apoyo que se les confíen para
                                        realizar las actividades.</p>
                                    <p className="text-muted mb-2"> f) Entregar al Departamento de Práctica Profesional y Servicio Social los
                                        documentos que avalan haber culminado el Servicio Social Universitario en la
                                        modalidad individual, en un plazo de 15 días hábiles pasada la fecha de término.</p>
                                    <p className="text-muted mb-2"> g) Completar en línea el formulario del informe final con sus respectivas evidencias
                                        en cumplimiento a las actividades realizadas.</p>
                                    <p className="text-muted mb-2"> h) Realizar el servicio social universitario en el lugar, horario y plan de trabajo
                                        estipulado en el programa o proyecto asignado.</p>
                                    <p className="text-muted mb-2"> i) Cumplir con todas las disposiciones del presente reglamento aplicables a los
                                        participantes.</p>

                                    <h6 className="card-title mb-3"> CAPÍTULO VII</h6>
                                    <h6 className="card-title mb-3"> DE LAS OBLIGACIONES DE LAS UNIDADES RECEPTORAS. </h6>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 34.</h6>
                                    <p className="text-muted mb-2">Las unidades receptoras tendrán las obligaciones siguientes:</p>

                                    <p className="text-muted mb-2"> a) Recibir al participante de Servicio Social Universitario y proporcionarle
                                        condiciones de seguridad e higiene apropiadas para el desarrollo de sus
                                        actividades.</p>
                                    <p className="text-muted mb-2"> b) Proporcionar al participante los materiales, instrumentos y apoyo necesarios para
                                        el desarrollo del servicio social universitario.</p>
                                    <p className="text-muted mb-2"> c) Colaborar con la Universidad en la supervisión y evaluación del Servicio Social
                                        Universitario, designando un personal responsable de esas tareas.</p>
                                    <p className="text-muted mb-2"> d) Mantener una actitud de respeto y consideración con el participante.</p>
                                    <p className="text-muted mb-2"> e) Informar a la Coordinación del Servicio Social en la Universidad, sobre las
                                        irregularidades y faltas cometidas por los participantes.</p>
                                    <p className="text-muted mb-2"> f) Completar, firmar y sellar la certificación de finalización del Servicio Social
                                        Universitario de acuerdo al modelo enviado por la coordinación.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 35.</h6>
                                    <p className="text-muted mb-2">La Coordinación del Servicio Social, verificará y asegurará que en los
                                        convenios que se firmen con las unidades receptoras externas, se especifiquen las
                                        obligaciones que indica el artículo anterior.</p>

                                    <h6 className="card-title mb-3"> CAPÍTULO VIII</h6>
                                    <h6 className="card-title mb-3"> DE LAS FALTAS DE LOS PARTICIPANTES Y EL RÉGIMEN DE SANCIONES. </h6>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 36.</h6>
                                    <p className="text-muted mb-2">Se consideran faltas de los participantes las siguientes:</p>

                                    <p className="text-muted mb-2"> a) Abandonar el Servicio Social Universitario, sin causa justificada.</p>
                                    <p className="text-muted mb-2"> b) Realizar actividades diferentes a las establecidas, durante el horario perteneciente
                                        al Servicio Social Universitario.</p>
                                    <p className="text-muted mb-2"> c) No completar el informe final en línea en el tiempo estipulado.</p>
                                    <p className="text-muted mb-2"> d) Presentar informe cuyo contenido carezca de veracidad.</p>
                                    <p className="text-muted mb-2"> e) Cometer actos deshonestos o fraudulentos que afecten el prestigio de la unidad
                                        receptora y/o de la Universidad.</p>
                                    <p className="text-muted mb-2"> f) El servicio social no culminado en el tiempo máximo establecido y entregada la
                                        documentación, no será reconocido, ni validado por el Departamento de Práctica
                                        Profesional y Servicio Social, teniendo que iniciar el proceso.</p>
                                    <p className="text-muted mb-2"> g) No cumplir con las disposiciones establecidas en el presente Reglamento y/o en
                                        los convenios establecidos.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 37.</h6>
                                    <p className="text-muted mb-2">Las faltas señaladas en el artículo anterior darán lugar a la aplicación de las
                                        siguientes sanciones:</p>

                                    <p className="text-muted mb-2"> a) Amonestación verbal.</p>
                                    <p className="text-muted mb-2"> b) Amonestación escrita.</p>
                                    <p className="text-muted mb-2"> c) Anulación de las horas de Servicio Social Universitario acumuladas.</p>
                                    <p className="text-muted mb-2"> d) Cancelación de la asignación al programa o proyecto de Servicio Social
                                        Universitario.</p>
                                    <p className="text-muted mb-2"> e) Cualquier otra establecida en el Reglamento Interno de Disciplina según sea la
                                        falta.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 38.</h6>
                                    <p className="text-muted mb-2">Las sanciones establecidas serán aplicadas de acuerdo a la gravedad de la
                                        falta en que incurra el participante. La amonestación verbal o escrita la hará el
                                        coordinador de servicio social del recinto al que corresponda el participante; la anulación
                                        de las horas de servicio y la cancelación de la asignación al programa, la aplicarán de
                                        común acuerdo el coordinador del Servicio Social y el Director o Encargado del
                                        Departamento Práctica Profesional y Servicio Social. Para faltas muy graves no
                                        contempladas en este Reglamento podrán aplicarse, según el caso, las sanciones
                                        previstas en el Reglamento interno de disciplina de la universidad.</p>


                                    <h6 className="card-title mb-3"> CAPITULO IX </h6>
                                    <h6 className="card-title mb-3"> DE LAS EVALUACIONES DEL SERVICIO SOCIAL UNIVERSITARIO </h6>
                                    <h6 className="card-title mb-3">De la evaluación por la coordinación del servicio social</h6>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 39.</h6>
                                    <p className="text-muted mb-2">El Departamento de Práctica Profesional y Servicio Social recibe la
                                        certificación de finalización de servicio social y control de horas, firmados y sellados
                                        emitidos por la unidad receptora.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 40.</h6>
                                    <p className="text-muted mb-2">El Departamento de Práctica Profesional y Servicio Social valida que los
                                        datos contenidos en la certificación y control de horas estén correctos, confirma la
                                        recepción en línea del informe final, luego se procede a cotejar con la base de datos para
                                        registrar la fecha de inicio y término del servicio social realizado.</p>

                                    <h6 className="card-title mb-3">De la evaluación de los Departamentos de Práctica Profesional y Servicio Social y
                                        la Dirección de Aseguramiento de la Calidad.</h6>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 41.</h6>
                                    <p className="text-muted mb-2">El Departamento de Práctica Profesional y Servicio Social con el apoyo de
                                        la Dirección de Aseguramiento de la Calidad, realizarán una evaluación anual de los
                                        programas y proyectos de servicio social aprobados y ejecutados, para efectos de
                                        determinar la calidad del Servicio Social Universitario y tomar las medidas correctivas
                                        pertinentes.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 42.</h6>
                                    <p className="text-muted mb-2">La evaluación tendrá como propósitos:</p>
                                    <p className="text-muted mb-2"> a) Determinar el impacto del Servicio Social Universitario en la formación integral de
                                        los participantes.</p>
                                    <p className="text-muted mb-2"> b) Determinar los beneficios proporcionados a la sociedad a través de los programas
                                        y proyectos desarrollados, así como las acciones individuales realizadas por los
                                        participantes.</p>

                                    <h6 className="card-title mb-3"><i className="bi bi-circle-square align-baseline me-1 text-info"></i>Artículo 43.</h6>
                                    <p className="text-muted mb-2">La Universidad, además de lo anterior, podrá realizar procesos de
                                        evaluación del desempeño, autoevaluación y evaluación externa de las dependencias,
                                        programas y proyectos del Servicio Social Universitario.</p>

                                    <h6 className="card-title mb-3">DADO y APROBADO LA CIUDAD DE SANTIAGO DE LOS CABALLEROS EN SESIÓN
                                        ORDINARIA DEL CONSEJO ACADÉMICO, A LOS CATORCE (14) DÍAS DEL MES DE
                                        NOVIEMBRE DE 2023.</h6>

                                    <div className="d-flex justify-content-end gap-2 btncontainer">
                                        <Button className='actulizarboton'>Aceptar</Button>
                                        <Button className="cancelarboton"><i className="ri-close-line align-bottom me-1"></i> Declinar</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

        </React.Fragment>
    )
}

export default SsuRegulation;