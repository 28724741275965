import { useState, useEffect, useRef, useMemo } from 'react';
import { fetchElements } from 'services/apiEndpoints';
import { toast } from 'react-toastify';
import { Request } from 'Common/data/requests';
import { useSocialServiceRequest } from './useSocialServiceRequest';
import { configuration } from 'Common/data/endpointsData';

const useRequestsData = () => {
    const [participant, setParticipant] = useState<Request | null>(null);
    const [data, setData] = useState<{ [key: string]: any[] }>({});
    const dataFetchedRef = useRef(false);
    const { roleName } = useSocialServiceRequest();

    const memoizedRoleName = useMemo(() => roleName, [roleName]);

    useEffect(() => {
        const fetchData = async () => {
            if (memoizedRoleName === "Estudiante") {             
                try {
                    const profileFullResponse = await fetchElements('/participants/profile');
                    const { data } = profileFullResponse as unknown as { data: Request };

                    const transformedRequests = data.requests.map((request) => ({
                        ...request,
                        createdAt: new Date(request.createdAt).toLocaleDateString(),
                        updatedAt: new Date(request.updatedAt).toLocaleDateString(),
                        typeRequestName: request.typeRequest.name,
                        statusName: request.status.name,
                        degreeName: request.degree.name,
                        campusName: request.campus.name,
                    }));

                    setParticipant({ ...data, requests: transformedRequests });
                    dataFetchedRef.current = true;
                } catch (error) {
                    toast.error("Error al obtener los datos, intente más tarde o comuníquese con el administrador");
                }
            }
            else {
                setParticipant(null);
            }
        };

        const fetchExtraData = async () => {
            try {
                const responses = await Promise.all(
                    Object.keys(configuration).map(key => fetchElements(configuration[key as keyof typeof configuration]))
                );

                const fetchedData = Object.keys(configuration).reduce((acc, key, index) => {
                    acc[key] = responses[index];
                    return acc;
                }, {} as { [key: string]: any[] });

                setData(fetchedData);
            } catch (error) {
                toast.error("Error al obtener los datos de los dropdowns, intente más tarde o comuníquese con el administrador");
            }
        };

        fetchData();
        fetchExtraData();
    }, [memoizedRoleName]);

    return { participant, data };
};

export default useRequestsData;