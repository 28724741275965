import React, { useState, useEffect, useRef } from 'react';
import { fetchElements, updateElement, createElement, Element, fetchElement } from 'services/apiEndpoints';
import { toast } from 'react-toastify';
import { SocialServiceEndpoints, Jornada, genderMap } from 'Common/data/socialServices';
import { useProfile } from './UserHooks';
import { Request, statusMap } from 'Common/data/requests';
import { useLocation } from 'react-router-dom';

export const useSocialServiceRequest = () => {
    const { roleName } = useProfile();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState<number>(0);
    const [progress, setProgress] = useState<number>(0);
    const [validated, setValidated] = useState<boolean>(false);
    const [data, setData] = useState<{ [key: string]: Element[] }>({});
    const [participant, setParticipant] = useState<{ [key: string]: any } | null>(null);
    const [selectedJornada, setSelectedJornada] = useState<Jornada | null>(null);
    const [whatsappLink, setWhatsappLink] = useState<string | null>(null);
    const [existingRequest, setExistingRequest] = useState<Request | null>(null);

    const formRef = useRef<HTMLFormElement>(null);
    let savedInfo: Element;

    // Determinamos si es una solicitud de servicio social o pasantía
    const isSocialService = location.pathname.includes('create-social-service-request');
    const isInternship = location.pathname.includes("create-intership-request");

    const [formData, setFormData] = useState<any>({
        nameInput: '',
        lastNameInput: '',
        identificationInput: '',
        genderInput: '',
        phoneInput: '',
        whatsappInput: '',
        emailInput: '',
        Provincias: '',
        Municipios: '',
        enrollmentInput: '',
        Recintos: '',
        Períodos: '',
        Carreras: '',
        institutionalEmailInput: '',
        Jornadas: isSocialService ? '' : undefined,
        completedSocialServiceInput: '',
        hasFutureSubjects: true,
        optionalSubjects: '',
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch the data from the endpoints
                const responses = await Promise.all(
                    Object.keys(SocialServiceEndpoints).map(key => fetchElements(SocialServiceEndpoints[key as keyof typeof SocialServiceEndpoints]))
                );

                const fetchedData = Object.keys(SocialServiceEndpoints).reduce((acc, key, index) => {
                    acc[key] = responses[index];
                    return acc;
                }, {} as { [key: string]: Element[] });

                if (Object.keys(fetchedData).length === 0) {
                    console.log("No hay datos disponibles.");
                    return;
                }

                fetchedData['Jornadas'] = fetchedData['Jornadas'].filter((j: Element) => (j as Jornada).request > 0) as Jornada[];
                setData(fetchedData);

                if (roleName !== "Estudiante") {
                    return;
                }

                const participantResponse = await fetchElement('/participants/profile');
                const participantData: Request = participantResponse.data as unknown as Request;
                setParticipant(participantData);

                const info = participantData.info || {};
                setFormData({
                    nameInput: participantData.name,
                    lastNameInput: participantData.lastname,
                    identificationInput: info.identification?.toString() || '',
                    genderInput: Object.keys(genderMap).find(key => genderMap[key] === info.sex) || '',
                    phoneInput: info.phone?.toString() || '',
                    whatsappInput: info.whatsapp?.toString() || '',
                    emailInput: info.personalEmail || '',
                    Provincias: info.province?.id?.toString() || '',
                    Municipios: info.borough?.id?.toString() || '',
                    enrollmentInput: participantData.enrollmentNumber,
                    Recintos: info.campus?.id?.toString() || '',
                    Períodos: info.period?.id?.toString() || '',
                    Carreras: info.degree?.id?.toString() || '',
                    institutionalEmailInput: participantData.email || '',
                    Jornadas: isSocialService ? participantData.serviceSession?.id?.toString() || '' : undefined,
                    completedSocialService: participantData.requests[0]?.completedSocialService ? 'Si' : 'No',
                    hasFutureSubjects: participantData.requests[0]?.hasFutureSubjects ? 'Si' : 'No',
                    optionalSubjects: participantData.requests[0]?.optionalSubjects || '',
                });

                // Verificar si ya existe una solicitud con los tipos de solicitud "Solicitud de servicio social" o "Solicitud de Pasantía"
                const existingRequest = participantData.requests?.find(
                    (request) => {
                        if (isSocialService) {
                            return request.typeRequest.id === 1;
                        } else if (isInternship) {
                            return request.typeRequest.id === 2;
                        }
                        return false;
                    }
                );

                if (existingRequest) {
                    setActiveTab(2);
                    setProgress(100);
                }

            } catch (error) {
                console.log("Error al obtener los datos, intente más tarde o comuníquese con el administrador");
            }
        };

        fetchData();
    }, [roleName, location.pathname]);

    // Para manejar el cambio de jornada en el input
    const handleJornadaChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const jornadaId = parseInt(event.target.value);
        const jornada = data['Jornadas'].find((j: Element) => j.id === jornadaId) as Jornada;
        setSelectedJornada(jornada);
        setFormData({
            ...formData,
            Jornadas: jornadaId.toString()
        });
        if (jornada) {
            setWhatsappLink(jornada.whatsappLink);
        } else {
            setWhatsappLink(null);
        }
    };

    // Para manejar el cambio de los inputs generales
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { id, value } = e.target;
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            [id]: value
        }));
    };

    const handleSubmitInfo = async (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        event.preventDefault();

        if (roleName !== "Estudiante") {
            toast.error("No eres un estudiante para realizar una solicitud.");
            return;
        }

        if (form.checkValidity() === false) {
            event.stopPropagation();
            return;
        } else {
            try {
                const infoData = {
                    personalEmail: formData.emailInput,
                    phone: formData.phoneInput,
                    identification: formData.identificationInput,
                    address: formData.addressInput,
                    whatsapp: formData.whatsappInput,
                    sex: genderMap[formData.genderInput],
                    provinceId: parseInt(formData.Provincias),
                    boroughId: parseInt(formData.Municipios),
                    campusId: parseInt(formData.Recintos),
                    degreeId: parseInt(formData.Carreras),
                    periodId: parseInt(formData.Períodos),
                    participantId: participant?.id
                };

                // Para crear o actualizar la información del participante
                if (participant && participant.info && participant.info.id) {
                    savedInfo = await updateElement('/info', participant.info.id, infoData);
                } else {
                    savedInfo = await createElement('/info', infoData);
                }

                const participantData = {
                    name: formData.nameInput,
                    lastname: formData.lastNameInput,
                    enrollmentNumber: formData.enrollmentInput,
                    institutionalEmail: formData.institutionalEmailInput,
                    serviceSession: selectedJornada ? { id: selectedJornada.id } : participant?.serviceSession ? { id: participant.serviceSession.id } : null,
                    info: savedInfo.id
                };

                // Crear o actualizar la información del participante
                if (participant && participant.id) {
                    await updateElement('/participants/updateInfoAndParticipant', participant.id, { infoData, participantData });
                    setParticipant({ ...participant, info: savedInfo });
                } else {
                    console.error("Participant ID not found");
                    toast.error("No se encontró el ID del participante.");
                    return;
                }
                toast.success("Finalizado satisfactoriamente")
            } catch (error: any) {
                toast.error(error.message || "Ocurrió un error, intente más tarde o comuníquese con el administrador.");
            }

        }
        setValidated(true);
    };

    const handleSubmitRequests = async (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        event.preventDefault();

        if (roleName !== "Estudiante") {
            toast.info("No eres un estudiante para realizar una solicitud.");
            return
        }

        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            try {
                await handleSubmitInfo(event);

                // Crear la solicitud
                const createRequest = {
                    description: isInternship ? "Solicitud de pasantía" : "Solicitud de servicio social",
                    typeRequestId: isInternship ? 2 : 1,
                    statusId: 1,
                    degreeId: parseInt(formData.Carreras),
                    campusId: parseInt(formData.Recintos),
                    participantId: participant?.id,
                    completedSocialService: isInternship ? formData.completedSocialService === "Si" : undefined,
                    hasFutureSubjects: isInternship ? formData.hasFutureSubjects === "Si" : undefined,
                    optionalSubjects: isInternship ? formData.optionalSubjects : undefined,
                };

                // Pra actualizar la solicitud si ya existe o crearla si no
                const existingRequest = participant?.requests?.find(
                    (request: any) => {
                        if (isSocialService) {
                            return request.typeRequest.id === 1;
                        } else if (isInternship) {
                            return request.typeRequest.id === 2;
                        }
                        return false;
                    }
                );

                if (existingRequest) {
                    await updateElement('/requests', participant?.requests[0].id, createRequest);
                } else {
                    await createElement('/requests', createRequest);
                }

                // Con esto se disminuye la cantidad de solicitudes disponibles por cada solicitud enviada
                if (isSocialService && selectedJornada && selectedJornada.request > 0) {
                    const updatedJornada = { ...selectedJornada, request: selectedJornada.request - 1 };
                    await updateElement('/serviceSessions', selectedJornada.id, updatedJornada);
                    setData(prevData => ({
                        ...prevData,
                        'Jornadas': prevData['Jornadas'].map((j: Element) => j.id === selectedJornada.id ? updatedJornada : j)
                    }));
                    setSelectedJornada(updatedJornada);
                }

                // Mantener la jornada seleccionada previamente si no se elige una nueva
                if (!isSocialService && participant?.serviceSession) {
                    setSelectedJornada(participant.serviceSession);
                }

                //depende si es pasantia o servicio social el setActiveTab sera 2 o 3
                setActiveTab(isInternship ? 3 : 2);
                setProgress(100);
            } catch (error) {
                toast.error("Ocurrio un error, intente más tarde o comuníquese con el administrador");
            }
        }
        setValidated(true);

    }

    const updateRequest = async (requestId: number, updatedData: { status: string, description: string }) => {
        try {
            const statusId = statusMap[updatedData.status];
            await updateElement('/requests', requestId, {
                statusId,
                description: updatedData.description
            });

            // Actualiza el estado local del participante con la nueva información
            if (participant) {
                const updatedRequests = participant.requests.map((req: Request) =>
                    req.id === requestId ? { ...req, statusName: updatedData.status } : req
                );
                setParticipant({ ...participant, requests: updatedRequests });
            }
            toast.success("Solicitud actualizada correctamente");
        } catch (error) {
            toast.error("Error al actualizar la solicitud, intente más tarde o comuníquese con el administrador");
        }
    };

    const handleTabChange = (tabIndex: number) => {
        if (formRef.current && formRef.current.checkValidity() === false) {
            setValidated(true);
        } else {
            setActiveTab(tabIndex);
            setProgress(tabIndex * 50);
        }
    };

    return {
        activeTab,
        progress,
        validated,
        data,
        participant,
        selectedJornada,
        whatsappLink,
        formData,
        formRef,
        handleJornadaChange,
        handleChange,
        handleSubmitInfo,
        handleSubmitRequests,
        handleTabChange,
        roleName,
        updateRequest,
        existingRequest,
    };
};