import React, { useState } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import PaginationComponent from "Components/Pagination";
import usePagination from "Common/Hooks/UsePagination";
import useFilter from "Common/Hooks/UseFilter";
import useAllRequestsData from "Common/Hooks/useAllRequestsData";
import { Request } from 'Common/data/requests';
import { columnNamesAdmin } from "Common/data/columnNames";
import ModalComponent from "Components/Modal";
import { useLocation } from 'react-router-dom';
import LordIcon from "Components/LordIcon";
import "assets/scss/pages/_requests.scss";
import BotonExplorarMetricas from 'Components/BotonExplorarMetricas';

function RequestsTable() {
  document.title = "Métricas de Solicitudes";

  const icon = require('../../../assets/animations/nodata.json');

  const { requests, loading, refetchData } = useAllRequestsData();

  const filteredRequestsByStatus: Request[] = requests.map(req => ({
    ...req,
    requests: req.requests.filter(request =>
      request.status.name === "Revisado" || request.status.name === "Aprobado"
    )
  })).filter(req => req.requests.length > 0);

  const { setSearch, filteredRequests } = useFilter(filteredRequestsByStatus);
  const [showModal, setShowModal] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState<Request['requests'][0] | null>(null);
  const location = useLocation();
  const currentView = location.pathname;
  const itemsPerPage = 5;
  const { currentPage, setCurrentPage, itemsToShow } = usePagination(filteredRequests, itemsPerPage);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(1);
    setSearch(e.target.value);
  };

  const openModal = (request: Request['requests'][0]) => {
    setSelectedRequest(request);
    setShowModal(true);
  };

  const handleApprove = async () => {
    await refetchData();
    setShowModal(false);
    setCurrentPage(1);
  };

  const handleReject = async () => {
    await refetchData();
    setShowModal(false);
    setCurrentPage(1);
  };

  // Transformar los datos para pasarlos al componente BotonExplorarMetricas
  const transformedRequests = filteredRequests.map(req => req.requests);

  // Renderizar las columnas para acceder tanto a participant como a requests
  const renderCellContent = (participant: Request, request: Request['requests'][0], key: keyof typeof columnNamesAdmin): React.ReactNode => {
    const cellMapping: { [key in keyof typeof columnNamesAdmin]: string | undefined } = {
      id: request.id.toString(),
      name: participant.name,
      lastname: participant.lastname,
      enrollmentNumber: participant.enrollmentNumber,
      createdAt: new Date(request.createdAt).toLocaleDateString(),
      email: participant.email,
      statusName: request.status.name,
      description: request.description,
      typeRequestName: request.typeRequest.name,
      degreeName: request.degree.name,
      campusName: request.campus.name,
    };

    return cellMapping[key] || 'N/A';
  };

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Row className="box-header">
                <Col>
                  <h6 className="card-title mb-0">Solicitudes</h6>
                </Col>
                <Col className="search-box-container">
                  <div className="search-box">
                    <Form.Control
                      type="text"
                      className="search"
                      placeholder="Buscar por cualquier criterio..."
                      onChange={handleSearch}
                    />
                    <i className="ri-search-line search-icon"></i>
                  </div>
                </Col>
                <div className="hstack gap-2 Export-Button">
                  <BotonExplorarMetricas requests={transformedRequests} />
                </div>
              </Row>
            </Card.Header>
            <Card.Body>
              {loading ? (
                <div className="d-flex justify-content-center align-content-center">
                  <p>Cargando solicitudes...</p>
                </div>
              ) : (
                <div className="table-container">
                  <table className="table-responsive table table-centered align-middle ">
                    <thead className="table-light">
                      <tr>
                        {Object.values(columnNamesAdmin).map((columnName, index) => (
                          <th key={index}>{columnName}</th>
                        ))}
                        <th>Acción</th>
                      </tr>
                    </thead>
                    <tbody>
                      {itemsToShow.length > 0 ? (
                        itemsToShow.map((participant: Request, idx) => (
                          participant.requests.map((request, requestIdx) => (
                            <tr key={`${idx}-${requestIdx}`}>
                              {Object.keys(columnNamesAdmin).map((key) => (
                                <td key={key} data-column={`${columnNamesAdmin[key]}:`}>
                                  {renderCellContent(participant, request, key as keyof typeof columnNamesAdmin)}
                                </td>
                              ))}
                              <td>
                                <div className="d-flex justify-content-center align-items-center">
                                  <Button
                                    onClick={() => openModal(request)}
                                    className="btnver"
                                    disabled={!request}
                                  >
                                    <i className="ri ri-eye-line"></i>
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          ))
                        ))
                      ) : (
                        <tr>
                          <td colSpan={Object.keys(columnNamesAdmin).length + 1}>
                            <div className="d-flex justify-content-center align-content-center">
                              <LordIcon icon={icon} />
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}

              <ModalComponent
                show={showModal}
                onHide={() => setShowModal(false)}
                view={currentView}
                request={selectedRequest}
                participant={selectedRequest ? requests.find(r => r.requests.some(req => req.id === selectedRequest.id)) || null : null}
                onApprove={handleApprove}
                onReject={handleReject}
              />

              <div>
                <nav aria-label="requests navigation">
                  <PaginationComponent
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalItems={filteredRequests.length}
                    itemsPerPage={itemsPerPage}
                  />
                </nav>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default RequestsTable;