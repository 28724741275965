export const internshipTabOne = [
  {
    label: "Nombre",
    placeholder: "Ingrese su nombre",
    id: "nameInput",
    value: "",
    type: "text",
    col: 6,
  },
  {
    label: "Apellidos",
    placeholder: "Ingrese sus apellidos",
    id: "lastNameInput",
    value: "",
    type: "text",
    col: 6,
  },
  {
    label: "Cédula",
    placeholder: "Ingrese su cedula",
    id: "identificationInput",
    value: "",
    type: "text",
    col: 4,
  },
  {
    label: "Sexo",
    placeholder: "Ingrese su sexo",
    id: "genderInput",
    option: ["Femenino", "Masculino", "Otro"],
    value: "",
  },
  {
    label: "Teléfono",
    placeholder: "Ingrese su telefono",
    id: "phoneInput",
    type: "number",
    value: "",
  },
  {
    label: "Whatsapp",
    placeholder: "Ingrese su whatsapp",
    id: "whatsappInput",
    type: "number",
    value: "",
  },
  {
    label: "Correo",
    placeholder: "Ingrese su correo electronico",
    id: "emailInput",
    type: "email",
    value: "",
  },
  {
    label: "Provincia",
    id: "Provincias",
    option: [],
  },
  {
    label: "Municipio",
    id: "Municipios",
    option: [],
  },
];

export const internshipTabTwo = [
  {
    label: "ID - Matrícula",
    id: "enrollmentInput",
    placeholder: "Ingrese su matricula, eje: 100020202",
    type: "number",
    value: "",
  },
  {
    label: "Recinto u oficina de apoyo a la que perteneces ",
    id: "Recintos",
    option: [],
    value: ""
  },
  {
    label: "Período Académico",
    id: "Períodos",
    option: [],
    value: ""
  },
  {
    label: "Carrera",
    id: "Carreras",
    option: [],
    value: ""
  },
  {
    label: "Correo Institucional",
    id: "institutionalEmailInput",
    placeholder: "ejem: 202002153@p.uapa.edu.do",
    type: "email",
    value: ""
  },
  {
    label: "¿Realizaste el Servicio Social Universitario?",
    id: "completedSocialService",
    option: [ "Si", "No"],
    value: ""
  },
  {
    label: "¿Tienes que cursar otras asignaturas el próximo trimestre?",
    id: "hasFutureSubjects",
    option: [ "Si", "No"],
    value: ""
  },
];
