import React from 'react';
import { Container, Row, Col, Button, Form, Tab, Card, Nav, ProgressBar } from 'react-bootstrap';
import { Link } from "react-router-dom";
import BreadCrumb from 'Common/BreadCrumb';
import { ssuformTabOne, ssuformTabTwo } from 'Common/data/socialServices';
import { Element } from 'services/apiEndpoints';
import { useSocialServiceRequest } from '../../../Common/Hooks/useSocialServiceRequest';
import LordIcon from 'Components/LordIcon';
import '../../../assets/scss/pages/_SocialServiceRequest.scss';

const CreateSocialServiceRequest = () => {
    document.title = "Crear solicitud - Servicio Social";

    const {
        activeTab,
        progress,
        validated,
        data,
        selectedJornada,
        whatsappLink,
        formData,
        formRef,
        handleJornadaChange,
        handleChange,
        handleSubmitRequests,
        handleTabChange
    } = useSocialServiceRequest();

    const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        handleSubmitRequests(e);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Servicio Social" pageTitle="Solicitudes" />
                </Container>
                <Container fluid>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Body className="form-steps">
                                    <Tab.Container activeKey={activeTab} unmountOnExit>
                                        <div className="text-center pt-3 pb-4 mb-1">
                                            <h5>Solicitud para el servicio social</h5>
                                        </div>
                                        <div id="custom-progress-bar" className="progress-nav mb-4">
                                            <div>
                                                <ProgressBar now={progress} style={{ height: "1px" }}></ProgressBar>
                                            </div>
                                            <Nav as="ul" variant="pills" className="progress-bar-tab">
                                                <Nav.Item as="li">
                                                    <Nav.Link as="button" eventKey="0" onClick={() => handleTabChange(0)} className="rounded-pill">1</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item as="li">
                                                    <Nav.Link as="button" eventKey="1" onClick={() => handleTabChange(1)} className="rounded-pill">2</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item as="li">
                                                    <Nav.Link as="button" eventKey="2" onClick={() => handleTabChange(2)} className="rounded-pill">3</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </div>

                                        <Tab.Content>
                                            <Form noValidate validated={validated} onSubmit={handleFormSubmit} ref={formRef}>
                                                <Tab.Pane eventKey="0" className={activeTab === 0 ? '' : 'd-none'}>
                                                    <div>
                                                        <h5 className="mb-1">Información General</h5>
                                                        <p className="text-muted">Completa cada uno de los campos requeridos.</p>
                                                        <Row>
                                                            {ssuformTabOne.map((ssu, index) => (
                                                                <Col key={index} lg={4} md={6} sm={12}>
                                                                    <div className="mb-3">
                                                                        <Form.Group>
                                                                            <Form.Label htmlFor={ssu.id}>{ssu.label}</Form.Label>
                                                                            {!ssu.option ? (
                                                                                <Form.Control
                                                                                    type={ssu.type}
                                                                                    id={ssu.id}
                                                                                    placeholder={ssu.placeholder}
                                                                                    required
                                                                                    value={formData[ssu.id] || ''}
                                                                                    onChange={handleChange}
                                                                                />
                                                                            ) : (
                                                                                <Form.Select id={ssu.id} required value={formData[ssu.id] || ''} onChange={handleChange}>
                                                                                    {!formData[ssu.id] && <option value="">Seleccionar</option>}
                                                                                    {ssu.option.map((option, idx) => (
                                                                                        <option key={idx} value={option}>{option}</option>
                                                                                    ))}
                                                                                    {data[ssu.id]?.map((option: Element) => (
                                                                                        <option key={option.id} value={option.id}>{option.name}</option>
                                                                                    ))}
                                                                                </Form.Select>
                                                                            )}
                                                                            <Form.Control.Feedback type="invalid">Completa este espacio vacío</Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                            ))}
                                                        </Row>
                                                    </div>
                                                    <div className="d-flex align-items-start gap-3 mt-4">
                                                        <Button type="button" className="btn btn-primary btn-label right ms-auto nexttab nexttab font-weight-bold custom-button" onClick={() => handleTabChange(1)}>
                                                            <i className="ri-arrow-right-line label-icon align-middle fs-lg ms-2"></i>Siguiente
                                                        </Button>
                                                    </div>
                                                </Tab.Pane>

                                                <Tab.Pane eventKey="1" className={activeTab === 1 ? '' : 'd-none'}>
                                                    <div>
                                                        <Row>
                                                            {ssuformTabTwo.map((ssu, index) => (
                                                                <Col key={index} lg={6} md={6} sm={12} className="px-xl-2 px-xxl-5">
                                                                    <div className="mb-3">
                                                                        <Form.Group>
                                                                            <Form.Label htmlFor={ssu.id}>{ssu.label}</Form.Label>
                                                                            {!ssu.option ? (
                                                                                <Form.Control
                                                                                    type={ssu.type}
                                                                                    id={ssu.id}
                                                                                    placeholder={ssu.placeholder}
                                                                                    required
                                                                                    value={formData[ssu.id] || ''}
                                                                                    onChange={handleChange}
                                                                                />
                                                                            ) : (
                                                                                <Form.Select
                                                                                    id={ssu.id}
                                                                                    required
                                                                                    value={formData[ssu.id] || ''}
                                                                                    onChange={ssu.id === 'Jornadas' ? handleJornadaChange : handleChange}>
                                                                                    {!formData[ssu.id] && <option value="">Seleccionar</option>}
                                                                                    {ssu.option.map((option, idx) => (
                                                                                        <option key={idx} value={option}>{option}</option>
                                                                                    ))}
                                                                                    {data[ssu.id]?.map((option: Element) => (
                                                                                        <option key={option.id} value={option.id}>{option.name}</option>
                                                                                    ))}
                                                                                </Form.Select>
                                                                            )}
                                                                            <Form.Control.Feedback type="invalid">Completa este espacio vacío</Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                            ))}
                                                        </Row>
                                                        <Form.Group className="d-flex align-content-center mt-5">
                                                            <Form.Check required feedbackType="invalid" id="checkMark" type="checkbox" />
                                                            <Form.Label className="form-check-label ms-2" htmlFor="checkMark">
                                                                <Link to="/ssu-regulation" target="_blank" className='regulation'>He leído el Reglamento Institucional del SSU</Link>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </div>
                                                    <div className="d-flex align-items-start gap-3 mt-4">
                                                        <Button type="button" className="btn btn-link text-decoration-none btn-label previestab custom-button-back" onClick={() => handleTabChange(0)}>
                                                            <i className="ri-arrow-left-line label-icon align-middle fs-lg me-2"></i>Volver
                                                        </Button>
                                                        <Button type="submit" className="btn btn-primary btn-label right ms-auto nexttab nexttab font-weight-bold custom-button">
                                                            <i className="ri-arrow-right-line label-icon align-middle fs-lg ms-2"></i>Enviar
                                                        </Button>
                                                    </div>
                                                </Tab.Pane>

                                                <Tab.Pane eventKey="2" className={activeTab === 2 ? '' : 'd-none'}>
                                                    <div>
                                                        <div className="text-center">
                                                            <div className="d-flex justify-content-center mb-4">
                                                                <LordIcon icon={require('../../../assets/animations/confetti.json')} />
                                                            </div>
                                                            <h5>Su solicitud ha sido enviada!</h5>
                                                            <p className="text-muted">Por favor, esté pendiente a su correo institucional y el grupo de WhatsApp al cual debe de ingresar a continuación:</p>
                                                            {selectedJornada && whatsappLink && (
                                                                <div className="mb-3">
                                                                    <p className="text-muted">Enlace del grupo de WhatsApp:</p>
                                                                    <a href={whatsappLink} target="_blank" rel="noopener noreferrer">{whatsappLink}</a>
                                                                </div>
                                                            )}
                                                            <Link to="/home" className='text-white'><Button className="d-block btn-orange-uapa mx-auto my-3 rounded w-25">Volver al inicio</Button></Link>
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                            </Form>
                                        </Tab.Content>
                                    </Tab.Container>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CreateSocialServiceRequest;