import React from 'react';
import { Pagination } from 'react-bootstrap';

interface PaginationComponentProps {
    currentPage: number;
    setCurrentPage: (page: number) => void;
    totalItems: number;
    itemsPerPage: number;
}

const PaginationComponent: React.FC<PaginationComponentProps> = ({ currentPage, setCurrentPage, totalItems, itemsPerPage }) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    return (
        <div>
            <nav aria-label="requests navigation">
                <Pagination className="d-flex justify-content-center mt-4">
                    {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                        <Pagination.Item
                            key={page}
                            active={page === currentPage}
                            onClick={() => setCurrentPage(page)}
                            className="d-flex justify-content-center mt-4"
                        >
                            {page}
                        </Pagination.Item>
                    ))}
                </Pagination>
            </nav>
        </div>
    );
};

export default PaginationComponent;