import React, { useState } from 'react';
import { Card, Col, Button, Form, Alert, Spinner } from 'react-bootstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { Link } from 'react-router-dom';
import withRouter from 'Common/withRouter';
import { useNavigate } from 'react-router-dom';
import { signupinfo } from 'Common/data/signup';
import axios from 'axios';

const Signup = () => {
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [passwordShow, setPasswordShow] = useState<boolean>(false);
    const [confirmpasswordShow, setConfirmPasswordShow] = useState<boolean>(false);
    const [formData, setFormData] = useState({
        name: '',
        lastname: '',
        enrollmentNumber: '',
        email: '',
        password: '',
        confirmPassword: '',
    });

    const validateEmail = (email: string) => {
        const regex = /(@p.uapa.edu.do|@uapa.edu.do)$/;
        return regex.test(email);
    };

    const handleChange = (e: any) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    //Pendiente por hacer con el endpoint correcto
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
        }
        setValidated(true);
        // Validar el formato del correo
        if (!validateEmail(formData.email)) {
            setError('Debe de registrarse con su correo institucional.');
            return;
        }
        if (form.checkValidity() === true) {
            if (formData.password !== formData.confirmPassword) {
                setError('Las contraseñas no coinciden');
                return;
            }
            setLoading(true);
            try {
                const { confirmPassword, ...dataWithoutConfirmPassword } = formData;
                //Si ejecuta el backend primero sino debe ser :3001
                await axios.post('https://sagespra.uapa.edu.do/api/registration', dataWithoutConfirmPassword);
                navigate("/login");
            } catch (error) {
                setLoading(false);
                setError('Ocurrió un error al intentar registrarse, por favor intente de nuevo más tarde.');
            } finally {
                setLoading(false);
            }
        }
    };
    return (
        <ParticlesAuth>
            <React.Fragment>
                <Col lg="6" className="mx-auto">
                    <Card className="mb-0 border-0 shadow-none mb-0">
                        <Card.Body className="p-sm-5 m-lg-4">
                            <div className="text-center mt-5">
                                
                                <h5 className="fs-3xl">Bienvenido</h5>
                                <p className="text-muted">Registrese para ingresar a SAGRESPRA - (UAPA)</p>
                            </div>
                            <div className="p-2 mt-5">
                                {error && error ? (<Alert variant="danger"> {error} </Alert>) : null}

                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                    {signupinfo.map((item, index) => (
                                        <Form.Group className="mb-3" controlId={item.controlId} key={index}>
                                            <Form.Label>{item.label} <span className="text-orange-uapa">*</span></Form.Label>
                                            <div className="position-relative">
                                                <Form.Control
                                                    type={item.type === 'password' ? (item.name === 'password' ? passwordShow ? "text" : "password" : confirmpasswordShow ? "text" : "password") : item.type}
                                                    name={item.name}
                                                    className="form-control password-input"
                                                    placeholder={item.placeholder}
                                                    required
                                                    onChange={handleChange}
                                                    value={formData[item.name as keyof typeof formData]}
                                                    isInvalid={validated && formData[item.name as keyof typeof formData].length === 0}
                                                />
                                                {item.type === 'password' && (
                                                    <Button variant="link" className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="password-confirm" onClick={item.name === 'password' ? () => setPasswordShow(!passwordShow) : () => setConfirmPasswordShow(!confirmpasswordShow)}>
                                                        {(item.name === 'password' ? passwordShow : confirmpasswordShow) ? (
                                                            <i className="fa fa-eye-slash"></i>
                                                        ) : (
                                                            <i className="fa fa-eye"></i>
                                                        )}
                                                    </Button>
                                                )}
                                                <Form.Control.Feedback type="invalid">Completa este espacio vacío</Form.Control.Feedback>
                                            </div>
                                        </Form.Group>
                                    ))}

                                    <div className="mt-4">
                                        <Button className="btn btn-login w-100" type="submit" disabled={loading}> {loading && <Spinner size='sm' />} {" "}Registrarse</Button>
                                    </div>

                                </Form>
                                <div className="mt-4 text-center">
                                    <p className="mb-0">¿Ya tienes una cuenta? <Link to="/login" className="text-decoration-underline link-orange-uapa link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">Iniciar sesión</Link></p>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </React.Fragment>
        </ParticlesAuth>
    );
};

export default withRouter(Signup);