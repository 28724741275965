import React, { useState } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import PaginationComponent from "Components/Pagination";
import usePagination from "Common/Hooks/UsePagination";
import useFilter from "Common/Hooks/UseFilter";
import { downloadExcel } from "react-export-table-to-excel";
import { Request } from 'Common/data/requests';
import { columnNamesRequest, columnNamesDocumentation } from "Common/data/columnNames";
import ModalComponent from "Components/Modal";
import { Link, useLocation } from 'react-router-dom';
import LordIcon from "Components/LordIcon";
import { Media } from "Components/Media";
import useRequestsData from "Common/Hooks/useRequestsData";
import "assets/scss/pages/_requests.scss";

export const DocumentationTable = () => {
    const icon = require('../../../assets/animations/nodata.json');
    const { participant } = useRequestsData();
    const { setSearch, filteredRequests } = useFilter((participant?.requests as Request[] | undefined) || []);
    const [showModal, setShowModal] = useState(false);
    const [selectedRequest, setSelectedRequest] = useState<Request['requests'][0] | null>(null);
    const location = useLocation();
    const currentView = location.pathname;
    const itemsPerPage = 5;
    const { currentPage, setCurrentPage, itemsToShow } = usePagination(
        filteredRequests,
        itemsPerPage
    );

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentPage(1);
        setSearch(e.target.value);
    };

    const body = filteredRequests.map(Object.values);

    function handleDownloadExcel() {
        downloadExcel({
            fileName: "Solicitudes",
            sheet: "Solicitudes",
            tablePayload: {
                header: Object.values(columnNamesRequest),
                body,
            },
        });
    }

    const openModal = (request: Request['requests'][0]) => {
        setSelectedRequest(request);
        setShowModal(true);
    };

    const handleApprove = () => {
        setShowModal(false);
    };

    const handleReject = () => {
        setShowModal(false);
    };

    const renderCellContent = (value: any, key: string) => {
        if (typeof value === 'object' && value !== null) {
            return value.name;
        }
        return value;
    };

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Card>
                        <Card.Header>
                            <Row className="box-header">
                                {/* Título */}
                                <Col>
                                    <h6 className="card-title mb-0">Documentaciones recibidas</h6>
                                </Col>

                                {/* Buscador */}
                                <Col className="search-box-container">
                                    <div className="search-box">
                                        <Form.Control
                                            type="text"
                                            className="search"
                                            placeholder="Buscar por cualquier criterio..."
                                            onChange={handleSearch}
                                        />
                                        <i className="ri-search-line search-icon"></i>
                                    </div>
                                    {/* Exportar a Excel */}
                                    <div className="hstack gap-2 Export-Button">
                                        <button
                                            onClick={handleDownloadExcel}
                                            className="btn btn-orange-uapa"
                                        >
                                            Exportar
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <div className="table-container">
                                <table className="table-responsive table table-centered align-middle">
                                    <thead className="table-light">
                                        <tr>
                                            {Object.values(columnNamesDocumentation).map((columnName, index) => (
                                                <th key={index}>{columnName}</th>
                                            ))}
                                            <th>Acción</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/*itemsToShow.length > 0 ? (
                                            itemsToShow.map((request: Request['requests'][0]) => (
                                                <tr key={request.id}>
                                                    {Object.keys(columnNamesDocumentation)
                                                        .filter(key => key !== 'documents')
                                                        .map((key) => (
                                                            <td key={key} data-column={`${columnNamesDocumentation[key]}:`}>
                                                                {renderCellContent((request as any)[key.split('.')[0]], key)}
                                                            </td>
                                                        ))}
                                                    <td>
                                                        {request.documents?.map((documentUrl, index) => (
                                                            <Link to={documentUrl} target="_blank" rel="noopener noreferrer" key={index} className="d-flex justify-content-center">
                                                                <Media key={index} url={documentUrl} width='50px' height='50px' />
                                                            </Link>
                                                        ))}
                                                    </td>
                                                    <td>
                                                        <div className="d-flex justify-content-center align-items-center">
                                                            <Button onClick={() => openModal(request)} className="btnver">
                                                                <i className="ri ri-eye-line"></i>
                                                            </Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={Object.keys(columnNamesDocumentation).length + 1}>
                                                    <div className="d-flex justify-content-center align-content-center">
                                                        <LordIcon icon={icon} />
                                                    </div>
                                                </td>
                                            </tr>
                                        )*/}
                                    </tbody>
                                </table>
                            </div>
                            <ModalComponent
                                show={showModal}
                                onHide={() => setShowModal(false)}
                                view={currentView}
                                request={selectedRequest}
                                participant={participant}
                                onApprove={handleApprove}
                                onReject={handleReject}
                                DocumentationTable={true}
                            />
                            <div>
                                <nav aria-label="requests navigation">
                                    <PaginationComponent
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        totalItems={filteredRequests.length}
                                        itemsPerPage={itemsPerPage}
                                    />
                                </nav>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
}
