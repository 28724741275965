import BreadCrumb from "Common/BreadCrumb";
import React from "react";
import { Card, Col, Form, Nav, ProgressBar, Row, Tab, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import LordIcon from "Components/LordIcon";
import { internshipTabOne, internshipTabTwo } from "Common/data/internship";
import { Element } from 'services/apiEndpoints';
import "../../../assets/scss/pages/_SocialServiceRequest.scss";
import "../../../assets/scss/pages/_InternshipRequest.scss";
import aviso from "assets/images/auth/Aviso.png";
import { useSocialServiceRequest } from "Common/Hooks/useSocialServiceRequest";
const CreateIntershipRequest = () => {
  document.title = "Crear solicitud - Pasantía";

  const icon = require("../../../assets/animations/confetti.json");

  const {
    activeTab,
    progress,
    validated,
    data,
    formData,
    formRef,
    handleChange,
    handleSubmitRequests,
    handleTabChange
  } = useSocialServiceRequest();

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmitRequests(e);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Pasantía" pageTitle="Solicitudes" />
        </Container>
        <Container fluid>
          <Row>
            <Col>
              <Card>
                <Card.Body className="form-steps">
                  <Tab.Container activeKey={activeTab} unmountOnExit>
                    <div className="text-center pt-3 pb-4 my-3">
                      <h5>Solicitud para la Pasantía</h5>
                    </div>

                    <div id="custom-progress-bar" className="progress-nav mb-4">
                      <div>
                        <ProgressBar
                          now={progress}
                          style={{ height: "1px" }}
                        ></ProgressBar>
                      </div>

                      {/*botones circulares*/}
                      <Nav as="ul" variant="pills" className="progress-bar-tab">
                        <Nav.Item as="li">
                          <Nav.Link as="button" eventKey="0" onClick={() => handleTabChange(0)} className="rounded-pill" >1</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                          <Nav.Link as="button" eventKey="1" onClick={() => handleTabChange(1)} className="rounded-pill">2</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                          <Nav.Link as="button" eventKey="2" onClick={() => handleTabChange(2)} className="rounded-pill">3</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                          <Nav.Link as="button" eventKey="2" onClick={() => handleTabChange(2)} className="rounded-pill">4</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>

                    <Tab.Content>
                      <Form
                        noValidate
                        validated={validated}
                        onSubmit={handleFormSubmit}
                        ref={formRef}
                      >
                        <Tab.Pane
                          eventKey="0"
                          id="pills-gen-info"
                          role="tabpanel"
                          aria-labelledby="pills-gen-info-tab"
                          className={activeTab === 0 ? "" : "d-none"}
                        >
                          <div className="d-flex flex-column align-items-center justify-content-center mt-4">
                            <img
                              src={aviso}
                              alt=""
                              className="imgaviso img-thumbnail"

                            />
                            <br />
                            <button
                              type="button"
                              className="btn btn-primary btn-label nexttab font-weight-bold custom-button mb-3 ms-auto"
                              onClick={() => handleTabChange(1)}
                            >
                              Iniciar Formulario
                              <i className="ri-arrow-right-line label-icon align-middle fs-lg mx-2"></i>
                            </button>
                          </div>
                        </Tab.Pane>

                        {/*El segundo tab*/}
                        <Tab.Pane
                          eventKey="1"
                          id="pills-gen-info"
                          role="tabpanel"
                          aria-labelledby="pills-gen-info-tab"
                          className={activeTab === 1 ? "" : "d-none"}
                        >
                          <div>
                            <div className="mb-4">
                              <h5 className="mb-1">Información General</h5>
                              <p className="text-muted">
                                Completa cada uno de los campos requeridos.
                              </p>
                            </div>
                            <Row>
                              {internshipTabOne.map((internship, index) => (
                                <Col key={index} lg={4} md={6} sm={12}>
                                  <div className="mb-3">
                                    <Form.Group>
                                      <Form.Label htmlFor={internship.id}>
                                        {internship.label}
                                      </Form.Label>
                                      {!internship.option ? (
                                        <Form.Control
                                          type={internship.type}
                                          id={internship.id}
                                          placeholder={internship.placeholder}
                                          required
                                          value={formData[internship.id] || ''}
                                          onChange={handleChange}
                                        />
                                      ) : (
                                        <Form.Select id={internship.id} required value={formData[internship.id] || ''} onChange={handleChange}>
                                          {!formData[internship.id] && <option value="">Seleccionar</option>}
                                          {internship.option.map((option, idx) => (
                                            <option key={idx} value={option}>{option}</option>
                                          ))}
                                          {data[internship.id]?.map((option: Element) => (
                                            <option key={option.id} value={option.id}>{option.name}</option>
                                          ))}
                                        </Form.Select>
                                      )}
                                      <Form.Control.Feedback type="invalid">
                                        Completa este espacio vacío
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </div>
                                </Col>
                              ))}
                            </Row>
                          </div>
                          <div className="d-flex align-items-start gap-3 mt-4">
                            <button
                              type="button"
                              className="btn btn-link text-decoration-none btn-label previestab custom-button-back"
                              onClick={() => handleTabChange(0)}
                            >
                              <i className="ri-arrow-left-line label-icon align-middle fs-lg mx-2"></i>
                              Volver
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary btn-label right ms-auto nexttab nexttab font-weight-bold custom-button"
                              onClick={() => handleTabChange(2)}
                            >
                              <i className="ri-arrow-right-line label-icon align-middle fs-lg mx-2"></i>
                              Siguiente
                            </button>
                          </div>
                        </Tab.Pane>

                        {/*El tercero tab*/}
                        <Tab.Pane
                          eventKey="2"
                          id="pills-info-desc"
                          role="tabpanel"
                          aria-labelledby="pills-info-desc-tab"
                          className={activeTab === 2 ? "" : "d-none"}
                        >
                          <div>
                            <Row>
                              {internshipTabTwo.map((internship, index) => (
                                <Col key={index} lg={6} md={6} sm={12}>
                                  <Row>
                                    <Col>
                                      <div className="m-3">
                                        <Form.Group>
                                          <Form.Label htmlFor={internship.id}>{internship.label}</Form.Label>
                                          {internship.option ? (
                                            <Form.Select
                                              className="form-control"
                                              id={internship.id}
                                              required
                                              value={formData[internship.id]}
                                              onChange={handleChange}
                                            >
                                              {!formData[internship.id] && <option value="">Seleccionar</option>}
                                              {internship.option.map((option, idx) => (
                                                <option key={idx} value={option}>{option}</option>
                                              ))}
                                              {data[internship.id]?.map((option: Element) => (
                                                <option key={option.id} value={option.id}>{option.name}</option>
                                              ))}
                                            </Form.Select>
                                          ) : (
                                            <Form.Control
                                              type={internship.type}
                                              id={internship.id}
                                              placeholder={internship.placeholder}
                                              required
                                              value={formData[internship.id]}
                                              onChange={handleChange}
                                            />
                                          )}
                                          <Form.Control.Feedback type="invalid">Completa este espacio vacío</Form.Control.Feedback>
                                        </Form.Group>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              ))}
                            </Row>
                            <Row>
                              <Form.Group className="form-group m-2 d-flex flex-column">
                                <Form.Label className="control-label1 mb-3">
                                  Escribe el nombre completo de las materias
                                  optativas y período en que las aprobaste.
                                </Form.Label>
                                <textarea
                                  className="form-control1"
                                  rows={4}
                                  cols={50}
                                  id="optionalSubjects"
                                  placeholder="Si eres participante de agrimensura, escribe: No Aplica o N/A."
                                  required
                                  value={formData["optionalSubjects"]}
                                  onChange={handleChange}
                                ></textarea>
                              </Form.Group>
                            </Row>
                            <Form.Group className="d-flex align-content-center mt-5">
                              <Form.Check
                                required
                                feedbackType="invalid"
                                id="checkMark"
                                type="checkbox"
                              />
                              <Form.Label
                                className="form-check-label mx-2"
                                htmlFor="checkMark"
                              >
                                <Link
                                  to="/intern-regulation"
                                  target="_blank"
                                  className="regulation"
                                >
                                  He leído el Reglamento Institucional de la
                                  Pasantía
                                </Link>
                              </Form.Label>
                            </Form.Group>
                          </div>

                          <div className="d-flex align-items-start gap-3 mt-4">
                            <button
                              type="button"
                              className="btn btn-link text-decoration-none btn-label previestab custom-button-back"
                              onClick={() => handleTabChange(1)}
                            >
                              <i className="ri-arrow-left-line label-icon align-middle fs-lg mx-2"></i>
                              Volver
                            </button>
                            <button
                              type="submit" disabled={validated}
                              className="btn btn-primary btn-label right ms-auto nexttab nexttab font-weight-bold custom-button"
                              onClick={() => handleTabChange(3)}
                            >
                              <i className="ri-arrow-right-line label-icon align-middle fs-lg mx-2"></i>
                              Enviar
                            </button>
                          </div>
                        </Tab.Pane>

                        {/*El cuarto tab*/}
                        <Tab.Pane
                          eventKey="3"
                          id="pills-success"
                          role="tabpanel"
                          aria-labelledby="pills-success-tab"
                          className={activeTab === 3 ? "" : "d-none"}
                        >
                          <div>
                            <div className="text-center">
                              <div className="d-flex justify-content-center mb-4">
                                <LordIcon icon={icon} />
                              </div>
                              <h5>Su solicitud ha sido enviada!</h5>
                              <p className="text-muted">
                                Por favor, este pendiente a su correo
                                institucional para más información.
                              </p>
                              <Link to="/home">Volver al inicio</Link>
                            </div>
                          </div>
                        </Tab.Pane>
                      </Form>
                    </Tab.Content>
                  </Tab.Container>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateIntershipRequest;