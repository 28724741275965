import React, { useState, ChangeEvent } from 'react';
import { Container, Form, Card, Button, Row, Col } from 'react-bootstrap';
import BreadCrumb from 'Common/BreadCrumb';
import { useDropzone } from 'react-dropzone';
import LordIcon from "Components/LordIcon"
import ModalDocumentations from "Components/ModalDocumentations";
import "assets/scss/pages/MyRequestStatus.scss";

export default function DocumentationRequest() {
    document.title = "Evidencias"
    const icon = require('../../../assets/animations/upload-file.json');

    const [showModal, setShowModal] = useState(false);
    const [modalAction, setModalAction] = useState<'deshacer' | 'enviar'>('deshacer');
    const [seleccion, setSeleccion] = useState('');
    const [archivosImagen, setArchivosImagen] = useState<File[]>([]);
    const [radioSeleccionado, setRadioSeleccionado] = useState<string>('');

    const handleSeleccionChange = (event: ChangeEvent<HTMLInputElement>) => {
        const selectedValue = event.target.value;
        setSeleccion(selectedValue);
        setRadioSeleccionado(selectedValue);
    };

    //Para eliminar archivos
    const handleRemoveFile = (e: React.MouseEvent<HTMLButtonElement>, index: number) => {
        e.stopPropagation();
        const newArchivosImagen = [...archivosImagen];
        newArchivosImagen.splice(index, 1);
        setArchivosImagen(newArchivosImagen);
    };

    const onDrop = (acceptedFiles: File[]) => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            setArchivosImagen([...archivosImagen, ...acceptedFiles]);
        }
    };

    const handleDeshacer = () => {
        setModalAction('deshacer');
        setShowModal(true);
    };

    const handleEnviar = () => {
        setModalAction('enviar');
        setShowModal(true);
    };

    const confirm = () => {
        setRadioSeleccionado('');
        setSeleccion('');
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const renderContenido = () => {
        if (seleccion === 'servicio_social' || seleccion === 'pasantía') {
            return (
                <React.Fragment>
                    <Form.Group controlId="pregunta1">
                        <Form.Label>Pregunta 1: ¿Cómo fue tu experiencia?</Form.Label>
                        <Form.Control as="textarea" rows={3} placeholder="Ingrese su respuesta" required className='mb-3' />
                        <Form.Control.Feedback type="invalid">Completa este espacio vacío</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="pregunta2">
                        <Form.Label>Pregunta 2: ¿Qué aprendiste durante este periodo?</Form.Label>
                        <Form.Control as="textarea" rows={3} placeholder="Ingrese su respuesta" required className='mb-3' />
                        <Form.Control.Feedback type="invalid">Completa este espacio vacío</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="imagen">
                        <Form.Label>Subir evidencias:</Form.Label>
                        <p className='parrafo'>Puede subir múltiples imágenes en formato JPG, PNG, JPEG, así como videos en formato MP4.</p>
                        <div {...getRootProps()} className="dropzoneStyles">
                            <input {...getInputProps()} />
                            {archivosImagen.length > 0 ? (
                                archivosImagen.map((archivo, index) => (
                                    <div key={index} className='col-12 col-sm-6 col-lg-4 col-xxl-3 p-2 position-relative'>
                                        <Button className="btn-sm btn-eliminar" onClick={(e) => handleRemoveFile(e, index)}>X</Button>
                                        {archivo.type.startsWith('image/') ? (
                                            <img
                                                src={URL.createObjectURL(archivo)}
                                                alt={`Thumbnail ${index}`}
                                                className="media-items"
                                            />
                                        ) : archivo.type.startsWith('video/') ? (
                                            <video controls className="media-items">
                                                <source src={URL.createObjectURL(archivo)} type={archivo.type} />
                                                Tu navegador no admite la reproducción de audio.
                                            </video>
                                        ) : (
                                            <p>No se puede mostrar este tipo de archivo</p>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <div className="d-flex flex-column mx-auto">
                                    <span className="mx-auto">
                                        <LordIcon icon={icon} />
                                    </span>
                                    <p className='mt-3'>
                                        {isDragActive ? 'Suelta los archivos aquí' : 'Arrastra y suelta imágenes o videos, o haz clic para seleccionarlos.'}
                                    </p>
                                </div>
                            )}
                        </div>
                    </Form.Group>
                    <br />
                    <Row>
                        <Col className="d-flex justify-content-end">
                            <Button className='botondeshacer' onClick={handleDeshacer}>Deshacer</Button>
                            <Button className='botonenviar' onClick={handleEnviar}>Enviar</Button>
                        </Col>
                    </Row>
                    <ModalDocumentations
                        show={showModal}
                        onHide={() => setShowModal(false)}
                        onConfirm={confirm}
                        action={modalAction}
                    />
                </React.Fragment >
            );
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Documentaciones" pageTitle="Solicitudes" />
                    <Row>
                        <Col>
                            <Card body className='bg-white'>
                                <h5>Seleccione:</h5>
                                <p className='parrafo'>Es necesario subir evidencias después de culminar el servicio social y la pasantía.</p>
                                <br />
                                <Form>
                                    <Row>
                                        <Col>
                                            <Form.Check
                                                type="radio"
                                                id="servicioSocialRadio"
                                                label="Servicio Social"
                                                name="seleccion"
                                                value="servicio_social"
                                                onChange={handleSeleccionChange}
                                                className="mb-3"
                                                disabled={radioSeleccionado === 'pasantía'}
                                            />
                                            <Form.Check
                                                type="radio"
                                                id="pasantiaRadio"
                                                label="Pasantía"
                                                name="seleccion"
                                                value="pasantía"
                                                onChange={handleSeleccionChange}
                                                className="mb-3"
                                                disabled={radioSeleccionado === 'servicio_social'}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {renderContenido()}
                                        </Col>
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}