import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Alert, Button, Form } from "react-bootstrap";
import { useProfile } from "Common/Hooks/UserHooks";
import withRouter from "Common/withRouter";
import Breadcrumb from 'Common/BreadCrumb';
import avatar from "../../assets/images/users/32/icono.png";
import "assets/scss/pages/_ProfileSettings.scss";
import axiosInstance from "services/axios";

const UserProfile = () => {

    document.title = "Perfil De Usuario";

    const { userProfile, token, setUserProfile } = useProfile();
    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [enrollmentNumber, setEnrollmentNumber] = useState('');
    const [updateSuccess, setUpdateSuccess] = useState('');
    const [updateError, setUpdateError] = useState("");

    console.log('usuario desde el perfil:', userProfile);
    console.log('token desde el perfil:', token);

    // Para cargar los datos del usuario
    useEffect(() => {
        if (userProfile && userProfile.data) {
            setName(userProfile.data.name || '');
            setLastname(userProfile.data.lastname || '');
            setEnrollmentNumber(userProfile.data.enrollmentNumber || '');
            setEmail(userProfile.data.email || '');
        }
    }, [userProfile]);

    // Funcion para actualizar el perfil del usuario
    const handleUpdate = async (e: any) => {
        e.preventDefault();

        if (!name || !lastname) {
            setUpdateError('El nombre o el apellido son obligatorios.');
            return;
        }

        const updateData = {
            name,
            lastname,
        };

        try {
            await axiosInstance.patch('administratives/profile', {
                email,
                ...updateData,
            });

            // Actualizamos el perfil global
            setUserProfile({ ...userProfile, ...updateData });
            setUpdateSuccess('Perfil actualizado con éxito');
        } catch (error: any) {
            console.error('Error al actualizar el perfil del usuario:', error);
            setUpdateError(error.response?.data?.message || 'Hubo un error al actualizar el perfil del usuario.');
        }
    };

    return (
        <React.Fragment>
            {userProfile && (

                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumb */}
                        <Breadcrumb title="Perfil" pageTitle="Cuentas" />

                        <Row>
                            <Col lg="12">
                                {/*Para los mensajes de error o exitoso*/}
                                {updateError && updateError ? <Alert variant="danger">{updateError}</Alert> : null}
                                {updateSuccess ? <Alert variant="success">El nombre de usuario se actualizó a {name} {lastname}. Debe refrescar la página para visualizar los cambios.</Alert> : null}

                                {/*Card de perfil de usuario*/}
                                <Card>
                                    <Card.Body>
                                        <div className="d-flex infoProfile">
                                            <div className="mx-3">
                                                <img
                                                    src={avatar}
                                                    alt=""
                                                    className="avatar-md rounded-circle img-thumbnail"
                                                />
                                            </div>
                                            <div className="flex-grow-1 align-self-center">
                                                <div className="text-muted">
                                                    <span>
                                                        <h5 className="me-1">{name} {lastname}</h5>
                                                    </span>
                                                    <p className="mb-1">Email institucional: {email}</p>
                                                    <p className="mb-0">Matrícula: {enrollmentNumber}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        {/*Formulario de actualización de datos*/}
                        <h4 className="card-title mb-4">Actualizar los datos</h4>
                        <Card>
                            <Card.Body>
                                <Form
                                    className="form-horizontal"
                                    onSubmit={handleUpdate}>
                                    <div className="form-group">
                                        <Form.Label>Nombre</Form.Label>
                                        <Form.Control
                                            name="name"
                                            className="form-control"
                                            placeholder="Ingrese su nombre"
                                            type="text"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                        <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                                    </div>
                                    <div className="form-group mt-2">
                                        <Form.Label>Apellidos</Form.Label>
                                        <Form.Control
                                            name="lastname"
                                            className="form-control"
                                            placeholder="Ingrese sus apellidos"
                                            type="text"
                                            value={lastname}
                                            onChange={(e) => setLastname(e.target.value)}
                                        />
                                        <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                                    </div>
                                    <div className="text-center mt-4">
                                        <Button type="submit" className="ActualizarUser">
                                            Actualizar Usuario
                                        </Button>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                        {/* Fin del formlario*/}
                    </Container>
                </div>
            )}
        </React.Fragment>
    );
};

export default withRouter(UserProfile);