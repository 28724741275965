import React, { useEffect, useState } from "react";
import { useProfile } from "Common/Hooks/UserHooks";
import { Navigate } from "react-router-dom";
import uapagifs from "assets/images/uapagifs.gif";
import ChatComponent from 'Components/ChatComponent';

interface AuthProtectedProps {
  children: React.ReactNode;
}

// Para proteger las rutas que requieren autenticación
const AuthProtected: React.FC<AuthProtectedProps> = ({ children }) => {
  const { isAuthorized, loading } = useProfile();

  if (loading) {
    return null;
  }

  return isAuthorized ? children : <Navigate to="/not-found" />;
};

// Para proteger las rutas que requieren autenticación y roles específicos
const AccessRoute = ({ component: Component, allowedRoles, children, ...rest }: any) => {
  const { isAuthorized, roleName, loading, roleLoading } = useProfile();
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    if (!loading && !roleLoading) {
      setTimeout(() => {
        setShowLoading(false);
      }, 2000);
    }
  }, [loading, roleLoading]);

  // Si los roles aún se están cargando mostramos la carga
  if (showLoading) {
    console.log('Cargando roles...')
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', background: 'white' }}>
        <img src={uapagifs} alt="Cargando..." className="" />
      </div>
    );
  }

  // Una vez que los roles se han cargado este realiza la verificacion de autorizacion
  if (!isAuthorized || (allowedRoles && roleName && !allowedRoles.includes(roleName))) {
    return <Navigate to="/unauthorized" />;
  }
  console.log('Rol de usuario desde el componente AccessRoute:', roleName)
  console.log('Roles permitidos desde access:', allowedRoles)

  // Si el usuario tiene acceso, renderiza el componente
  return <Component {...rest}>{children} <ChatComponent /></Component>;
};

export { AccessRoute, AuthProtected };