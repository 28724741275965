import React from 'react';
import { Button } from 'react-bootstrap';
import "assets/scss/pages/MyRequestStatus.scss";

interface ModalButtonsProps {
    view: string;
    handleApproveClick: () => void;
    handleRejectClick: () => void;
    onHide: () => void;
}

const ModalButtons: React.FC<ModalButtonsProps> = ({ view, handleApproveClick, handleRejectClick, onHide }) => {
    if (view === "/intership-requests" || view === "/social-service-requests") {
        return (
            <div className="w-100 d-flex flex-wrap justify-content-evenly gap-2">
                <Button className='btnenviar-modal' onClick={handleApproveClick}>
                    <i className="fa-solid fa-check"></i> Guardar
                </Button>
                <Button className='botonrechazar' onClick={handleRejectClick}>
                    <i className="fa-solid fa-xmark"></i> Cerrar
                </Button>
            </div>
        );
    } else if (view === "/requests-metrics") {
        return (
            <div className="w-100 d-flex flex-wrap justify-content-evenly gap-2">
                <Button className='btnenviar-modal' onClick={handleApproveClick}>
                    <i className="fa-solid fa-check"></i> Guardar
                </Button>
                <Button className='botonrechazar' onClick={handleRejectClick}>
                    <i className="fa-solid fa-xmark"></i> Cerrar
                </Button>
            </div>
        );
    } else {
        return (
            <Button className='botonrechazar w-25' onClick={onHide}>
                Cerrar
            </Button>
        );
    }
};

export default ModalButtons;