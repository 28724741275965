import React from "react";
import { Container, Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { homeData } from 'Common/data/homeData';
import "assets/scss/pages/_home.scss";
import { useProfile } from "Common/Hooks/UserHooks";

const Home = () => {
  document.title = "Inicio - SAGESPRA";
  const { roleName } = useProfile();

  const datafiltered = homeData.filter((data) => {
    return roleName && data.roles.includes(roleName);
  });

  return (
    <React.Fragment>
      <div className="page-content home">
        <Container fluid>
          <div className="home-title">
            <h2>BIENVENIDO(A) A SAGESPRA</h2>
          </div>
          <Row>
            {datafiltered.map((data, index) => (
              <Col key={index} lg={6} xl={4} className="col-home-cards">
                <Link to={data.link}>
                  <Card className="card card-height-100 card-animate mt-10">
                    <Card.Header className="d-flex justify-content-center align-items-center">
                      <div className="flex-shrink-0 mb-5">
                        <img
                          src={data.image}
                          alt={data.title}
                          width={240}
                        />
                      </div>
                    </Card.Header>
                    <Card.Body className="pt-0 pb-2 ps-0 mt-2 mt-lg-n3 sales-report-chart">
                      <h3 className="ms-2">{data.title}</h3>
                      <div className="d-flex align-items-center justify-content-between ms-2">
                        <span className="text-muted">{data.subtitle}</span>
                        <span className="text-muted">
                          <i className="ri-arrow-right-s-line"></i>
                        </span>
                      </div>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Home;
